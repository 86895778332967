import { ElementRef, Injectable, NgZone } from '@angular/core';
import { DragBase } from '@app/definitions/types';
import { ModalService } from '@app/services/modal.service';
import { Subject } from 'rxjs';
import { PureDragObj } from './../definitions/types';
import { LangService } from './lang.service';
import { StoreService } from './store.service';

const minNotTouch = 1300;	//screen with higher width would NOT be considered a touch device, despite proclaiming to be one
const minNotMobile = 992;	//screen with higher width would not be considered "mobile" (relevant in code mostly to patient/contact/payor/etc)

@Injectable({
	providedIn: 'root'
})
export class EventsService {

	docClickSubject = new Subject();	//listens and broadcasts clicks on the document (anywhere). used to close popups like date/time pickers
	escapeKeySubj = new Subject();	//listens and broadcasts ESC key. used to close modals, reset gen-table filters/search, close autocomplete suggestions
	escapeKeyNonModalSubj = new Subject();	//listens and braodcasts ESC key ONLY AFTER MODALS ARE CLOSED. used return to "default" in patient etc
	isMobileSubj = new Subject();	//listens and braodcasts changes in isMobile variable. Used by home-meeting/patient/configCliniq etc to change displays types (all panels vs separate panels)
	isMobile: boolean = false;	//is mobile width (see isMobileSubj for usage)
	MobileHeight: any;	//is mobile width (see isMobileSubj for usage)
	isTouch: boolean = false;	//is touch device. used for deciding if to enforce focus on fields etc

	constructor(protected zone: NgZone, protected lang: LangService, protected modalService: ModalService,private store:StoreService) {
		this.isMobile = window.innerWidth < minNotMobile;	//determined by window width
    this.MobileHeight=window.innerHeight;
		this.isTouch = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0)) && window.innerWidth < minNotTouch;	//determined both by window/navigator attributes AND maximum width
		//|| (navigator.msMaxTouchPoints > 0)

	}

	checkShouldMobileChange(size: number) {	//detrmines if isMobile has changed - if so, updates variable and braodcasts via the subject
		const newIsMobile = size < minNotMobile;
		if (newIsMobile != this.isMobile) {	//changed from current value
			this.isMobile = newIsMobile;
			this.isMobileSubj.next(true);	//broadcast the change to listeners
		}
	}

	onDocClick(val: any = true) {	//braodcast a click in the document. accepts also a {src:this} from a popup, so it itself would NOT close due to this click
		this.docClickSubject.next(val);
	}

	//drag functions
	dragBase: DragBase = new DragBase();	//holds the variables for current dragging operation
	removeDragFuncs() {	//remove the mousemove event listener
		if (this.dragBase && this.dragBase.mmFunc) {	//if object exists and holds a function in this variable - remove even listener
			window.document.removeEventListener('mousemove', this.dragBase.mmFunc);
		}
	}
	dragMouseDown(ev: MouseEvent, pureDragObj: PureDragObj = null, mmFunc: Function = null, baseVals: any = {}) {	//starts a dragging process (currently used by modals)
		this.removeDragFuncs();	//makes sure to remove previous event listeners
		this.dragBase.clickX = ev.clientX;	//setup the start position
		this.dragBase.clickY = ev.clientY;

		if (pureDragObj && pureDragObj.affectedObj) {	//if a native element exists to use for dragging
			if (!baseVals) {	//init the baseVals
				baseVals = {};
			}
			if (pureDragObj.xStyleAttrName) {	//if there's an x attribute (for example "left" or "right") will use it and init same attribute in baseVals (to be used to compare while dragging)
				baseVals[pureDragObj.xStyleAttrName] = parseInt(pureDragObj.affectedObj.style[pureDragObj.xStyleAttrName] || 0);
			}
			if (pureDragObj.yStyleAttrName) {	//same but for y ("top" or "bottom")
				baseVals[pureDragObj.yStyleAttrName] = parseInt(pureDragObj.affectedObj.style[pureDragObj.yStyleAttrName] || 0);
			}
			mmFunc = this.dragMouseMovePure.bind(this);	//register the mousemove handler
		}
		this.dragBase.baseVals = baseVals;	//save vars to the dragBase object
		this.dragBase.pureDragObj = pureDragObj;

		this.zone.runOutsideAngular(() => {	//register event listener OUTSIDE ANGULAR PROCESS, so that detection would be avoided (much better performance)
			this.dragBase.mmFunc = mmFunc;
			window.document.addEventListener('mousemove', this.dragBase.mmFunc);
		});

	}
	dragMouseUp(ev: MouseEvent) {	//finish drag - remove event listeners and reset the dragBase
		this.removeDragFuncs();
		this.dragBase = {};
		this.zone.run(() => {

		});
	}
	dragMouseMovePure(ev: MouseEvent) {	//mousemove handler, works outside angular, affects native element only
		ev.preventDefault();	//prevents browser reaction to dragging
		if (!this.dragBase.pureDragObj || !this.dragBase.pureDragObj.affectedObj) { return; }	//if dragging is not taking place - exit

		let ne = this.dragBase.pureDragObj.affectedObj;	//the native element
		if (this.dragBase.pureDragObj.xStyleAttrName) {	//if has x attribute ("left"/"right") calculate difference from start position and update
			//eg. for "left" - calculates the difference between drag start and current position, adds the result to native elements initial "left"
			let x = (this.dragBase.baseVals[this.dragBase.pureDragObj.xStyleAttrName] + ev.clientX - this.dragBase.clickX);
			ne.style[this.dragBase.pureDragObj.xStyleAttrName] = x + "px";
		}
		if (this.dragBase.pureDragObj.yStyleAttrName) {	//same for y ("top"/"bottom")
			let y = (this.dragBase.baseVals[this.dragBase.pureDragObj.yStyleAttrName] + ev.clientY - this.dragBase.clickY);
			ne.style[this.dragBase.pureDragObj.yStyleAttrName] = y + "px";
		}
	}

	datePopUpProps(inp: ElementRef, popupHeight: number, popupWidth: number, ev: MouseEvent) {	//calculate best positions for a popup (date/timepicker) base on how close it is to screen edges (trying to prevent part of it being outside the viewport)
		let ret = { bottom: null, left: null, right: null };	//returned attributes to be used by the component
		let totH = document.documentElement.clientHeight;	//screen height
		const isBottomScreen = ev.clientY > totH / 2;	//is the click in the lower half of the screen
		let rect = inp.nativeElement.getBoundingClientRect();	//get the input native element's dimensions
		// console.log("isBottomScreen",isBottomScreen);
		ret.bottom = (isBottomScreen ? -rect.height - popupHeight : 0);	//if bottom half - will appear above the input

		//console.log(rect);

		let totW = document.documentElement.clientWidth;	//screen width
		let scrollObj = this.modalService.getScrollInfo();	//current screen scroll

		if (this.lang.isRtl) {	//isRtl - move the left of the popup to the left side of the input
			//console.log(rect.right, popupWidth);

			if (rect.right < popupWidth) {
				ret.left = rect.left;
			}
		}
		else {	//no rtl - make sure the popup doesn't appear too right and disappears off screen
			//console.log(totW, scrollObj.x, rect.left);
			if (totW - scrollObj.x - rect.left < popupWidth) {
				ret.right = totW - scrollObj.x - rect.left - rect.width;
			}
		}

		return ret;
	}

	async scrollToTop() {
    await this.store.timeout(10);
    window.scroll(0, 0);
	}

	updateTitle(title: string) {
		// document.title = `My-Cliniq - ${title}`;
	}

}
