import { Component, OnInit } from '@angular/core';
import { LangService } from '@app/services/lang.service';

@Component({
  selector: 'app-payment-client-finish-grow',
  templateUrl: './payment-client-finish-grow.component.html',
  styleUrl: './payment-client-finish-grow.component.css'
})
export class PaymentClientFinishGrowComponent implements OnInit {

  success: boolean = false;	//was the purchase a success
  constructor(public lang: LangService) { }

  ngOnInit() {
    console.log(location);
    let input = location.search.split("?").pop();	//gets the query string (everything in the URL after the ? sign)
    console.log("input: ");
    console.log(input);
    let statusCode = decodeURIComponent(input)	//decodes the url (so it can be parsed as request pairs)
      .split("&")	//splits to pairs
      .map(it => it.split("="))	//split each pair to array of [name,value]
      .find(it => it[0] == "response");	//tried to find an item where the name is "Response"

    //console.log(input, statusCode);
    console.log("statusCode:");
    console.log(statusCode);
    if (statusCode && statusCode[1] == "success") {	//found an item and the response was "000"
      this.success = true;	//change success to true
    }
  }

}
