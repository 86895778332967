import { ModalService } from '@app/services/modal.service';
import { ApiService, pluck } from '@app/services/api.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SearchSortService } from '@app/services/search-sort.service';
import { environment } from '@env/environment';
import { StoreService } from '@app/services/store.service';
import {EventsService} from "@app/services/events.service";
import { PermsService } from '@app/services/perms.service';
import { DatePickerComponent } from '@app/utils/date-picker/date-picker.component';
import {ConfigDefinitions, FinResourcePeriods} from "@app/definitions/types";

@Component({
	selector: 'app-mixed-list',
	templateUrl: './mixed-list.component.html',
	styleUrl: './mixed-list.component.css'
})
export class MixedListComponent {
	@Input() patient: any = null;
	@Input() followup_id: number = null;
	mixedListChosenOpt: string = "without";
	patientImages: any[] = [];
  withDescription: string = 'yes';
  withPersonalMemorandum: string = 'no';
  showFutureMeetings: string = 'no';
	searchStr: string = "";
	objectCollection: any[] = [];
	objectCollectionShown: any[] = [];
	searchableFields: string[] = ["date_formatted", "description"];

	sortField: string = "date";
	sortIsAsc: boolean = false;
	dateFields: string[] = ["date"];

  period:string="";

  date_from:string="";
  date_to:string="";
  @ViewChild("fromDate") fromDate: DatePickerComponent;
  @ViewChild("toDate") toDate: DatePickerComponent;
  periodInputs: any[] = [   'last_90_days','last_365_days', 'all_options','date_range'].map(it=>({name:it,value:it}));   //'last_30_days',
  currentPeriod: string = "last_90_days";  //FinResourcePeriods

	constructor(protected searchSortService: SearchSortService, public apiService: ApiService, public modalService: ModalService,public store:StoreService,public eventsService:EventsService,public permsService: PermsService) { }

	async ngOnInit() {
    this.withDescription = 'yes';
    this.withPersonalMemorandum = 'no';
    const cc=this.store.getCliniqDataOrExit();
    this.showFutureMeetings = cc.show_future_meetings;
    this.currentPeriod = "last_365_days";
    if(cc.mixed_list_period){
      this.currentPeriod=cc.mixed_list_period;
    }
    if(cc.mixed_list_pm){
      this.withPersonalMemorandum=cc.mixed_list_pm;
    }
		await this.loadObjectCollections();


    this.chPeriod();
    // this.search();
	}
	async loadObjectCollections() {
    const res:any=await this.apiService.post("get_fp_images",{ patient_id: this.patient.id });
    this.patientImages = res.items;
    this.patientImages.forEach(imgObj => {
      imgObj.url = environment.serverUrl + "/followup-image/" + imgObj.id;
    })
		this.objectCollection = this.patient.mixed_list;
		this.objectCollection.forEach(row => {
			row[this.store.getDateExpandName("date")] = new Date(row.date);
			row["date_formatted"] = this.store.getMomentDisplay(row.date);
      switch(row.type){
        case "followup":
          row.images = this.patientImages.filter(it => it.followup_id == row.id);
        break;
        case "testfill":
          row.test=this.patient.tests.find(it=>it.testfill_id==row.id);
        break;
      }
		});

		this.search();
	}
  chPeriod() {
    if (this.currentPeriod == "date_range") {
      this.search();
      return;
    }
    //plant new dates
    //let from = null;
    const [from,to]=this.store.getPriodFromTo(this.currentPeriod);
    this.date_from = from;
    this.date_to = to;
    // console.log(this.fromDate);
    if (this.date_from && this.fromDate) {
      this.fromDate.refreshWithModel();
      this.toDate.refreshWithModel();
    }
    this.search();

    const cc = this.store.getCliniqDataOrExit();
		cc.mixed_list_period = this.currentPeriod;
		this.apiService.post("update_configcliniq_data",cc);
    sessionStorage.setItem("cliniqData", JSON.stringify(cc));

  }
  saveCc(){
    const cc = this.store.getCliniqDataOrExit();
		cc.mixed_list_pm = this.withPersonalMemorandum;
		this.apiService.post("update_configcliniq_data",cc);
    sessionStorage.setItem("cliniqData", JSON.stringify(cc));

  }
  openFuses(){
    this.modalService.openMulti("patient-fuses",{patient_id:this.patient.id});
  }

	search() {
		this.searchSortService.search(this, this.searchStr, 'objectCollectionShown', 'objectCollection');
    if(!this.searchStr){
      if(this.currentPeriod!="all_options"){
        if(this.date_from){
          const dateFrom=new Date(this.date_from);
          this.objectCollectionShown=this.objectCollectionShown.filter(row=>{
            return dateFrom<=row[this.store.getDateExpandName("date")];
          });
        }
        if(this.date_to){
          const dateTo=new Date(this.date_to);
          this.objectCollectionShown=this.objectCollectionShown.filter(row=>{
            return dateTo>=row[this.store.getDateExpandName("date")];
          });
        }
      }
    }
		this.searchSortService.sort(this, 'objectCollectionShown', this.sortField, true);
	}

	sort(fieldName: string, repeatSort: boolean = false) {
		this.searchSortService.sort(this, 'objectCollectionShown', fieldName, repeatSort);
	}

	openGallery(imgObj: any) {
		const ind = this.patientImages.findIndex(it => it.id == imgObj.id);
		this.modalService.openMulti("image-gallery", {
      images: this.patientImages,
      curImagePosition: ind
		})
	}

  async openRow(row:any){
    if(!this.permsService?.perms?.clinical || row?.type!="followup" || row?.status=="deleted" || row.id==this.followup_id){return;}
    // let followup=this.store.data.followups.find(it=>it.id==row.id);
    row.isFetching=true;
    const res=await this.apiService.post("get_followup_by_id",row,"id");
    row.isFetching=false;
    if(!res?.followup){return;}
    const followup=this.store.removeDecItem(res.followup);
    // if(!followup){
    // }
    await this.store.openEditFollowup(followup);
  }

  async xlsxDownload(){
    this.store.downloadToastFunc(async ()=>{
      const res=await this.apiService.download("print_mixed_list",{id:this.patient.id,...pluck(this,"date_from,date_to")});
      if(!res?.size){return this.store.downloadFailedModal();}
      this.apiService.downloadFile(res, `mixed_list.xlsx`);
    });
  }

  async print() {

    let html = document.getElementById("section-to-print-mixed-list").innerHTML;
    html = this.store.processHtmlPreDl(html);
    html = html.replace(/<thead\s*>/g, "<thead>");
    let newWin = window.open('', 'Print-Window');

    //console.log(html);
    // html=html.replace("<thead>")

    newWin.document.open();

    newWin.document.write(`
		<html>
		<style type='text/css'>${this.store.getPrintStyle()}</style>
		<body onload="window.print();window.onafterprint=()=>window.close();" style="direction:rtl">


		${html}
		</body>
		</html>`);

    newWin.document.close();
    // await this.store.timeout(2000);
    // newWin.close();

  }
}
