import { Component, OnInit } from '@angular/core';
import { LangService } from '@app/services/lang.service';

@Component({
  selector: 'app-payment-client-finish',
  templateUrl: './payment-client-finish.component.html',
  styleUrl: './payment-client-finish.component.css'
})
export class PaymentClientFinishComponent implements OnInit {

  success: boolean = false;	//was the purchase a success
	constructor(public lang: LangService) { }

	ngOnInit() {
		let input = location.search.split("?").pop();	//gets the query string (everything in the URL after the ? sign)
		let statusCode = decodeURIComponent(input)	//decodes the url (so it can be parsed as request pairs)
			.split("&")	//splits to pairs
			.map(it => it.split("="))	//split each pair to array of [name,value]
			.find(it => it[0] == "statusCode");	//tried to find an item where the name is "Response"

		//console.log(input, statusCode);

		if (statusCode && statusCode[1] == "0") {	//found an item and the response was "000"
			this.success = true;	//change success to true
		}
		let response = decodeURIComponent(input)	//decodes the url (so it can be parsed as request pairs)
			.split("&")	//splits to pairs
			.map(it => it.split("="))	//split each pair to array of [name,value]
			.find(it => it[0] == "statusCode");	//tried to find an item where the name is "Response"

		//console.log(input, response);

		if (response && response[1] == "000") {	//found an item and the response was "000"
			this.success = true;	//change success to true
		}

	}

}
