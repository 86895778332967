import { Injectable } from '@angular/core';
import { StoreService } from '@app/services/store.service';
import { filter } from 'rxjs/operators';
import { pluck } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class SearchSortService {
	siteDataSearchableFieldPerClass: any = {
		branches: ["code"],
		medics: ["trade_name"],
	};

	constructor(protected store: StoreService) { }

	updateSortField(object: any, fieldName: string) {
		if (object.sortField == fieldName) {
			object.sortIsAsc = !object.sortIsAsc;
		}
		else {
			object.sortIsAsc = true;
		}
		object.sortField = fieldName;
	}




	sort(object: any, shownFieldName: string, fieldName: string, repeatSort: boolean = false) {
		if (!repeatSort) {
			this.updateSortField(object, fieldName);
		}

		let reverseFactor = (object.sortIsAsc ? 1 : -1);

    let siteDataSortField=null;
    if(object.siteDataSortFields?.length){
      siteDataSortField=object.siteDataSortFields.find(fieldObj=>fieldObj.fieldName==fieldName);
    }

    let siteDataVals=null;
    if(siteDataSortField){
      const arr=this.store.getSiteDataTable(siteDataSortField?.attrs?.tableName);
      if(arr?.length){
        siteDataVals={};
        arr.forEach(row=>{
          siteDataVals[row.id]=row.name;
        });
      }
    }

    // console.log(fieldName);


		object[shownFieldName].sort((a, b) => {
      if(siteDataSortField){
        let aVal = siteDataVals[a[fieldName]];
        aVal= aVal ? String(aVal) : "";
        let bVal = siteDataVals[b[fieldName]];
        bVal= bVal ? String(bVal) : "";
        // console.log(aVal,bVal);
        // console.log(a[fieldName],aVal,b[fieldName],bVal);
        // console.log("\""+aVal.toLowerCase()+"\"","\""+bVal.toLowerCase()+"\"",aVal.toLowerCase() > bVal.toLowerCase());
        return (aVal.toLowerCase() > bVal.toLowerCase() ? 1 : -1) * reverseFactor;
      }
			if (object.numberFields && object.numberFields.includes(fieldName)) {
				return (a[fieldName] - b[fieldName]) * reverseFactor;
			}
			if (object.dateFields && object.dateFields.includes(fieldName)) {
				let da = a[this.store.getDateExpandName(fieldName)];
				let db = b[this.store.getDateExpandName(fieldName)];

				return (da.getTime() - db.getTime()) * reverseFactor;
			}

			let aVal = String(a[fieldName]) || "";
			let bVal = String(b[fieldName]) || "";
      // console.log(aVal,bVal);
			return (aVal.toLowerCase() > bVal.toLowerCase() ? 1 : -1) * reverseFactor;
		});

	}
	search(object: any, value: string, shownFieldName: string, collectionName: string, isFuzzy: boolean = false) {
		object.searchStr = value;
    
		let valueLC = value.toLowerCase();


		if (object.fuzzySearchableClasses && object.fuzzySearchableClasses.length) {
			if (!this.store.data.siteData) { throw new Error("no site data in search!!!") }

			object[shownFieldName] = [];
			object.fuzzySearchableClasses.forEach(className => {
				let searchableFields = this.siteDataSearchableFieldPerClass[className] || ["name"];
				object[shownFieldName].push(...this.store.getSiteDataTable(className).filter(item => {
					if (object.approvedIds !== null && !object.approvedIds.includes(item.id)) {
						return false;
					}
					return this.searchItem(item, valueLC, searchableFields, isFuzzy);
				}).map(item => ({ ...item, typeClassName: className })));
			})
		}
		else {
			if (!object.searchableFields.length && !object.filterFields?.length && !object.siteDataSortFields?.length) {
				object[shownFieldName] = [...object[collectionName]];
			}
			else {
				object[shownFieldName] = object[collectionName]
					.filter(item => {
						//is this item in the SAME [fieldName] as currentFilterValues[fieldName]
						if (object.filterFields && object.filterFields.length) {
							for (let filterObj of object.filterFields) {
								let fieldName = filterObj.fieldName;
								let filterValue = object.currentFilterValues[fieldName];
								if (filterValue == "-1") {	//ALL case
									continue;
								}
								if (filterObj.checkType === "null") {
									let isNull = (filterValue === "null");
									if ((isNull && item[fieldName] !== null) || (!isNull && item[fieldName] === null)) {
										return false;
									}
								}
								else {
									if (item[fieldName] != filterValue) {
										return false;
									}
								}
							}
						}
						return true;
					})
					.filter(item => {
						return this.searchItem(item, valueLC, object.searchableFields, isFuzzy,object);
					});
			}
		}


		// this.sort(object,shownFieldName,object.sortField,true);
	}

	searchItem(item: any, valueLC: string, searchableFields: string[], isFuzzy: boolean,object:any=null) {
		if (!searchableFields.length || !valueLC.length) { return true; }

		// const dateFields = (object?.dateFields || []);

		for (let field of searchableFields) {

			let fieldValueLC = item[field].toString().toLowerCase();
			// console.log(fieldValueLC, valueLC);
			if (this.isStringValueInFieldValue(fieldValueLC, valueLC, isFuzzy)) {
				return true;
			}

		}
    if(object?.siteDataSortFields){
      for (let fieldObj of object.siteDataSortFields) {
        const idVal=item[fieldObj.fieldName];
        if(!idVal){continue;}
        let val = this.store.searchSiteDataItem(idVal,[fieldObj?.attrs?.tableName])?.name;
        if(val){
          val=val.toString().toLowerCase();
        }
        if (this.isStringValueInFieldValue(val, valueLC, isFuzzy)) {
          return true;
        }

      }
    }
		return false;

	}
	isStringValueInFieldValue(fieldValueLC: string, valueLC: string, isFuzzy: boolean) {
		if (isFuzzy) {
			let fuzzVal = [...valueLC];
			let prevPos = -1;
			let found = true;
			for (let char of fuzzVal) {
				let pos = fieldValueLC.indexOf(char, prevPos + 1);
				if (pos == -1 || pos <= prevPos) {
					found = false;
					break;
				}
				prevPos = pos;
			}
			if (found) {
				return true;
			}
		}
    else {
			if (fieldValueLC.indexOf(valueLC) != -1) {
				return true;
			}
		}
		return false;
	}





	templateReplaceInObj(template: string, obj: any) {
		let segments = template.split("%");
		if (segments.length % 2 == 0) {	//means EVEN number of items, meaning there is a missing closing %
			throw new Error("missing % in template");
		}
		for (let i = 0; i < segments.length; i++) {
			if (i % 2) {
				let fieldName = segments[i];
				let val = obj[fieldName] === undefined ? "" : obj[fieldName];
				segments[i] = val;
			}
		}
		return segments.join("");
	}
}



