<div class="ac-common-wrapper">

  <div class="tags-container" (click)="focus()">
    @for(obj of chosenObjs;track obj){
      <div class="ac-tag" (click)="$event.stopImmediatePropagation()">
        {{obj.name}}
        @if(!isStatic){
          <app-icon icon="cancel" (click)="delete(obj)" />
        }
      </div>
    }

    @if(isLoading){
      <app-spin class="flex-center" />
    }

    <div class="input-cont" [style.display]="(isStatic || noInput)?'none':''" (click)="$event.stopImmediatePropagation()">
      <input [(ngModel)]="inputStr" (keyup)="keyup()" (click)="keyup(true)" (blur)="onBlur()" autocomplete="do-not-autofill"
       [placeholder]="chosenObjs?.length ? '' : (placeholder || (title_ph_lang | langVal))" [title]="title || (title_ph_lang | langVal)" [tabindex]="tabindex" #inp />

      @if(options.length){
        <div>
          @for(option of options;track option){
            <div (click)="addOption(option)">{{option.name}}</div>
          }
        </div>
      }
    </div>
  </div>
  <!-- saveMode &&  -->
  @if(hasPlus){
    <button (click)="openAddModal()" class="btn btn-icon btn-plus" titleLang="tags"  icon="add" isImg=true></button>
  }


</div>
@if(error){
  <p class="error">{{error}}</p>
}
