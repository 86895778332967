import { Component } from '@angular/core';
import { PatientDetailsFillFields } from '@app/definitions/types';
import { ApiService } from '@app/services/api.service';
import { LangService } from '@app/services/lang.service';
import { ModalService } from '@app/services/modal.service';
import { StoreService } from '@app/services/store.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-patient-details-fields-manage',
  templateUrl: './patient-details-fields-manage.component.html',
  styleUrl: './patient-details-fields-manage.component.css'
})
export class PatientDetailsFieldsManageComponent {
  fields:string[]=PatientDetailsFillFields;
  saveObj:any={};
  saveSubj=new Subject();

  constructor(protected store: StoreService,protected apiService: ApiService, public lang: LangService,public modalService: ModalService){}

  ngOnInit() {
    this.saveObj=this.store.getCliniqDataOrExit()?.patient_details_fields_manage;
    if(!this.saveObj){
      this.saveObj=this.fields.reduce((acc,item)=>({...acc,[item]:{show:true,required:"yes"}}),{})
    }
    this.saveSubj.pipe(
      debounceTime(800),
    ).subscribe(()=>{
      this.save();
    });
  }

  async save(){
    console.log(this.saveObj);
    const cc = this.store.getCliniqDataOrExit();
		cc.patient_details_fields_manage = this.saveObj;

		const result: any=await this.apiService.post("update_configcliniq_data",{...cc, update:"user"});
    if (result && !result?.error) {	//success
      this.modalService.openToast(this.lang.getVal("updated_successfully"));	//open toast with success message
      sessionStorage.setItem('cliniqData', JSON.stringify(result));	//update the config object in the sessionStorage
    }
    else {	//fail
      this.modalService.openToast(this.lang.getVal(result?.error));	//open toast with fail message
    }
  }

}
