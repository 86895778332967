import { Component, Input, OnInit } from '@angular/core';
import { CcParamsGen } from '@app/cc-params-gen/cc-params-gen';

@Component({
  selector: 'app-cc-params-self-coordination',
  templateUrl: './cc-params-self-coordination.component.html',
  styleUrl: './cc-params-self-coordination.component.css'
})
export class CcParamsSelfCoordinationComponent extends CcParamsGen {	//inheriting most functions and fields!
  saveObjName: string = "self-coordination";	//which part to save while updating
  apiLink:string="";

  async ngOnInit(){

    this.getApiKeys();
    const res=await this.apiService.post("get_configcliniq_data");	//get config object from server
    this.configcliniq = res;
  }
  async getApiKeys(){
    this.apiLink="";
    const meetingtypes=this.store.getSiteDataTable("meetingtypes");
    console.log(meetingtypes);

    if(!this.permsService.owner_has_users){

      if(!meetingtypes.find(mt=>mt?.first_time_meeting_request=="yes") && !meetingtypes.find(mt=>mt?.return_meeting_request=="yes") ){
        this.apiLink="";
        return;
      }
    }


    if(!this.apiLink){
      const res=await this.apiService.post("get_api_data");
      this.apiLink=location.origin+res?.link;
    }
  }

  async copyApiLink(){
    if(!this.apiLink){
      this.modalService.openMulti("confirm", {
        actionLang: this.lang.getVal("meetingtypes_and_time_allocation_shoud_be_defined"),
        hasCancel: false
      });
      return;
    }
    navigator.clipboard.writeText(this.apiLink);
    this.modalService.openToast(this.lang.getVal("copy_link"));
    window.open(this.apiLink, '_blank');
  }

}



