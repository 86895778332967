import { Component } from '@angular/core';
import { InsertPatientFields, ReportNames, ReportNamesSolo } from '@app/definitions/types';
import { ApiService } from '@app/services/api.service';
import { LangService } from '@app/services/lang.service';
import { ModalService } from '@app/services/modal.service';
import { PermsService } from '@app/services/perms.service';
import { StoreService } from '@app/services/store.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-cc-checkboxes',
  templateUrl: './cc-checkboxes.component.html',
  styleUrl: './cc-checkboxes.component.css'
})
export class CcCheckboxesComponent {
  fields:string[]=[];
  title:string="";
  ccFieldName:string="";

  saveObj:any={};
  allOp:boolean=true;
  isSaving:boolean=false;
  saveSubj=new Subject();


  constructor(protected store: StoreService,protected apiService: ApiService, public lang: LangService,public modalService: ModalService,protected perms:PermsService) { }

  ngOnInit() {
    if(this.ccFieldName=="reports"){
      this.fields= this.perms.owner_has_users ? ReportNames : ReportNamesSolo;
    }
    this.saveObj=this.store.getCliniqDataOrExit()[this.ccFieldName];
    if(!this.saveObj){
      this.saveObj=this.fields.reduce((acc,item)=>({...acc,[item]:true}),{})
    }
    this.saveSubj.pipe(
      debounceTime(800),
    ).subscribe(()=>{
      this.save();
    });
  }

  async save(){

    console.log(this.saveObj);
    const cc = this.store.getCliniqDataOrExit();
		cc[this.ccFieldName] = this.saveObj;

    this.isSaving=true;
		const result: any=await this.apiService.post("update_configcliniq_data",{...cc, update:"user"});
    this.isSaving=false;
    if (result && !result?.error) {	//success
      this.modalService.openToast(this.lang.getVal("updated_successfully"));	//open toast with success message
      sessionStorage.setItem('cliniqData', JSON.stringify(result));	//update the config object in the sessionStorage
      if(this.ccFieldName=="reports"){
        this.store.hiddenMenuItemsSubj.next(true);
      }
    }
    else {	//fail
      this.modalService.openToast(this.lang.getVal(result?.error));	//open toast with fail message
    }

  }

  flipAll(){
    this.allOp=!this.allOp;
    Object.keys(this.saveObj).forEach(k=>this.saveObj[k]=this.allOp);
    this.saveSubj.next(1);
  }
}

@Component({
  selector: 'app-cc-insert-patient-templates',
  templateUrl: './cc-checkboxes.component.html',
  styleUrl: './cc-checkboxes.component.css'
})
export class CcInsertPatientTemplate extends CcCheckboxesComponent {
  fields:string[]=InsertPatientFields;
  title:string="select_fields";
  ccFieldName:string="insert_patient_template";
}

@Component({
  selector: 'app-cc-reports',
  templateUrl: './cc-checkboxes.component.html',
  styleUrl: './cc-checkboxes.component.css'
})
export class CcReports extends CcCheckboxesComponent {
  fields:string[]=ReportNames;
  title:string="report_selection";
  ccFieldName:string="reports";
}
