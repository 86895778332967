import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StoreService } from '@app/services/store.service';
import { distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-ta-expands',
  templateUrl: './ta-expands.component.html',
  styleUrl: './ta-expands.component.css'
})
export class TaExpandsComponent implements OnInit {
  @Input() tabindex=null;
  @Input() className="";
  @Input() actualObj=null;
  @Input() fieldName="";
  @Input() disabled=false;
  @Input() errors=null;
  @Input() placeholder="";
  @Input() title_ph_lang="";
  @Output("keyupEvent") keyupEvent=new EventEmitter();
  @Output("changeEvent") changeEvent=new EventEmitter();
  changeSubject = new Subject();
  baseHeight:number=24;
  height:number=this.baseHeight;
  @ViewChild("inp") inp:ElementRef=null;

  constructor(private store:StoreService) { }

  ngOnInit() {
    this.changeSubject.pipe(
			distinctUntilChanged()
		).subscribe(() => {
			this.updateHeight();
		});
  }

  async ngAfterViewInit() {
    await this.store.timeout(50);
    this.updateHeight();
	}

  async paste(){
    console.log(4444);
    await this.store.timeout();
    this.changeSubject.next(this.actualObj[this.fieldName]);
  }

  keyup(){
    this.keyupEvent.emit(this.actualObj[this.fieldName]);
    this.changeSubject.next(this.actualObj[this.fieldName]);
  }
  onChange(){
    // console.log(this.actualObj[this.fieldName]);
    this.changeEvent.emit(this.actualObj[this.fieldName]);
  }
  async resetHeight(){
    this.height=this.baseHeight;
    await this.store.timeout(50);
    this.updateHeight();
  }
  updateHeight(){
    if(!this.inp){return}

    const na=this.inp.nativeElement;
    // console.log(na.scrollHeight,this.height);

    if(na.scrollHeight>this.height){
      this.height=na.scrollHeight+6;
    }
    // console.log(na.scrollHeight);
    // console.log(na.sty);

  }
  focus(scrollToTop: boolean = false) {		//called from parent. focuses the input (can also scroll into view)
		this.inp.nativeElement.focus();
		if (scrollToTop) {
			this.inp.nativeElement.scrollIntoView(true);
		}
	}

}
