import {InsertPatientFields, Patientfield, StorePatient} from './../definitions/types';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChange, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ValidatorService } from '@app/services/validator.service';
import { LangService } from '@app/services/lang.service';
import { Patient } from '@app/definitions/types';
import { ApiService } from '@app/services/api.service';
import { ModalService } from '@app/services/modal.service';
import { StoreService } from '@app/services/store.service';
import { PermsService } from '@app/services/perms.service';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TaExpandsComponent } from '@app/utils/ta-expands/ta-expands.component';
import { WysiwygComponent } from '@app/utils/wysiwyg/wysiwyg.component';
import * as moment from 'moment';
import {GenAutocompleteComponent} from "@app/gen-inputs/gen-autocomplete/gen-autocomplete.component";

@Component({
	selector: 'app-update-patient',
	templateUrl: './update-patient.component.html',
	styleUrl: './update-patient.component.css'
})
export class UpdatePatientComponent implements OnInit {
	@Output() addDocumentEv = new EventEmitter();	//notify parent of a request to add document
	@Output() addInvoiceEv = new EventEmitter();	//notify parent of a request to add invoice
	@Output() openUploadDocumentModalEv = new EventEmitter();	//notify parent of a request to add uploaded document

	@Input() patient: Patient;	//the patient object to update
  @ViewChild('payorFuzzyInput') payorFuzzyInput: GenAutocompleteComponent;
	patienttypes: any[] = [];	//the patient types to choose from
	// payors: any[] = [];	//the payors to choose from
  insert_patient_template:any= {};
  excludeIds:number[]=[];
  isApprovingPatientDetails:boolean=false;

  isLocked:boolean=false;
  rerouteTimeout:any=null;

  @ViewChild("remarksWysiwyg") remarksWysiwyg: WysiwygComponent;


  @ViewChildren("taExpands") taExpands: QueryList<TaExpandsComponent>;	//all fields that can receive focus, used to focus on them

  patientfields:Patientfield[]=[];

  changeSubj=new Subject();
  nameSubj=new Subject();

	errors: any = {};	//will hold errors for the fields in the form
	isFormSubmittable: boolean = false;	//can submit (controls disabled in the submit button)

	validationFields: any = {	//field validations, control displaying field errors and ability to submit the form
		name:{'not_empty':null},
	};

  tags:any[]=[];
  tagIds:number[]=[];
  nameChangeConfirmHappened:boolean=false;
  prevPatientName:string="";

  
	constructor(private apiService: ApiService, public lang: LangService, public validator: ValidatorService, public modalService: ModalService, public store: StoreService, public permsService: PermsService,protected route: ActivatedRoute,public router: Router) { }

	ngOnInit() {
		// this.payors = this.store.getSiteDataTable("payors");	//get from store (siteData in sessionStorage)
		this.patienttypes = this.store.getSiteDataTable("patienttypes");
    this.patientfields=this.store.getPreppedPatientFields();
    this.insert_patient_template=this.store.getCliniqDataOrExit()?.insert_patient_template;
    if(!this.insert_patient_template){
      this.insert_patient_template=InsertPatientFields.reduce((acc,item)=>({...acc,[item]:true}),{})
    }
    this.excludeIds = this.store.getNonClinicalSubUserIds();
    this.prevPatientName=this.patient?.name


    this.changeSubj.pipe(
      debounceTime(800),
    ).subscribe(()=>{
      this.save();
    });
    this.nameSubj.pipe(
      debounceTime(800),
    ).subscribe(()=>{
      if(this.prevPatientName==this.patient.name){return}
      this.nameUpdate();
    });
  }
  async nameUpdate(){
    this.nameChangeConfirmHappened=true;
    const approve=await this.modalService.openMulti("confirm",{
      actionLang: this.lang.getVal("do_you_confirm_change"),
      objectName:this.patient.name,
    });
    if(!approve){
      const storePatient=this.store.searchSiteDataItem(this.patient.id,["patients"]);
      if(storePatient){
        this.patient.name=storePatient.name;
      }
    }
    else{
      this.validateAndSave();
    }
  }

  async assocAddItem(obj: any) {
		const item = { user_id: obj.id, item_id: this.patient.id, item_type: "patient", active: "yes" };
    this.validateEntireForm();
		const res:any=await this.apiService.save_gen_item("object_to_sub_users", item);
    this.modalService.openToast(this.lang.getVal("assigned_to_assoc"));
    if(this.patient.assoc){
      this.patient.assoc=[...this.patient.assoc,obj.id];
    }
		// curRow[fieldObj.fieldName] = [...curRow[fieldObj.fieldName], obj.id];

	}
	async assocDeleteItem(id: any) {
		const item = { user_id: id, item_id: this.patient.id, item_type: "patient" };
    this.validateEntireForm();
		await this.apiService.post("delete_assoc",item);
    this.modalService.openToast(this.lang.getVal("unassigned_to_assoc"));
    if(this.patient.assoc?.length){
      this.patient.assoc=this.patient.assoc.filter(it=>it!=id);
    }
		// curRow[fieldObj.fieldName] = curRow[fieldObj.fieldName].filter(it => it != id);
	}


  rerouteTimeoutfunc(){
    clearTimeout(this.rerouteTimeout);
    this.rerouteTimeout=setTimeout(()=>{
      this.router.navigateByUrl("/");
    },15*60*1000);
  }

  async ngOnChanges(changes: SimpleChange) {
    if(changes["patient"]){
      this.prevPatientName=this.patient?.name;

      if(this.permsService?.owner_has_users){

        if(changes["patient"]["previousValue"]){
          if(!this.isLocked){
            console.log(123123123);
            clearTimeout(this.rerouteTimeout);
            this.apiService.post("release_patient_lock",{patient_id:changes["patient"]["previousValue"].id});
          }
        }
        if(changes["patient"]["currentValue"]){
          this.isLocked=false;
          clearTimeout(this.rerouteTimeout);
          const res=await this.apiService.post("is_patient_locked",{patient_id:changes["patient"]["currentValue"].id});
          if(res?.is_locked_by_other_user){
            this.isLocked=true;
          }
          else{
            this.rerouteTimeoutfunc();
          }
        }
      }

      // console.log(this.patient.id);
      this.tagIds=this.store.getSiteDataTable("object_to_tags").filter(row => row.item_type == "patient" && row.item_id == this.patient.id).map(it=>it.tag_id);
      this.tags=this.store.getSiteDataTable("tags")
      .filter(it => this.tagIds.includes(it.id)).map(it=>({...it,typeClassName: "tags"}));


      await this.store.timeout(50);
      if(this.taExpands?.length){
        this.taExpands.forEach(it=>{
          it.resetHeight();
        })
      }
      if(this.remarksWysiwyg){
        this.remarksWysiwyg.reset();
      }
    }
  }
  ngOnDestroy(){
    if(this.permsService?.owner_has_users){
      if(!this.isLocked){
        clearTimeout(this.rerouteTimeout);
        this.apiService.post("release_patient_lock",{patient_id:this.patient.id});
      }

    }
  }

	validateEntireForm() {	//validate all fields and detrmine if can be submitted (or has errors)
		this.isFormSubmittable = this.validator.validateEntireForm(this.patient, this.errors, this.validationFields);
	}


  validateAndSave(){
    this.validateEntireForm();
    this.changeSubj.next(true);
  }


	async save() {	//save the patient and notify the store if successful

    if(!this.isFormSubmittable){return;}

		const res:any=await this.apiService.post("update_patient",{...this.patient,tagIds:this.tagIds});

    this.patient.year_of_birth=res.year_of_birth;

    if(this.patient.date_of_birth){
      this.patient.age=moment.utc().diff(moment.utc(this.patient.date_of_birth),"years").toString();
    }
    else if(this.patient.year_of_birth){
      this.patient.age=moment.utc().diff(moment.utc(this.patient.year_of_birth),"years").toString();
    }



    let message = (!res || res.error) ? this.lang.getVal("save_failed") : this.lang.getVal("updated_successfully");	//set message by saving success
    this.modalService.openToast(message);	//toast the message to user

    if (res && !res.error) {	//if save successful - notify the store of the updated info

      this.patient.status=res.status;

      this.rerouteTimeoutfunc();
      this.store.updatePatient(this.patient as StorePatient);
      if(res?.tags){
        this.store.updatePatientTags(res.tags,"patient",this.patient.id);
      }

    }
	}
	openAssoc() {
		this.modalService.openMulti("gen-table-display", {
      tableName: "object_to_sub_users",
      filterField: "item_id",
      filterFieldApiObject: { item_type: "patient" },
      objectRow: this.patient
		})
	}
	openTasks() {
		this.modalService.openMulti("task", {
			patientRow: this.patient
		})
		// this.modalService.openMulti("task", {patient_id:this.patient.id})
	}
  openApotropus() {
		this.modalService.openMulti("gen-table-display", {
      tableName: "apotropuses",
      filterField: "patient_id",
      objectRow: this.patient
		})
	}

	openReferer() {
		this.modalService.openMulti("gen-table-display", {
      tableName: "refererpatients",
      filterField: "patient_id",
      objectRow: this.patient
		})
	}

  chooseTags(tags:any[]){
    this.tagIds=tags.map(it=>it.id);
    this.validateAndSave();
  }
  updateExcludedIds(tagObjs:any[]){
    this.excludeIds=this.store.getExcludedIdsByTagObjs(tagObjs);
  }
  async openAddPayorModal(){
    const obj: any = await this.modalService.openMulti("gen-table-add-item",{
      tableName: 'payors',
      fuzzyName: this.payorFuzzyInput.returnSearchStrForAdding()
    });

    if (obj) {	//expects either NULL if nothing happened or object of new inserted row!
      this.patient.payor_id = obj.id;
      this.payorFuzzyInput.searchStr = obj.name;

    }
    this.validateEntireForm();
  }
}
