import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { StoreService } from '@app/services/store.service';
import { GenModalComponent } from './gen-modal/gen-modal.component';

@Component({
  selector: 'app-multi-modals',
  template: `<ng-template #viewContainerRef></ng-template>`
})
export class MultiModalsComponent {
  stack:any[]=[];

  constructor(private modalService:ModalService,private store:StoreService){
    this.modalService.registerMulti(this);
  }

  @ViewChild("viewContainerRef", { read: ViewContainerRef }) vcr!: ViewContainerRef;
  // ref!: ComponentRef<YourChildComponent>

  // async open(componentToUse:any,data:any=null) {
  async open(componentPath:any,data:any=null) {

    // const comp:any=await import("@app/"+"google2fa-qr-code/google2fa-qr-code.component");
    // console.log(comp?.Google2faQrCodeComponent);


    const componentName=componentPath.split("-").map(it=>it.charAt(0).toUpperCase()+it.slice(1)).join("")+"Component";
    // console.log(componentPath,componentName);

    // const imp:any=await import("../"+componentPath+"/"+componentPath+".component");
    // console.log(imp,imp[componentName]);

    const componentToUse=(await import("../"+componentPath+"/"+componentPath+".component"))[componentName];
    // console.log(componentToUse);

    // const componentRef=this.vcr.createComponent(component);
    const componentRef=this.vcr.createComponent(GenModalComponent);
    if(!componentRef){return;}

    const instance:any=componentRef.instance;

    instance.close=this.close.bind(this);

    document.body.style.overflow = "hidden";	//hide body overflow to avoid scrolling issues
    this.stack.push(componentRef);
    await this.store.timeout();


    instance.chCV(true);	//make the wrapper show the content (the modal component's html) so it starts init etc
    instance.isOpened = true;

    instance.zIndex = this.modalService.getZIndex();	//zindex is calculated with step differences from last modal

    // instance.reset();	//call the reset method on this modal component model
    instance.eventsService.dragMouseUp(null);	//free drag

    // if (data) {	//if fieldsToUpdate exist - override the modal's variables with these values
    //   Object.keys(data).forEach(field => {
    //     instance[field] = data[field];
    //   })
    // }
    instance.componentToUse=componentToUse;
    instance.data=data;

    // if (instance.onOpen) {	//run onOpen (if exists in modal component model)
    //   instance.onOpen();
    // }
    instance.onOpen();

    const promiseRet=new Promise(resolve => {	//return a promise that will be resolved in close
      instance.promiseResolve = resolve;
    });

    await this.store.timeout(150);
    instance.chShow(true)

    return promiseRet;
  }

  async close(closeCallbackFuncObject:any=null) {

    if(!this.stack.length){return}
    this.modalService.stack.pop();
    const componentRef=this.stack.pop();
    const instance=componentRef.instance;

    // if (instance.onClose) {	//run onClose (if exists in modal component model)
		// 	instance.onClose();
		// }
		instance.onClose();

		if (instance.promiseResolve) {	//if has promise resolver func - call it and inject the parameter into it
			instance.promiseResolve(closeCallbackFuncObject);
			delete instance.promiseResolve;	//delete the reference
		}

		instance.chShow(false);	////wrapper becomes display:none

		instance.isOpened = false;


	  if (!this.stack.length) {	//if this was the last opened modal - restore the body's overflow
			document.body.style.overflow = "";
		}

    // instance.close=null;
    instance.close=()=>{};


    this.vcr.remove(this.vcr.length-1);
    await this.store.timeout(150);
    instance.chCV(false);	//remove content (the modal component itself) from the wrapper
    // const index = this.vcr.length;
    // if (index != -1) this.vcr.remove(index)
  }

}
