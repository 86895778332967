import {Inquiry_status, Location, Patientfield, SoloTimeAllocation} from './../definitions/types';
import { Injectable } from '@angular/core';
import { OptionsList } from '@app/definitions/opts';
import {
  Pricingtype, Apotropus,Admininvoice, Announce, Autotext, Bank, Bankaccount, Branch, City,Contact, Contacttype, Contactway, Creditcard, Creditcomp, Credittype, Dblog, Diagp, Diagppatient,
  Document, Documenttemplate, Expense, Expensegroup, Expensetype,Faq,Faq_subject, Followup, Followupserie, Followuptype, Groupattend, Grouppatient, Headture, Invoice, Labtest,
  Labtestpatient,Referer, Refererpatient, Medic, Medicpatient, Medicalog, Medilog, Meetingtype, Nondrug, Nondrugpatient, Notification, Smslog, Ownerbankaccount, Patient, Patienttype,
  Paymentapp, Payor, Payorprice, Prescription, Proforma, Supplier, TableGen, Tax, User, User_program, Clinic_role, Scheduled_report, Holiday_instance, Sub_user, Tag, Object_to_sub_user,Testgroup,
  Sub_user_tarif, State,Sub_user_group, Site,Sub_user_group_member, Tarif, Task, Task_comment, Task_recipient, TimeAlloaction,

} from '@app/definitions/types';
import { LangService } from './lang.service';
import { StoreService } from './store.service';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class GenTableService {

  constructor(protected lang: LangService,protected store:StoreService,protected apiService:ApiService) {
    //console.log(Object.keys(this.tableNamesToInitFuncs));

    // Object.keys(this.tableNamesToInitFuncs).forEach(key => {
    // 	const obj: any = {};
    // 	this.tableNamesToInitFuncs[key](obj);
    // 	if (!obj.openNewRowOnInit) {
    // 		console.log(key);

    // 	}
    // })
  }

  tableNamesToInitFuncs: any = {

    // announces: (tableComp: TableGen) => {
    // 	tableComp.collectionFields = [
    // 		{
    // 			fieldName: 'subject',
    // 			type: 'input',
    // 			validationRules: { 'not_empty': null },
    // 			attrs: { autofocus: 'autofocus' }
    // 		},
    // 		{ fieldName: 'message', type: 'input', attrs: {} },
    // 		{ fieldName: 'remarks', type: 'input', attrs: {} },
    // 		{
    // 			fieldName: 'an_lang',
    // 			type: 'button-group',
    // 			optionsCollection: OptionsList.announce_an_langOpts
    // 		},
    // 	];
    // 	tableComp.defaultNewRow = new Announce();
    // },
    //***************************************************************
    autotexts: (tableComp: TableGen) => {
      tableComp.mobileUpperFields = ["html_autotext"];
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },

        {
          fieldName: 'html_autotext',
          type: 'wysiwyg',
          tabindex: 2,
        },
        { fieldName: 'remarks', type: 'input', attrs: {} },
        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Autotext();
    },
    //***************************************************************
    banks: (tableComp: TableGen) => {
      tableComp.mobileUpperFields = ["name"];
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          attrs: { autofocus: 'autofocus' }
        },
        { fieldName: 'code', type: 'input', attrs: {} },
      ];
      tableComp.defaultNewRow = new Bank();
    },
    //****************************************************************
    bankaccounts: (tableComp: TableGen) => {
      tableComp.collectionFields = [
        {
          fieldName: 'bank_code',
          langName: 'bank',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true },
          attrs: {
            displayStringFieldName: "bank_name",
            fuzzySearchableClasses: ["banks"],
            tableName: "banks",
            objectFieldToSave: "code",
            avoidPlus: true,
            autofocus: "autofocus"
          },	//avoidPlus:true is optional,
          tabindex: 1,
          savingProps: {
            noSave: true,
            funcName: "resetOtherField",
            fieldName: "branch_id"
          },
          isNumberField:true,

        }, {
          fieldName: 'branch_id',
          langName: 'branch',
          type: 'fuzzy-api',
          validationRules: { 'is_legit_fuzzy_value': true },
          attrs: {
            displayStringFieldName: "branch_name",
            tableName: "branches",
            fuzzySearchObj: {
              apiCall: "fuzzy_online_search_branches",
              outerFilterName: "bank_code",

              innerFilterName: "filter_value",
            },
            optionDisplay: "%code% - %name%",
            avoidPlus: true
          },	//avoidPlus:true is optional
          tabindex: 2,
          savingProps: {
            funcName: "saveAnotherField",
            fieldName: "bank_code"
          },
        },
        {
          fieldName: 'number',
          langName: 'account_num',
          type: 'input',
          validationRules: { 'not_empty': null },
          tabindex: 3
        },
        {
          fieldName: 'name',
          type: 'input',
          tabindex: 4
        },
        {
          fieldName: 'is_foreign',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'foreign_bank',
          type: 'input',
          attrs: {}
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.mobileUpperFields = ["bank_code", "branch_id", "number"];
      tableComp.defaultNewRow = new Bankaccount();
    },
    ownerbankaccounts: (tableComp: TableGen) => {
      tableComp.collectionFields = [
        {
          fieldName: 'bank_code',
          langName: 'bank',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true },
          attrs: {
            displayStringFieldName: "bank_name",
            fuzzySearchableClasses: ["banks"],
            tableName: "banks",
            objectFieldToSave: "code",
            avoidPlus: true,
            autofocus: "autofocus"
          },	//avoidPlus:true is optional,
          tabindex: 1,
          savingProps: {
            noSave: true,
            funcName: "resetOtherField",
            fieldName: "branch_id"
          },
          isNumberField:true,
        }, {
          fieldName: 'branch_id',
          langName: 'branch',
          type: 'fuzzy-api',
          validationRules: { 'is_legit_fuzzy_value': true },
          attrs: {
            displayStringFieldName: "branch_name",
            tableName: "branches",
            fuzzySearchObj: {
              apiCall: "fuzzy_online_search_branches",
              outerFilterName: "bank_code",

              innerFilterName: "filter_value",
            },
            optionDisplay: "%code% - %name%",
            avoidPlus: true
          },	//avoidPlus:true is optional
          tabindex: 2,
          savingProps: {
            funcName: "saveAnotherField",
            fieldName: "bank_code"
          },
        },
        {
          fieldName: 'number',
          type: 'input',
          tabindex: 3
        },
        {
          fieldName: 'name',
          type: 'input',
          tabindex: 4
        },

      ];
      tableComp.mobileUpperFields = ["number","name"];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Ownerbankaccount();
    },
    //************************************************************

    contacts: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];
      tableComp.mobileUpperFields = ["name","show-item", "contactway"];
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.expanded = { upperFields: ["id", "name","show-item", "contactway", "contacttype_id", "invoice","notifiy",  "notification_default", "is_withholding_tax","withholding_tax_percent"] };
      tableComp.duplicateWarningFields = ["name"];
      tableComp.filterFields = [
        { fieldName: "status", opts: OptionsList.yes_no_opts },
        { fieldName: "notification_default", opts: OptionsList.notification_defaultOpts },

      ];
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'number',
          type: 'static',
          attrs: { width: "5%", minWidth: "5%", },
          isNumberField: true,
        },
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus', width: "20%", minWidth: "20%",mobileWidth: "50%", },
          tabindex: 1,
        },
        {
          fieldName:"show-item",
          type:"show-item",
          isSortable: false,
          attrs:{
            showItemBtnUrl:"/contacts/", minWidth: "5%", mobileWidth: "10%",
          }

        },

        {
          fieldName: 'contactway',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "10%",  mobileWidth: "30%",
            icon: "mobile",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "contactways", filterField: "contact_id" },
            rowVariableName: "objectRow",
            isInputInNewRow: true,

          }
        },



        {
          fieldName: 'contacttype_id',
          langName: 'contacttype',
          type: 'fuzzy',
          validationRules: { is_legit_fuzzy_value_or_empty: true },
          attrs: {
            displayStringFieldName: "contacttype_name",
            fuzzySearchableClasses: ["contacttypes"],
            tableName: "contacttypes",
            width: "10%", mobileWidth: "30%",
            showPlusPerm:"administrative",
          },	//avoidPlus:true is optional
          tabindex: 2,
          isNumberField:true,
        },
        {
          fieldName: 'invoice',
          langName: 'invoice_short',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "5%", mobileWidth: "20%",
            icon: "invoice-sm",
            isImg: true,
            modal: "add-invoice",
            fieldsToUpdate: { resource: "contact" },
            rowVariableName: "inputRow"
          },
        },
        /*{
					fieldName: 'account_in_hashav',
					type: 'input',
					validationRules: { 'is_integer': null },
					attrs: { width: "10%", minWidth: "10%", },
					isNumberField: true,
					tabindex: 3,
				},*/

        {
          fieldName: 'notifiy',
          type: 'button-group',
          attrs: { width: "10%", minWidth: "30%", },
          optionsCollection: OptionsList.yes_no_opts
        },
        /*{
					fieldName: 'notification_time',
					type: 'button-group',
					attrs: { width: "20%", minWidth: "20%", },
					optionsCollection: OptionsList.notifiyOpts

				},*/
        {
          fieldName: 'notification_default',
          type: 'button-group',
          attrs: { width: "10%", mobileWidth: "30%", },
          optionsCollection: OptionsList.notification_defaultOpts
        },
        {
          fieldName: 'is_withholding_tax',
          langName: 'withholding_tax',
          type: 'button-group',
          attrs: { width: "10%",  mobileWidth: "30%", },
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'withholding_tax_percent',
          type: 'input',
          validationRules: { 'is_decimal_or_zero': null },
          attrs: {width: "10%", minWidth: "10%", mobileWidth: "30%"  },
          isNumberField: true
        },
        {
          fieldName: 'address',
          type: 'input',
          validationRules: {  },
          attrs: { autofocus: 'autofocus', width: "20%", mobileWidth: "30%", },
          tabindex: 1,
        },
        {
          fieldName: 'bn_number',
          type: 'input',
          validationRules: { },
          attrs: { autofocus: 'autofocus', width: "10%", mobileWidth: "30%", },
          tabindex: 1,
        },

        /*{
          fieldName: 'status',
          type: 'button-group',
          attrs: { width: "10%", minWidth: "10%" },
          optionsCollection: OptionsList.yes_no_opts
        },*/

      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Contact();
    },
    apotropuses: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.expanded = { upperFields: [ "name", "relation","id_number"] };
      tableComp.mobileUpperFields = [ "name", "relation","id_number"];
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { width: "20%", minWidth: "20%", autofocus: "autofocus" },
          tabindex: 1,
        },
        {
          fieldName: 'id_number',
          isSortable: false,
          type: 'input',
          attrs: { width: "20%", minWidth: "20%" },
          tabindex: 2,
        },
        //contact_id_to
        {
          fieldName: 'relation',
          type: 'input',
          attrs: { width: "20%", minWidth: "20%" },
          tabindex: -1,
        },

      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Apotropus();
    },
    // cities: (tableComp: TableGen) => {
    //   tableComp.searchableFields = ["name"];
    //   tableComp.sortField = "name";
    //   tableComp.collectionFields = [
    //     {
    //       fieldName: 'name',
    //       type: 'input',
    //       validationRules: { 'not_empty': null, "unique": null },
    //       attrs: { width: "30%", minWidth: "20%" },
    //       tabindex: 1,
    //     },
    //   ];
    //
    //   tableComp.openNewRowOnInit = true;
    //   tableComp.mobileUpperFields = ["name"];
    //   tableComp.defaultNewRow = new City();
    //
    // },
    contactways: (tableComp: TableGen) => {
      tableComp.searchableFields = ["contactway"];
      tableComp.sortField = "contactway";
      tableComp.expanded = { upperFields: ["contactway","whatsapp","phone","name"] };
      tableComp.mobileUpperFields = ["contactway","whatsapp","phone"];
      tableComp.expandOnLoad = true;
      tableComp.collectionFields = [



        {
          fieldName: 'contactway',
          langName: 'email_phone',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { width: "50%",minWidth: "118px", autofocus: "autofocus" ,},
          tabindex: 1,
        },
        {
          fieldName:"whatsapp",
          type:"whatsapp",
          isSortable: false,
          // hideOnNonMobile:true,
          // hideOnMobile:true,
          attrs:{
            width: "70px",
          }

        }, {
          fieldName:"phone",
          type:"phone",
          isSortable: false,
          hideOnNonMobile:true,
          attrs:{
            width: "70px",
          }

        },
        {
          fieldName: 'name',
          isSortable: false,
          type: 'input',
          attrs: { width: "50%", minWidth: "106px" },
          tabindex: 2,
        },
        //contact_id_to
        {
          fieldName: 'description',
          type: 'input',
          attrs: { width: "calc(50% - 70px)", minWidth: "106px"  },
          tabindex: -1,
        },


        {
          fieldName: 'main',
          // hideOnNonMobile:true,
          type: 'button-group',
          attrs: {  width: "85px" },
          optionsCollection: OptionsList.main_opts
        },
        {
          fieldName: 'contactway_identifier',
          type: 'hidden',
          attrs: { width: "15%", minWidth: "15%",updatedByServer:true },
          optionsCollection: OptionsList.contactway_identifier_opts
        },

      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Contactway();
    },
    contactway_list: (tableComp: TableGen) => {

      tableComp.collectionFields = [
        {
          fieldName: 'email',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: { width: "40%", minWidth: "60%",mobileWidth: "80%", autofocus: "autofocus" ,},
          tabindex: 1,
        },
        {
          fieldName: 'name_dec',
          langName: 'name',
          isSortable: false,
          type: 'static',
          attrs: { width: "30%", minWidth: "30%" },
          tabindex: 2,
        },
        //contact_id_to


      ];
      tableComp.openNewRowOnInit = true;
      tableComp.mobileUpperFields = ["email"];
    },
    contacttypes: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },
        { fieldName: 'code', type: 'input', attrs: {} },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Contacttype();
    },
    creditcards: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },
        { fieldName: 'more', type: 'input', attrs: {} },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Creditcard();
    },
    creditcomps: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.mobileUpperFields = ["name"];
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },
        { fieldName: 'address', type: 'input', attrs: {} },
        { fieldName: 'more', type: 'input', attrs: {} },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Creditcomp();
    },
    dblogs: (tableComp: TableGen) => {
      /*u_id:number = 0;
php_sapi_name:string = '';
level:string = '';
url:string = '';
message:string = '';
context:string = '';
			* */
      tableComp.searchableFields = ["u_id"];
      tableComp.mobileUpperFields = ["u_id","user_name"];
      tableComp.filterFields = [
        { fieldName: "level", opts: OptionsList.level_opts, lang: "info", defaultValue: "info" }
        // ,{fieldName:"is_group",opts:OptionsList.active_opts,lang:"group",defaultValue:"no"},
      ];
      tableComp.sortField = "u_id";
      tableComp.sortIsAsc = false;
      tableComp.itemsPerPage = 50;
      tableComp.collectionFields = [
        {
          fieldName: 'u_id',
          type: 'static',
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },
        {
          fieldName: 'log_created',
          langName: 'created_at',
          type: 'static',
          attrs: {}
        },
        { fieldName: 'user_name', langName: 'user', type: 'static', attrs: {} },
        { fieldName: 'level', type: 'static', attrs: {} },

        { fieldName: 'message', type: 'static', attrs: {} },
        {
          fieldName: 'time_spent',
          type: 'static',
          attrs: {}
        },
      ];
      tableComp.defaultNewRow = new Dblog();

    },
    smslogs: (tableComp: TableGen) => {
      tableComp.searchableFields = ["phone"];
      tableComp.mobileUpperFields = ["phone","created_at"];
      tableComp.sortField = "created_at";
      tableComp.sortIsAsc = false;
      tableComp.collectionFields = [

        {
          fieldName: 'created_at',
          langName: 'created_at',
          type: 'date',
          attrs: {}
        },
        {
          fieldName: 'patient_id',
          langName: 'patient',
          type: 'static',
          attrs: {
            displayStringFieldName: "patient_name",
            fuzzySearchableClasses: ["patients"],
            tableName: "patients",
            avoidPlus:true,
          },	//avoidPlus:true is optional
        },
        {
          fieldName: 'contact_id',
          langName: 'contact',
          type: 'static',
          attrs: {
            displayStringFieldName: "contact_name",
            fuzzySearchableClasses: ["contacts"],
            tableName: "contacts",
            avoidPlus:true,
          },	//avoidPlus:true is optional
        },{
          fieldName: 'payor_id',
          langName: 'payor',
          type: 'static',
          attrs: {
            displayStringFieldName: "payor_name",
            fuzzySearchableClasses: ["payors"],
            tableName: "payors",
            avoidPlus:true,
          },	//avoidPlus:true is optional
        },
        /* {
          fieldName: 'user_id',
          langName: 'user',
          type: 'static',
          attrs: {
            displayStringFieldName: "user_name",
            fuzzySearchableClasses: ["users"],
            tableName: "users",
            avoidPlus:true,
          },	//avoidPlus:true is optional
        },

        */

        { fieldName: 'phone', langName: 'phone', type: 'static', attrs: {} },
      ];

    },
    credittypes: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },
        { fieldName: 'more', type: 'input', attrs: {} },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Credittype();
    },
    diagppatients: (tableComp: TableGen) => {
      tableComp.expanded = { upperFields: ["diagp_name", "code", "dx_status"] };
      tableComp.mobileUpperFields = [ "dx_status","diagp_name", "code"];
      tableComp.searchableFields = ["diagp_name"];
      tableComp.sortField = "start_date";
      tableComp.sortIsAsc = false;
      tableComp.expandOnLoad = true;
      tableComp.filterFields = [
        {
          fieldName: "end_date",
          opts: OptionsList.medicpatients_filters,
          lang: "show",
          checkType: "null",
          defaultValue: "null",
          canEditOnValue: "null"
        },
      ];
      tableComp.collectionFields = [
        {
          fieldName: 'dx_status', type: 'select',
          attrs: { width: "50px", minWidth: "50px",staticOnEdit: true,},
          tabindex: 1,
          optionsCollection: OptionsList.dx_status_opts
        },
        {
          fieldName: 'code',
          type: 'static',
          attrs: { updatedByServer: true, width: "50px", minWidth: "50px", }
        },
        {
          fieldName: 'diagp_name',
          type: 'fuzzy-api',
          validationRules: { 'not_empty': true },
          attrs: {
            autofocus: 'autofocus',
            displayStringFieldName: "diagp_name",
            width: "70%", minWidth: "70%",
            mobileWidth: "245px",
            tableName: "diagps",
            staticOnEdit: true,
            showPlusPerm:"administrative",
            idNameConnection: { idField: "diagp_id" },

            fuzzySearchObj: {
              apiCall: "fuzzy_online_search_diagps",
              outerFilterName: "",
              minLength: 3,
              innerFilterName: "filter_value",
              otherFieldsToUpdate: ["code"],
            },
            avoidPlus: true
          },	//avoidPlus:true is optional
          tabindex: 2,
        },


        {
          fieldName: 'start_date',
          type: 'date',
          validationRules: { 'not_empty': null },
          attrs: {mobileWidth: "100px",staticOnEdit: true,}
        },
        {
          fieldName: 'end_date',
          type: 'date',
          validationRules:{today_and_before:true},
          attrs: { avoidDateReset: true,mobileWidth: "100px",disabledAfterToday:true }
        },
        { fieldName: 'remarks', type: 'input', attrs: {width: "45%",mobileWidth: "90%"} },

      ];

      tableComp.headerModalOpenBtns = [
        {
          icon: "list",
          hoverLang: "diagps",
          modal: "gen-table-display",
          fieldsToUpdate: { tableName: "diagps", isPagi: true },
        }
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Diagppatient();
    },
    documents: (tableComp: TableGen) => {
      tableComp.sortField = "date";
      tableComp.searchableFields = ["doc_name"];
      tableComp.mobileUpperFields = ["date","doc_name","title","recipient",];
      tableComp.sortIsAsc = false;
      tableComp.isCheckItems = true;
      tableComp.hideExport=true;
      tableComp.expandOnLoad = true;

      tableComp.headerModalOpenBtns = [
        {
          // שליחת המסמכים שנבחרו בדוא"ל"
          // "שמירת המסמכים שנבחרו כקובץ PDF"
          // icon: "emailLarge",
          // hoverLang: "send_documents_to_patient",
          icon: "emailLarge",
          hoverLang: "email_multiple_invoices",
          modal: "gen-table-display",
          funcToRunOnReturn: "sendMultipleFinresources",
          addFilterFieldValueFromTable: true,
          visibleCheckedOnly:true,
          textOnly:true,
          fieldsToUpdate: {
            tableName: "contactways",
            filterField: tableComp.filterField,
            fieldNameInItemToDisplay: "contactway",
            isCheckItems: true,
          },
        },
        {
          icon: "print",
          hoverLang: "print_multiple_invoices",
          funcToRunOnReturn: "printMultipleFinresources",
          visibleCheckedOnly:true,
          textOnly:true,
        },
      ];

      tableComp.collectionFields = [
        { fieldName: 'date', type: 'date', attrs: { width: "8%", minWidth: "8%",mobileWidth: "15%"} },
        {
          fieldName: 'title',
          type: 'static',
          attrs: { width: "45%", minWidth: "45%",mobileWidth: "15%" },

        },
        {
          fieldName: 'recipient',
          type: 'static',
          attrs: { width: "10%", minWidth: "15%",mobileWidth: "15%" },
        },


        {
          fieldName: 'pdf-view',
          isSortable: false,
          type: 'pdf-view',
          attrs: {
            downloadType: "document",
            staticOnPerms: ["authorized_signer"],
            width: "5%", minWidth: "5%",mobileWidth: "15%",
            ngIfFunc:(row:any)=>{return (row.doc_type=="html" && row.signed=="yes") || (row.doc_type=="upload" && row.extension=="pdf")}
          } //console.log((row.doc_type=="html" && row.signed=="yes") || (row.doc_type=="upload" && row.extension=="pdf"),row);
        },
        {
          fieldName: 'download',

          isSortable: false,
          type: 'download',
          attrs: { downloadType: "document", staticOnPerms: ["authorized_signer"], width: "10%", minWidth: "10%",mobileWidth: "20%",}
        },
        {
          fieldName: 'email',
          langName: 'submit',
          isSortable: false,
          type: 'mail-to',
          attrs: { downloadType: "document", staticOnPerms: ["authorized_signer"], width: "5%", minWidth: "5%",mobileWidth: "15%" },
        },
        {
          fieldName: 'document-action',
          langName: '',
          isSortable: false,
          type: 'document-action',
          attrs: {  width: "5%", minWidth: "5%",mobileWidth: "30%" },
        },
        // {
        //   fieldName: 'remarks',
        //   type: 'input',
        //   validationRules: { 'not_empty': null },
        //
        // },

        //	{ fieldName: 'from',   type: 'input',  attrs: {} },
        //	{ fieldName: 'html_content',   type: 'input',  attrs: {} },
        //	{ fieldName: 'recipient',   type: 'input',  attrs: {} },
        //	{ fieldName: 'address',   type: 'input',  attrs: {} },
        //	{ fieldName: 'remarks',   type: 'input',  attrs: {} },


      ];
      // tableComp.headerModalOpenBtns=[
      //   {
      //     icon: "plus",
      //     hoverLang:"document",
      //     modal: "add-document",
      //     fieldsToUpdate: { tableName: "documents",  },
      //     dataVariablesFields:["patient_id"]
      //   }
      // ];
      tableComp.filterFields = [
        { fieldName: "doc_type", opts: OptionsList.document_doc_types,defaultValue:"html" },
      ];

      // tableComp.openNewRowOnInit=true;
      tableComp.defaultNewRow = new Document();
    },
    documenttemplates: (tableComp: TableGen) => {
      tableComp.searchableFields = ["title"];
      tableComp.mobileUpperFields = ["title"];
      tableComp.collectionFields = [
        {
          fieldName: 'title',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus', width: "20%", minWidth: "20%" },
          tabindex: 1,
        },
        {
          fieldName: 'html_content',
          type: 'wysiwyg',
          tabindex: 2,
        },
      ];

      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Documenttemplate();
    },
    expenses: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];
      // tableComp.expanded = { upperFields: ["id","date","expensetype_id","description","sum","supplier_id"] };//
      tableComp.searchableFields = ["description"];
      tableComp.mobileUpperFields = ["expensetype_id","date"];
      tableComp.sortField = "date";
      tableComp.sortIsAsc = false;
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'number',
          type: 'static',
          isNumberField: true,
        },
        {
          fieldName: 'description',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus',width: "200px"},
          tabindex: 1,
        },
        {
          fieldName: 'expensetype_id',
          langName: 'expensetype',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true },
          attrs: {
            width: "300px",
            displayStringFieldName: "expensetype_name",
            fuzzySearchableClasses: ["expensetypes"],
            tableName: "expensetypes",
            showPlusPerm:"administrative",
            fuzzySearchObj: {
              // apiCall: "fuzzy_online_search_diagps",
              // outerFilterName: "",
              // minLength: 3,
              // innerFilterName: "filter_value",
              otherFieldsToUpdate: ["withvat"],
            },
          },	//avoidPlus:true is optional
          isSiteDataSortField: true,
          tabindex: 2,
        },
        {
          fieldName: 'user_id',
          langName: 'user',
          type: 'static',
          attrs: {
            tableName: "sub_users",
          },

        },
        {
          fieldName: 'supplier_id',
          langName: 'supplier',
          type: 'fuzzy',
          validationRules: { is_legit_fuzzy_value_or_empty: true },
          attrs: {
            width: "300px",
            displayStringFieldName: "supplier_name",
            fuzzySearchableClasses: ["suppliers"],
            tableName: "suppliers",
            showPlusPerm:"administrative",
          },	//avoidPlus:true is optional
          isSiteDataSortField: true,
          tabindex: 3,
        },
        {
          fieldName: 'supplier_invoice',

          type: 'input',
          tabindex: 4,
          attrs: {
            width: "100px",
          }
        },
        {
          fieldName: 'date',
          type: 'date',
          validationRules: { 'not_empty': null },
          tabindex: 5,
          attrs: {width: "100px" },
        },
        {
          fieldName: 'sum',
          type: 'input',
          validationRules: { 'is_decimal': null, 'not_zero': null },
          isNumberField: true,
          tabindex: 6,
          attrs: {width: "150px", minWidth: "10%" },
        },
        {
          fieldName: 'withvat',
          type: 'yes-no',
          isHideable: true,

        },
      ];
      tableComp.headerModalOpenBtns = [
        {
          icon: "expensetype",
          hoverLang: "expensetypes",
          modal: "gen-table-display",
          fieldsToUpdate: { tableName: "expensetypes" },
        },
        {
          icon: "supplier",
          hoverLang: "suppliers",
          modal: "gen-table-display",
          fieldsToUpdate: { tableName: "suppliers" },
        },
        {
          icon: "expense",
          hoverLang: "expenses_report",
          modal: "reports",
          fieldsToUpdate: { report_name: "expenses_report" },
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Expense();
    },
    // expensegroups: (tableComp: TableGen) => {
    //   tableComp.perms = ["administrative"];
    //   tableComp.searchableFields = ["name"];
    //   tableComp.mobileUpperFields = ["title"];
    //   tableComp.sortField = "name";
    //   tableComp.collectionFields = [
    //     {
    //       fieldName: 'name',
    //       type: 'input',
    //       validationRules: { 'not_empty': null, "unique": null },
    //       attrs: { autofocus: 'autofocus' },
    //       tabindex: 1,
    //     },
    //   ];
    //
    //   tableComp.openNewRowOnInit = true;
    //   tableComp.defaultNewRow = new Expensegroup();
    // },
    expensetypes: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: {  width: "400px", mobileMinWidth: "80%" },
          tabindex: 1,
        },
        {
          fieldName: 'percent_for_tax',
          type: 'input',
          validationRules: { 'is_decimal_or_zero': null },
          tabindex: 2,
          isNumberField: true
        },
        {
          fieldName: 'percent_for_vat',
          type: 'input',
          validationRules: { 'is_decimal_or_zero': null },
          tabindex: 3,
          isNumberField: true,
          isHideable: true,

        },
        {
          fieldName: 'permanent',
          type: 'button-group',
          isHideable: true,
          optionsCollection: OptionsList.expensetype_permanentOpts,

        },
        {
          fieldName: 'withvat',
          type: 'button-group',
          isHideable: true,
          optionsCollection: OptionsList.withvatOpts,

        },
      ];

      tableComp.openNewRowOnInit = true;
      tableComp.mobileUpperFields = ["name"];
      tableComp.defaultNewRow = new Expensetype();

    },
    scheduled_reports: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];
      tableComp.searchableFields = [];
      tableComp.sortField = "contact_id";
      tableComp.mobileUpperFields = ["contact_id","report_name"];
      tableComp.collectionFields = [
        {
          fieldName: 'contact_id',
          langName: 'contact',
          type: 'fuzzy',
          isNumberField:true,
          attrs: {
            displayStringFieldName: "contact_name",
            fuzzySearchableClasses: ["contacts"],
            tableName: "contacts",
            staticOnEdit: true,
            width: "10%", minWidth: "10%",
            showPlusPerm:"administrative",
          },	//avoidPlus:true is optional

        },

        {
          fieldName: 'report_name',
          type: 'button-group',
         // validationRules: { unique_filtered_by_other_field: "contact_id" },
          attrs: { width: "40%", minWidth: "40%" },
          optionsCollection: OptionsList.report_name_opts,
        },
        {
          fieldName: 'send_freq',
          type: 'button-group',
          attrs: { width: "15%", minWidth: "15%" },
          optionsCollection: OptionsList.send_freq_opts,
        },
        {
          fieldName: 'file_format',
          type: 'button-group',
          attrs: { width: "10%", minWidth: "10%" },
          optionsCollection: OptionsList.file_format_opts,
        },
        {
          fieldName: 'date_in_month',
          type: 'button-group',
          attrs: { width: "15%", minWidth: "15%" },
          optionsCollection: OptionsList.date_in_month_opts,
        },
      ];

      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Scheduled_report();

    },
    mixed_list: (tableComp: TableGen) => {
      tableComp.searchableFields = ["description"];
      tableComp.mobileUpperFields = ["date","meetingtype_name"];
      tableComp.sortField = "date";
      tableComp.sortIsAsc = false;
      tableComp.collectionFields = [
        {
          fieldName: 'date',
          type: 'date',
          attrs: {}
        },
        {
          fieldName: 'meetingtype_name',
          langName: 'meetingtype',
          type: 'static',
          attrs: {}
        },
        {
          fieldName: 'price',
          type: 'static',
          attrs: {}
        },
        {
          fieldName: 'status',
          type: 'static',
          attrs: { outputLang: true }
        },

        // {
        // 	fieldName: 'description',
        // 					// 	type: 'textarea',
        // 					// 	attrs: { width: "100%", minWidth: "90%" }
        // },
        // {
        // 	fieldName: 'meetingtype_name',
        // 	langName: 'meetingtype',
        // 					// 	type: 'static',
        // 					// 	attrs: {}
        // },
        // {
        // 	fieldName: 'personal_memorandum',
        // 					// 	type: 'static',
        // 					// 	attrs: { width: "100%", minWidth: "90%" },
        // 	isHideable: true,
        // },
        // {
        // 	fieldName: 'price',
        // 					// 	type: 'static',
        // 					// 					// 	isNumberField: true
        // },

        // {
        // 	fieldName: 'arrived',
        // 					// 	type: 'static',
        // 					// 					// 	optionsCollection: OptionsList.yes_no_opts
        // },
        // {
        // 	fieldName: 'status',
        // 					// 	type: 'static',
        // 					// 					// 	optionsCollection: OptionsList.deleted_active_opts
        // },

      ];
    },
    followups_update_list: (tableComp: TableGen) => {
      tableComp.searchableFields = ["date"];
      tableComp.mobileUpperFields = ["date","time","price","charged"];
      tableComp.sortField = "date";
      tableComp.sortIsAsc = false;
      tableComp.collectionFields = [
        {
          fieldName: 'date',
          type: 'date',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' }
        },
        {
          fieldName: 'time',
          type: 'time',
          validationRules: { 'not_empty': null },
          attrs: {}
        },
        { fieldName: 'date_to', type: 'date', attrs: {} },
        {
          fieldName: 'series_repeat',
          type: 'input',
          optionsCollection: OptionsList.series_repeat_opts
        },
        {
          fieldName: 'price',
          type: 'input',
          isNumberField: true
        },
        {
          fieldName: 'price_payor',
          type: 'input',
          isNumberField: true
        },

        {
          fieldName: 'takes_place',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'charged',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'charged_manualy',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'arrived',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'status',
          type: 'button-group',
          optionsCollection: OptionsList.deleted_active_opts
        },


      ];
    },
    followups: (tableComp: TableGen) => {
      tableComp.searchableFields = ["description"];
      tableComp.mobileUpperFields = ["date", "time", "description"];
      tableComp.expanded = { upperFields: ["date", "time", "description"] };
      tableComp.collectionFields = [
        {
          fieldName: 'date',
          type: 'date',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' }
        },
        {
          fieldName: 'time',
          type: 'time',
          validationRules: { 'not_empty': null },
          attrs: {}
        },
        { fieldName: 'date_to', type: 'date', attrs: {} },
        {
          fieldName: 'series_repeat',
          type: 'input',
          optionsCollection: OptionsList.series_repeat_opts
        },
        //patient_id
        //contact_id
        //  followuptype_id
        // meetingtype_id:
        //payor_id
        {
          fieldName: 'description',
          type: 'input',
          attrs: {}
        },
        { fieldName: 'remarks', type: 'input', attrs: {} },
        {
          fieldName: 'personal_memorandum',
          type: 'input',
          attrs: {}
        },
        {
          fieldName: 'price',
          type: 'input',
          isNumberField: true
        },
        {
          fieldName: 'price_payor',
          type: 'input',
          isNumberField: true
        },

        {
          fieldName: 'takes_place',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'charged',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'charged_manualy',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'arrived',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'status',
          type: 'button-group',
          optionsCollection: OptionsList.deleted_active_opts
        },
        {
          fieldName: 'done',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
      ];
      tableComp.defaultNewRow = new Followup();
    },
    followupseries: (tableComp: TableGen) => {
      tableComp.mobileUpperFields = ["date", "time", "series_repeat"];
      tableComp.collectionFields = [
        {
          fieldName: 'date',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' }
        },
        {
          fieldName: 'time',
          type: 'time',
          validationRules: { 'not_empty': null },
          attrs: {}
        },
        { fieldName: 'date_to', type: 'input', attrs: {} },
        {
          fieldName: 'series_repeat',
          type: 'input',
          optionsCollection: OptionsList.series_repeat_opts
        },
        //patient_id
        //contact_id
        //  followuptype_id
        // meetingtype_id:
        //payor_id
        {
          fieldName: 'description',
          type: 'input',
          attrs: {}
        },
        { fieldName: 'remarks', type: 'input', attrs: {} },
        {
          fieldName: 'personal_memorandum',
          type: 'input',
          attrs: {}
        },
        {
          fieldName: 'price',
          type: 'input',
          isNumberField: true
        },
        {
          fieldName: 'price_payor',
          type: 'input',
          isNumberField: true
        },

        {
          fieldName: 'takes_place',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'charged',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'charged_manualy',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'arrived',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'status',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'done',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
      ];
      tableComp.defaultNewRow = new Followupserie();
    },
    followuptypes: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'static',
          attrs: { outputLang: true }
        },
        {
          fieldName: 'length',
          type: 'input',
          validationRules: { 'is_integer': null },
          isNumberField: true,
          tabindex: 1,
        },
        {
          fieldName: 'price',
          type: 'input',
          validationRules: { 'is_integer': null },
          isNumberField: true,
          tabindex: 2,
        },
        // {
        // 	fieldName: 'is_default',
        // 	type: 'button-group',
        // 	optionsCollection: OptionsList.yes_no_opts
        // },
        // {
        // 	fieldName: 'is_all_day',
        // 	type: 'button-group',
        // 	optionsCollection: OptionsList.yes_no_opts
        // },
        {
          fieldName: 'takes_place',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
      ];

      tableComp.defaultNewRow = new Followuptype();

    },
    grouppatients: (tableComp: TableGen) => {
      tableComp.searchableFields = [];
      tableComp.mobileUpperFields = ["patient_id","price"];
      tableComp.collectionFields = [
        {
          fieldName: 'patient_id',
          langName: 'patient',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true, unique: true },
          attrs: {
            displayStringFieldName: "patient_name",
            fuzzySearchableClasses: ["patients", "patients_for_groups","sub_users"],
            tableName: "patients",
            autofocus: 'autofocus',
            width: "30%",
            minWidth: "20%",
            staticOnEdit: true,
            showPlusPerm:"administrative",
            avoidPlus:true,

            // otherFieldToUse: {
            // 	fieldName: "user_id",
            // 	fuzzySearchableClasses: ["sub_users"],
            // 	validationRules: { 'is_legit_fuzzy_value': true, unique: true }
            // },

          },	//avoidPlus:true is optional
          isNumberField: true,
          tabindex: 1,
        },
        {
          fieldName:"show-item",
          type: 'button',
          isSortable: false,
          attrs: {
            width: "5%", minWidth: "5%",
            icon: "show",
            modal: "patient",
          },

        },
        {
          fieldName: 'invoice',
          langName: 'invoice_short',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "5%", minWidth: "5%",
            icon: "invoice-sm",
            isImg: true,
            modal: "add-invoice",
            fieldsToUpdate: { resource: "patient" },
            rowVariableName: "inputRow"
          },
        },
        {
          fieldName: 'price',
          type: 'input',
          validationRules: { 'is_integer': null },
          isNumberField: true,
          tabindex: 2,
        },
        {
          fieldName: 'remarks',
          type: 'input',
          attrs: {
            width: "400px",
          },
        },
      ];

      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Grouppatient();
      tableComp.sortField = "id";
    },
    headtures: (tableComp: TableGen) => {
      //template_id:number = 0;
      tableComp.mobileUpperFields = ["header_default","header_text"];
      tableComp.collectionFields = [
        {
          fieldName: 'header_default',
          isSortable: false,
          type: 'select',
          validationRules: { unique_filtered_by_other_field: "lang" },
          attrs: { width: "10%", mobileWidth: "30%", },
          optionsCollection: OptionsList.headture_header_defaultOpts
        },

        {
          fieldName: 'header_text',
          isSortable: false,
          type: 'textarea',
          validationRules: { 'not_empty': null, headture_textual: true },
          attrs: { headturesInsert: true, height: "100px", width: "25%",mobileWidth: "50%", },
          tabindex: 2,
        },
        {
          fieldName: 'signature_text',
          isSortable: false,
          type: 'textarea',
          validationRules: { 'not_empty': null },
          attrs: { width: "25%", mobileWidth: "50%", },
          tabindex: 3,
        },
        {
          fieldName: 'logo',
          isSortable: false,
          type: 'image',
          validationRules: { "file_max_size": 6 * 1000 * 1000 + 1 },
          attrs: { urlTemplate: "/headture-logo/%id%", width: "15%", mobileWidth: "50%",maxDims:{width:1500,height:1500}} //
        },
        {
          fieldName: 'signature_logo',
          isSortable: false,
          type: 'image',
          validationRules: { "file_max_size": 6 * 1000 * 1000 + 1 },
          attrs: { urlTemplate: "/headture-signature-logo/%id%", width: "15%", mobileWidth: "50%",maxDims:{width:1500,height:1500}} //
        },
        {
          fieldName: 'name',
          langName: 'description',
          isSortable: false,
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus', width: "10%", minWidth: "10%", mobileWidth: "80%"},
          tabindex: 1,
        },
        {
          fieldName: 'lang',
          isSortable: false,
          type: 'button-group',
          validationRules: { unique_filtered_by_other_field: "header_default" },
          attrs: {
            width: "5%",
            mobileWidth: "20%",
          },
          optionsCollection: OptionsList.langOpts
        },
        // {
        //   fieldName: 'template_id',
        //   isSortable: false,
        //   type: 'input',
        //   validationRules: { 'not_empty': null },
        //   attrs: { width: "5%", minWidth: "5%", },
        //   tabindex: 1,
        // },

        // {
        //   fieldName: 'select_a_headture',
        //   isSortable: false,
        //   type: 'button',
        //   attrs: {
        //     icon: "header",
        //     modal: "preview-finresource",
        //     fieldsToUpdate: {},
        //     rowVariableName: "headtureRow", width: "5%", minWidth: "5%"
        //   },	//fieldsToUpdate:{fieldName:val....} not required
        //   //rowVariableName:string (no required) is the name of the variable inside the modal that will RECEIVE the row that I clicked
        // },
        {
          fieldName: 'select_a_headture2',
          langName: 'design_your_document',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "header",
            modal: "preview-finresource",
            fieldsToUpdate: {isDemoMode:true},
            rowVariableName: "headtureRow", width: "5%", mobileWidth: "30%",
          },
        },
      ];
      tableComp.openNewRowOnInit = true;
      // tableComp.addRowIsByPlus = true;
      tableComp.defaultNewRow = new Headture();
    },
    admininvoices: (tableComp: TableGen) => {
      tableComp.mobileUpperFields = ["id"];
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'invoice',
          type: 'static',
          isNumberField: true,
        },
        {
          fieldName: 'download',
          type: 'download',
          isSortable: false,
          attrs: { downloadType: "admininvoice" }
        },
        {
          fieldName: 'email',
          isSortable: false,
          type: 'mail-to',
          attrs: { downloadType: "admininvoice" },
        },
        {
          fieldName: 'description',
          type: 'static',
          attrs: {}
        },

        {
          fieldName: 'total',
          type: 'static',
          isNumberField: true
        },

        {
          fieldName: 'created_at',
          langName: 'date',
          type: 'date',
          attrs: { autofocus: 'autofocus' }
        },
      ];
      tableComp.defaultNewRow = new Admininvoice();
      tableComp.searchableFields = ["id"];
      tableComp.sortField = "id";
    },
    prescriptions: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "id";
      tableComp.sortIsAsc = false;
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'prescription',
          type: 'static',
          isNumberField: true,
        },
        { fieldName: 'name', type: 'static', attrs: {} },
        {
          fieldName: 'date',
          type: 'date',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' }
        },
        { fieldName: 'remarks', type: 'static', attrs: {} },
        {
          fieldName: 'download',
          type: 'download',
          isSortable: false,
          attrs: { width: "12%", minWidth: "12%", downloadType: "prescription" }
        },
        {
          fieldName: 'renew',
          type: 'renew',
          isSortable: false,
          attrs: { width: "2%", minWidth: "2%", downloadType: "prescription" }
        },
        {
          fieldName: 'sendgrid_status',
          type: 'staticIcon',
          attrs: { width: "2%", minWidth: "2%", outputLang: true }
        },
        {
          fieldName: 'email',
          isSortable: false,
          type: 'mail-to',
          attrs: { width: "2%", minWidth: "2%", downloadType: "prescription" },
        },
      ];
      tableComp.defaultNewRow = new Prescription();
    },
    finResource_list: (tableComp: TableGen) => {
      // console.log(tableComp.filterField);
      //tableComp.expanded = { upperFields: ["created_at", "total", "doctype", "download", "email", "sendgrid_status", "credit_invoice_short"]}
      //tableComp.mobileUpperFields = ["created_at", "total", "doctype", "download", "email"]
      tableComp.headerModalOpenBtns = [
        {
          // שליחת המסמכים שנבחרו בדוא"ל"
          // "שמירת המסמכים שנבחרו כקובץ PDF"
          // icon: "emailLarge",
          // hoverLang: "send_documents_to_patient",
          icon: "emailLarge",
          hoverLang: "email_multiple_invoices",
          modal: "gen-table-display",
          funcToRunOnReturn: "sendMultipleFinresources",
          addFilterFieldValueFromTable: true,
          visibleCheckedOnly:true,
          textOnly:true,
          fieldsToUpdate: {
            tableName: "contactways",
            filterField: tableComp.filterField,
            fieldNameInItemToDisplay: "contactway",
            isCheckItems: true,
          },
        },
        {
          icon: "print",
          hoverLang: "print_multiple_invoices",
          funcToRunOnReturn: "printMultipleFinresources",
          visibleCheckedOnly:true,
          textOnly:true,
        },
      ];

      tableComp.collectionFields = [
        {
          fieldName: 'created_at',
          langName: 'date',
          type: 'date',
          validationRules: {},
          attrs: { width: "10%", minWidth: "10%" ,mobileWidth: "15%", staticOnEdit: true}
        },
        {
          fieldName: 'total',
          type: 'static',
          isNumberField: true,
          attrs: { width: "10%", minWidth: "10%" }
        },
        // {
        //   fieldName: 'vat',
        //   type: 'input',
        //   isNumberField: true
        // },
        {
          fieldName: 'description',
          type: 'static',
          attrs: { width: "30%" }
        },
        {
          fieldName: 'pdf-view',
          isSortable: false,
          type: 'pdf-view',
          attrs: {
            width: "5%", minWidth: "5%",mobileWidth: "15%",
          } //console.log((row.doc_type=="html" && row.signed=="yes") || (row.doc_type=="upload" && row.extension=="pdf"),row);
        },
        {
          fieldName: 'doctype',
          //	fieldName: 'fin_doctype_short',
          type: 'static',
          attrs: { width: "10%", outputLang: true }
        },
        {
          fieldName: 'sendgrid_status',
          type: 'staticIcon',
          attrs: { width: "5%", minWidth: "5%",mobileWidth: "10%" , outputLang: true }
        },
        {
          fieldName: 'download',
          type: 'download',
          langName:  'download',
          isSortable: false,
          attrs: { width: "12%", minWidth: "12%",mobileWidth: "10%" , downloadType: "invoice" }
        },
        {
          fieldName: 'email',
          langName: 'email',
          isSortable: false,
          type: 'mail-to',
          attrs: { width: "5%", minWidth: "5%",mobileWidth: "10%" , downloadType: "invoice" },
        },
        {
          fieldName: 'credit_invoice_short',
          langName: 'credit_invoice_short',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "5%", minWidth: "5%",mobileWidth: "10%" ,
            icon: "credit",
            isShownField: "isCreditable",
            modal: "finresource-credit",
            fieldsToUpdate: {},
            rowVariableName: "objectRow",
          }
        },
        {
          fieldName: 'en',
          langName: 'en',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "5%", minWidth: "5%",mobileWidth: "10%" ,
            icon: "translate",
            modal: "finresource-translation",
            fieldsToUpdate: {},
            rowVariableName: "objectRow",
          },
        },
        /*  {
						fieldName: 'credit_invoice',
						isSortable: false,
						type: 'credit',
						attrs: { width: "2%", minWidth: "2%", downloadType: "invoice" },

					},
				 */

      ];
      tableComp.searchableFields = ["id","total","description"];
      tableComp.mobileUpperFields = ["created_at","total","doctype","download"];
      tableComp.sortField = "created_at";
      tableComp.isCheckItems = true;
      tableComp.sortIsAsc = false;
      tableComp.expandOnLoad = true;
    },
    open_proformas: (tableComp: TableGen) => {
      // console.log(tableComp.filterField);
      //tableComp.expanded = { upperFields: ["created_at", "total", "doctype", "download", "email", "sendgrid_status", "credit_invoice_short"]}
      //tableComp.mobileUpperFields = ["created_at", "total", "doctype", "download", "email"]
      tableComp.headerModalOpenBtns = [
        {
          // שליחת המסמכים שנבחרו בדוא"ל"
          // "שמירת המסמכים שנבחרו כקובץ PDF"
          // icon: "emailLarge",
          // hoverLang: "send_documents_to_patient",
          icon: "emailLarge",
          hoverLang: "email_multiple_invoices",
          modal: "gen-table-display",
          funcToRunOnReturn: "sendMultipleFinresources",
          addFilterFieldValueFromTable: true,
          visibleCheckedOnly:true,
          textOnly:true,
          fieldsToUpdate: {
            tableName: "contactways",
            filterField: tableComp.filterField,
            fieldNameInItemToDisplay: "contactway",
            isCheckItems: true,
          },
        },
        {
          icon: "print",
          hoverLang: "print_multiple_invoices",
          funcToRunOnReturn: "printMultipleFinresources",
          visibleCheckedOnly:true,
          textOnly:true,
        },
      ];

      tableComp.collectionFields = [
        {
          fieldName: 'created_at',
          langName: 'date',
          type: 'date',
          validationRules: {},
          attrs: { width: "10%", minWidth: "10%" ,mobileWidth: "15%", staticOnEdit: true}
        },
        {
          fieldName: 'total',
          type: 'static',
          isNumberField: true,
          attrs: { width: "10%", minWidth: "10%" }
        },
        // {
        //   fieldName: 'vat',
        //   type: 'input',
        //   isNumberField: true
        // },
        {
          fieldName: 'description',
          type: 'static',
          attrs: { width: "30%" }
        },
        {
          fieldName: 'doctype',
          //	fieldName: 'fin_doctype_short',
          type: 'static',
          attrs: { width: "10%", outputLang: true }
        },
        {
          fieldName: 'sendgrid_status',
          type: 'staticIcon',
          attrs: { width: "5%", minWidth: "5%",mobileWidth: "10%" , outputLang: true }
        },
        {
          fieldName: 'download',
          type: 'download',
          langName:  'download',
          isSortable: false,
          attrs: { width: "12%", minWidth: "12%",mobileWidth: "10%" , downloadType: "invoice" }
        },
        {
          fieldName: 'email',
          langName: 'email',
          isSortable: false,
          type: 'mail-to',
          attrs: { width: "5%", minWidth: "5%",mobileWidth: "10%" , downloadType: "invoice" },
        },
        {
          fieldName: 'credit_invoice_short',
          langName: 'credit_invoice_short',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "5%", minWidth: "5%",mobileWidth: "10%" ,
            icon: "credit",
            isShownField: "isCreditable",
            modal: "finresource-credit",
            fieldsToUpdate: {},
            rowVariableName: "objectRow",
          }
        },
        {
          fieldName: 'en',
          langName: 'en',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "5%", minWidth: "5%",mobileWidth: "10%" ,
            icon: "translate",
            modal: "finresource-translation",
            fieldsToUpdate: {},
            rowVariableName: "objectRow",
          },
        },
        /*  {
						fieldName: 'credit_invoice',
						isSortable: false,
						type: 'credit',
						attrs: { width: "2%", minWidth: "2%", downloadType: "invoice" },

					},
				 */

      ];
      tableComp.searchableFields = ["id","total","description"];
      tableComp.mobileUpperFields = ["created_at","total","doctype","download"];
      tableComp.sortField = "created_at";
      tableComp.isCheckItems = true;
      tableComp.sortIsAsc = false;
      tableComp.expandOnLoad = true;
    },
    finResource_person_list: (tableComp: TableGen) => {
      // console.log(tableComp.filterField);


      tableComp.collectionFields = [
        {
          fieldName: 'created_at',
          langName: 'date',
          type: 'date',
          validationRules: {},
          attrs: { width: "10%", minWidth: "10%",mobileWidth: "15%" }
        },
        {
          fieldName: 'total',
          type: 'static',
          isNumberField: true,
          attrs: { mobileWidth: "20%" }
        },
        {
          fieldName: 'vat',
          type: 'static',
          isNumberField: true
        },

        {
          fieldName: 'doctype',
          //	fieldName: 'fin_doctype_short',
          type: 'static',
          attrs: { width: "10%", minWidth: "10%",mobileWidth: "30%" , outputLang: true }
        },
        {
          fieldName: 'description',
          type: 'static',
          attrs: { width: "30%", minWidth: "30%",mobileWidth: "20%" }
        },
        {
          fieldName: 'sendgrid_status',
          type: 'staticIcon',
          attrs: { width: "10%", minWidth: "10%",mobileWidth: "10%" ,outputLang: true }
        },
        {
          fieldName: 'download',
          type: 'download',
          langName:  'download',
          isSortable: false,
          attrs: { width: "12%", minWidth: "12%",mobileWidth: "10%", downloadType: "invoice" }
        },
        {
          fieldName: 'email',
          langName: 'email',
          isSortable: false,
          type: 'mail-to',
          attrs: { width: "10%", minWidth: "10%",mobileWidth: "10%", downloadType: "invoice" },
        },
        {
          fieldName: 'credit_invoice_short',
          langName: 'credit_invoice_short',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "10%", minWidth: "10%",mobileWidth: "10%",
            icon: "credit",
            isShownField: "isCreditable",
            modal: "finresource-credit",
            fieldsToUpdate: {},
            rowVariableName: "objectRow",
          }
        },
        {
          fieldName: 'en',
          langName: 'en',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "10%", minWidth: "10%",mobileWidth: "10%",
            icon: "translate",
            modal: "finresource-translation",
            fieldsToUpdate: {},
            rowVariableName: "objectRow",
          },
        }
      ];
      tableComp.searchableFields = ["id"];
      tableComp.mobileUpperFields = ["created_at","vat","total","download"];
      tableComp.sortField = "created_at";
      tableComp.isCheckItems = true;
      tableComp.sortIsAsc = false;
    },
    labtestpatients: (tableComp: TableGen) => {
      tableComp.searchableFields = ["labtest_name"];
      tableComp.mobileUpperFields = ["labtest_id", "date"]
      tableComp.sortField = "start_date";
      tableComp.sortIsAsc = false;
      tableComp.expandOnLoad = true;
      tableComp.collectionFields = [
        {
          fieldName: 'labtest_id',
          langName: 'labtest',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true },
          attrs: {
            width: "250px",
            displayStringFieldName: "labtest_name",
            fuzzySearchableClasses: ["labtests"],
            tableName: "labtests",
            autofocus: 'autofocus',
            showPlusPerm:"administrative"
          },	//avoidPlus:true is optional
          isNumberField: true,
          tabindex: 1,
        },
        //labtest_id:number = 0;
        //patient_id:number = 0;

        {
          fieldName: 'date',
          type: 'date',
          validationRules: { 'not_empty': null },
          attrs: {width: "90px"}
        },
        {
          fieldName: 'result',
          type: 'input',
          tabindex: 2,
          attrs: {width: "150px"}
        },
      ];
      tableComp.headerModalOpenBtns = [
        {
          icon: "list",
          hoverLang: "labtests",
          modal: "gen-table-display",
          fieldsToUpdate: { tableName: "labtests" },
        }
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Labtestpatient();
    },
    labtests: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus', mobileMinWidth: "80%"  },
          tabindex: 2,
        },
        {
          fieldName: 'code',
          type: 'input',
          tabindex: 3,
        },
        {
          fieldName: 'normal_range',
          type: 'input',
          tabindex: 4,
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Labtest();
    },
    refererpatients: (tableComp: TableGen) => {
      /*tableComp.searchableFields = ["referer_name"];*/
      tableComp.sortField = "referer_id";
      tableComp.sortIsAsc = false;
      tableComp.mobileUpperFields = ["referer_id"];
      tableComp.collectionFields = [
        {
          fieldName: 'referer_id',
          langName: 'referer',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true },
          attrs: {
            displayStringFieldName: "referer_name",
            fuzzySearchableClasses: ["referers"],
            tableName: "referers",
            autofocus: 'autofocus',
            showPlusPerm:"administrative",
          },	//avoidPlus:true is optional
          isNumberField: true,
          tabindex: 1,
        },
        //referer_id:number = 0;
        //patient_id:number = 0;


      ];
      tableComp.headerModalOpenBtns = [
        {
          icon: "list",
          hoverLang: "referers",
          modal: "gen-table-display",
          fieldsToUpdate: { tableName: "referers" },
        }
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Refererpatient();
    },
    referers: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },
        {
          fieldName: 'phone',
          type: 'input',
          validationRules: {},
          attrs: {  },
          tabindex: 2,
        },{
          fieldName: 'email',
          type: 'input',
          validationRules: {  },
          attrs: {  },
          tabindex: 3,
        },

      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Referer();
    },
    patienttypes: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },

      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Patienttype();
    },
    affiliated_users: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'static',
        },
        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts,
        },
        {
          fieldName: 'created_at',
          langName: 'date',
          type: 'date',
        },
      ];
    },
    medicpatients: (tableComp: TableGen) => {
      tableComp.searchableFields = ["medic_name"];
      tableComp.sortField = "start_date";
      tableComp.sortIsAsc = false;
      tableComp.expandOnLoad = true;
      tableComp.filterFields = [
        {
          fieldName: "end_date",
          opts: OptionsList.medicpatients_filters,
          lang: "show",
          checkType: "null",
          initialValue: "null",
          canEditOnValue: "null"
        },
      ];

      // tableComp.expanded = { upperFields: ["medic_name", "amount", "frequency", "frequency_unit"] };
      tableComp.expanded = { upperFields: ["medic_name","route_of_admin",  "X", "amount", "frequency","/","frequency_unit"] }
      tableComp.mobileUpperFields = ["medic_name", "amount_output"]
      tableComp.updateRowOverride = "save_medicpatient_ajax";
      tableComp.searchableFields = ["medic_name"];
      /*שם, כמות, תדירות, יחידת זמן (אפשרות שינוי/הוספה) תאריך התחלה, תאריך סיום,
*/
      tableComp.collectionFields = [

        {
          fieldName: 'medic_name',
          type: 'fuzzy-api',
          validationRules: { 'not_empty': true },
          attrs: {
            autofocus: 'autofocus',
            width: "400px", minWidth: "250px",
            displayStringFieldName: "medic_name",
            tableName: "medics",
            idNameConnection: { idField: "medic_id" },
            fuzzySearchObj: {
              apiCall: "fuzzy_online_search_medics",
              outerFilterName: "atc5",
              minLength: 3,
              innerFilterName: "filter_value",
              otherFieldsToUpdate: ["route_of_admin"],
            },
            avoidPlus: true
          },	//avoidPlus:true is optional
          tabindex: 1,
        },
        {
          fieldName: 'route_of_admin',
          type: 'static',
          attrs: { updatedByServer: true, width: "70px", minWidth: "30px",}
        },
        {
          fieldName: 'amount',
          type: 'input',
          validationRules: { 'is_decimal': null },
          attrs: { width: "50px" },
          isNumberField: true,
          tabindex: 2,
        },
        {
          fieldName:"X",
          type:"string",
          attrs: { width: "10px" },
        },
        {
          fieldName: 'frequency',
          type: 'input',
          validationRules: { 'is_integer': null },
          attrs: { width: "50px" },
          isNumberField: true,
          tabindex: 3,
        },
        {
          fieldName:"/",
          type:"string",
          attrs: { width: "10px" },
        },
        {
          fieldName: 'frequency_unit',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { width: "50px" },
          tabindex: 4,
        },
        // {
        //   fieldName: 'amount_output',
        //   type: 'static',
        //   attrs: { nonVisNewRow: true, width: "15%", mobileWidth:"10%", },
        //
        // },
        {
          fieldName: 'start_date',
          type: 'date',
          validationRules: { 'not_empty': null },
          attrs: { width: "15%", mobileWidth:"30%" }
        },
        {
          fieldName: 'end_date', type: 'date',langName:"end_change_date", attrs: { avoidDateReset: true, width: "15%", mobileWidth:"30%" }
        },


        {
          fieldName: 'remarks',
          type: 'input',
          attrs: { width: "45%", mobileWidth:"50%" }
        },
      ];
      tableComp.headerModalOpenBtns = [
        {
          icon: "medic",
          hoverLang: "medics",
          modal: "gen-table-display",
          fieldsToUpdate: { tableName: "medics", isPagi: true },
        },
        // {
        //   icon: "prescription",
        //   hoverLang: "prescription",
        //   modal: "add-prescription",
        //   fieldsToUpdate: { tableName: "medicpatients2", filterField: "patient_id" },
        //   dataVariablesFields: ["patient_id"]
        // },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Medicpatient();
    },
    medicpatients2: (tableComp: TableGen) => {
      tableComp.sortField = "start_date";
      tableComp.searchableFields = ["medic_name"];
      tableComp.mobileUpperFields = ["medic_name", "amount_output"]
      //tableComp.expanded = { upperFields: ["medic_name", "dosage_form", "medic_amount", "amount_output", "route_of_ng serve
      tableComp.collectionFields = [

        {
          fieldName: 'dosage_form',
          type: 'static',
          attrs: {}
        },
        {
          fieldName: 'medic_name',
          langName: 'medic',
          type: 'static',
          attrs: {}
        },
        {
          fieldName: 'medic_amount',
          type: 'static',
          attrs: {}
        },

        {
          fieldName: 'amount_output',
          type: 'static',
          attrs: {}
        },
        {
          fieldName: 'route_of_admin',
          type: 'static',
          attrs: {}
        },
        {
          fieldName: 'total_amount',
          type: 'input',
          attrs: {}
        },

        { fieldName: 'remarks', type: 'input', attrs: {} },
      ];
      tableComp.defaultNewRow = new Medicpatient();
    },
    meetingtypes: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.expanded = { upperFields: ["name", "price", "length", "is_default"] };
      tableComp.mobileUpperFields = ["name", "price", "length"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus', width: "30%", minWidth: "30%" },
          tabindex: 1,
        },
        {
          fieldName: 'length',
          type: 'input',
          validationRules: { not_empty: true, is_integer: null, is_positive: true },
          attrs: {  width: "10%", minWidth: "10%" },
          tabindex: 2,
          isNumberField: true
        },
        {
          fieldName: 'price',
          type: 'input',
          validationRules: { 'is_decimal_or_zero' : null },
          attrs: {  width: "10%", minWidth: "10%" },
          tabindex: 3,
          isNumberField: true
        },
        {
          fieldName: 'color',
          langName: 'border_color',
          isSortable: false,
          type: 'color',
          attrs: {  width: "10%", minWidth: "10%" },
          tabindex: 3,
        },
        {
          fieldName: 'is_default',
          type: 'yes-no',
          unique_for_value:true,
          validationRules:{},
          isHideable: true,

        },
        // {
        //   fieldName: 'countable',
        //   type: 'button-group',
        //   validationRules: {  },
        //   optionsCollection: OptionsList.yes_no_opts
        // },
        //first_time_meeting_request return_meeting_request

        {
          fieldName: 'first_time_meeting_request',
          type: 'yes-no',
          isHideable: true,

        }, {
          fieldName: 'return_meeting_request',
          type: 'yes-no',
          isHideable: true,

        },
        {
          fieldName: 'meeting_text',
          type: 'textarea',
          attrs: {}
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Meetingtype();

    },
    nondrugpatients: (tableComp: TableGen) => {
      tableComp.searchableFields = ["nondrug_name"];
      tableComp.mobileUpperFields = ["nondrug_name", "start_date", "end_date"];
      tableComp.sortField = "start_date";
      tableComp.sortIsAsc = false;
      tableComp.expandOnLoad = true;
      tableComp.expanded = { upperFields: ["nondrug_name", "start_date", "end_date"] };
      tableComp.filterFields = [
        {
          fieldName: "end_date",
          opts: OptionsList.medicpatients_filters,
          lang: "show",
          checkType: "null",
          defaultValue: "null",
          canEditOnValue: "null"
        },
      ];
      tableComp.collectionFields = [
        {
          fieldName: 'nondrug_name',
          langName: 'nondrug',
          type: 'fuzzy',
          validationRules: { 'not_empty': true },
          attrs: {
            displayStringFieldName: "nondrug_name",
            fuzzySearchableClasses: ["nondrugs"],
            tableName: "nondrugs",
            staticOnEdit: true,
            idNameConnection: { idField: "nondrug_id", },
            width: "60%", minWidth: "60%",
            autofocus: "autofocus",
            showPlusPerm:"administrative",
          },	//avoidPlus:true is optional
          tabindex: 1,
          isNumberField: true

        },
        {
          fieldName: 'start_date',
          type: 'date',
          validationRules: { 'not_empty': null },
          attrs: {}
        },
        {
          fieldName: 'end_date',
          type: 'date',
          attrs: { avoidDateReset: true }
        },
        {
          fieldName: 'remarks',
          type: 'input',
          tabindex: 2,
        },
      ];

      tableComp.headerModalOpenBtns = [
        {
          icon: "list",
          hoverLang: "nondrugs",
          modal: "gen-table-display",
          fieldsToUpdate: { tableName: "nondrugs" },
        }
      ];

      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Nondrugpatient();
    },
    nondrugs: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },

      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Nondrug();
    },
    paymentapps: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },
        {
          fieldName: 'more',
          type: 'input',
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },

      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Paymentapp();
    },
    patientfields: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs:  { autofocus: 'autofocus', width: "30%", minWidth: "30%", mobileWidth: "50%", mobileMinWidth: "50%" },
          tabindex: 1,
        },
        // {
        //   fieldName: 'show',
        //   langName: 'show-item',
        //   type: 'button-group',
        //   optionsCollection: OptionsList.yes_no_opts,
        //   attrs:  {  width: "30%", minWidth: "30%"},
        //   isHideable: true,
        // },
        {
          fieldName: 'show',
          langName: 'show-item',
          type: 'yes-no',
          isHideable: true,

        },
        {
          fieldName: 'order',
          langName: 'order_of_appearance',
          type: 'input',
          validationRules: { 'is_integer': null ,'not_zero': null },
          attrs:  {  width: "20%", minWidth: "20%"},
          isNumberField: true
        },
      ];
      tableComp.mobileUpperFields = ["name","show"];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Patientfield();
    },
    patients: (tableComp: TableGen) => {
      tableComp.avoidNewRow=true;
      tableComp.hasTagsFilter=true;
      tableComp.hasAssocFilter=true;
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.duplicateWarningFields = ["name"];
      // tableComp.expanded = { upperFields: ["id", "name", "name_en", "id_number", "contactway", "year_of_birth", "gender", "marital_status", "number_of_kids", 'automatic_proforma'] };//
      tableComp.expanded = { upperFields: ["id", "name","show-item","invoice","assoc","age"] };//
      tableComp.mobileUpperFields = ["name", "contactway","show-item","invoice"];
      tableComp.openNewRowOnInit = true;
      tableComp.filterFields = [
        { fieldName: "active", opts: OptionsList.patient_active_opts, defaultValue: "yes" },
        { fieldName: "status", opts: OptionsList.patient_inquirer_opts, defaultValue: "-1" }
        // ,{fieldName:"is_group",opts:OptionsList.active_opts,lang:"group",defaultValue:"no"},
      ];
      tableComp.defaultNewRow = new Patient();
      tableComp.filterFieldApiObjectPreset = { "is_group": "no" };  //,"status":"patient"
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'number',
          type: 'static',
          attrs: { width: "2%", minWidth: "2%" },
          isNumberField: true,
        },
        {
          fieldName: 'name',
          langName: 'patient_name',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus', width: "20%", minWidth: "20%", mobileWidth: "80%", mobileMinWidth: "80%" },
          tabindex: 1,
        },
        {
          fieldName:"show-item",
          type:"show-item",
          isSortable: false,
          attrs:{ width: "5%", minWidth: "5%",
            showItemBtnUrl:"/patients/",
          }

        },
        {
          fieldName: 'invoice',
          langName: 'invoice_short',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "5%", minWidth: "5%",
            icon: "invoice-sm",
            isImg: true,
            modal: "add-invoice",
            fieldsToUpdate: { resource: "patient" },
            rowVariableName: "inputRow"
          },
        },
        {
          fieldName: 'id_number',
          type: 'input',
          attrs: { width: "15%", minWidth: "7%" , mobileWidth: "30%"},
          tabindex: 2,
          isHideable: true,
        },
        {
          fieldName: 'name_en',
          type: 'input',
          attrs: { width: "15%", minWidth: "15%", mobileWidth: "30%" },
          tabindex: 3,
          isHideable: true,
        },

        {
          fieldName: 'year_of_birth',
          type: 'input',
          attrs: { width: "8%", minWidth: "10%", mobileWidth: "30%" },
          tabindex: 4,
          isHideable: true,
        },
        {
          fieldName: 'age',
          type: 'static',
          attrs: { width: "8%", minWidth: "10%", mobileWidth: "30%" },
          tabindex: -1,
          isHideable: true,
          isNumberField: true,
        },
        {
          fieldName: 'date_of_birth',
          type: 'date',
          attrs: { avoidDateReset: true,width: "8%", minWidth: "10%", mobileWidth: "30%" },

          tabindex: 5,

          isHideable: true,
        },
        {
          fieldName: 'gender',
          type: 'button-group',
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "80%" },
          optionsCollection: OptionsList.gender_opts,
          isHideable: true,

        },
        {
          fieldName: 'marital_status',
          type: 'select',
          attrs: { width: "8%", minWidth: "10%", mobileWidth: "30%" },
          optionsCollection: OptionsList.marital_status_opts,
          isHideable: true,
        },  {
          fieldName: 'parents_marital_status',
          type: 'select',
          attrs: { width: "8%", minWidth: "10%", mobileWidth: "30%" },
          optionsCollection: OptionsList.parents_marital_status_opts,
          isHideable: true,
        },
        {
          fieldName: 'number_of_kids',
          type: 'input',
          validationRules: { 'is_integer': null },
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "30%" },
          tabindex: 5,
          isNumberField: true,
          isHideable: true,
        },
        {
          fieldName: 'contactway',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "10%", minWidth: "10%", mobileWidth: "30%",
            icon: "mobile",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "contactways", filterField: "patient_id" },
            rowVariableName: "objectRow",
            isInputInNewRow: true,

          }
        },

        {
          fieldName: 'patienttype_id',
          langName: 'patienttype',
          type: 'fuzzy',
          validationRules: { is_legit_fuzzy_value_or_empty: true },
          attrs: {
            displayStringFieldName: "patienttype_name",
            fuzzySearchableClasses: ["patienttypes"],
            tableName: "patienttypes",
            showPlusPerm:"administrative",
          },
          tabindex: 6,	//avoidPlus:true is optional
          isNumberField: true,
          isHideable: true,
        },
        {
          fieldName: 'payor_id',
          langName: 'payor',
          type: 'fuzzy',
          validationRules: { is_legit_fuzzy_value_or_empty: true },
          attrs: {
            displayStringFieldName: "payor_name",
            fuzzySearchableClasses: ["payors"],
            tableName: "payors",
            staticOnPerms: ["finances_income"],
            showPlusPerm:"administrative",
          },//avoidPlus:true is optional
          isNumberField: true,
          isHideable: true,
          tabindex: 7,
        },

        /*	{
					fieldName: 'account_in_hashav',
					type: 'input',
					validationRules: { 'is_integer': null },
					isNumberField: true
				},*/


        {
          fieldName: 'notifiy',
          type: 'yes-no',
          // type: 'button-group',
          // optionsCollection: OptionsList.yes_no_opts,
          // attrs: { width: "10%", minWidth: "10%", mobileWidth: "30%" },
           isHideable: true,
        },
        /*	{
					fieldName: 'notification_time',
					type: 'button-group',
					optionsCollection: OptionsList.notifiyOpts
				},*/
        {
          fieldName: 'notification_default',
          type: 'button-group',
          optionsCollection: OptionsList.notification_defaultOpts,
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "40%" },
          isHideable: true,

        },
        {
          fieldName: 'is_withholding_tax',
          langName: 'withholding_tax',
          type: 'yes-no',
          // type: 'button-group',
          // optionsCollection: OptionsList.yes_no_opts,
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "30%" },
          isHideable: true,
        },
        {
          fieldName: 'withholding_tax_percent',
          type: 'input',
          validationRules: { 'is_decimal_or_zero': null },
          attrs: {width: "10%", minWidth: "10%", mobileWidth: "30%"  },
          isNumberField: true
        },
        /*	{
						fieldName: 'is_group',
						langName: 'group',
						type: 'button-group',
						optionsCollection: OptionsList.yes_no_opts
					},*/
        {
          fieldName: 'invoice_by_mail',
          type: 'yes-no',
          // type: 'button-group',
          // optionsCollection: OptionsList.yes_no_opts,
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "40%" },
          isHideable: true,
        },
        {
          fieldName: 'automatic_proforma',
          type: 'yes-no',
          // type: 'button-group',
          // optionsCollection: OptionsList.yes_no_opts,
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "40%" },
          isHideable: true,
        },
        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.patient_active_insert_opts,
          isHideable: true,
        },
        // {
        //   fieldName: 'status',
        //   type: 'button-group',
        //   optionsCollection: OptionsList.patient_or_inquirer_opts,
        //   isHideable: true,
        // },
        {
          fieldName: 'assoc',
          isSortable: false,
          type: 'autocomplete-multi',
          attrs: {width: "20%", minWidth: "20%", mobileWidth: "40%"},
          isHideable: true,
          tabindex: 8,
        },
        {
          fieldName: "tags",
          type: "tags-ac",
          attrs: {
            tagItemType: "patient",
            avoidPlus: false,
            mobileWidth: "80%"
          }
        },
        {
          fieldName: 'remarks',
          type: 'wysiwyg',
          validationRules: {  },
          attrs: {height:"30px", width: "40%", minWidth: "40%", mobileWidth: "50%", mobileMinWidth: "50%" },

        },

      ];
      tableComp.headerModalOpenBtns = [
        {
          icon: "sms",
          hoverLang: "patients_sms",
          modal: "gen-table-display",
          fieldsToUpdate: { tableName: "patients_sms",modalWidth:"modal-default-width" },
        },
        {
          icon: "list",
          hoverLang: "patients",
          modal: "patient-field-tbl",
          fieldsToUpdate: { tableName: "" },
        },
        {
          icon: "insert",
          hoverLang: "add_patient",
          modal: "insert-patient-form",
          fieldsToUpdate: { },
          // funcToRunOnReturn:"refreshItems",

        },


      ];
    },
    partners: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];
      tableComp.hasTagsFilter=true;
      tableComp.tagFilterType="partner";
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.openNewRowOnInit = false;
      tableComp.headerModalOpenBtns = [
        {
          icon: "insert",
          hoverLang: "invite-partner",
          modal: "invite-partner",
          fieldsToUpdate: { },
          // funcToRunOnReturn:"refreshItems",
        }

      ];
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          langName: 'name',
          type: 'static',
          attrs: { width: "20%", minWidth: "20%" },
        },
        {
          fieldName: "tags",
          type: "tags-ac",
          attrs: {
            tagItemType: "partner",
            avoidPlus: false,
            width: "70%",mobileWidth: "80%"
          }
        },

      ];
    },
    payorpatients: (tableComp: TableGen) => {
      //tableComp.hasTagsFilter=true;
      //tableComp.hasAssocFilter=true;
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.expanded = { upperFields: ["id", "name","show-item","invoice","assoc"] };//
      tableComp.mobileUpperFields = ["name", "contactway","show-item"];
      tableComp.filterFields = [
       { fieldName: "active", opts: OptionsList.patient_active_opts, defaultValue: "yes" },
        //{ fieldName: "status", opts: OptionsList.patient_inquirer_opts, defaultValue: "-1" }
        // ,{fieldName:"is_group",opts:OptionsList.active_opts,lang:"group",defaultValue:"no"},
     ];
      tableComp.defaultNewRow = new Patient();
      tableComp.filterFieldApiObjectPreset = { "is_group": "no" };  //,"status":"patient"
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'number',
          type: 'static',
          attrs: { width: "2%", minWidth: "2%" },
          isNumberField: true,
        },
        {
          fieldName: 'name',
          langName: 'patient_name',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus', width: "20%", minWidth: "20%", mobileWidth: "80%", mobileMinWidth: "80%" },
          tabindex: 1,
        },
        {
          fieldName:"show-item",
          type:"show-item",
          isSortable: false,
          attrs:{ width: "5%", minWidth: "5%",
            showItemBtnUrl:"/patients/",
          }

        },
        {
          fieldName: 'invoice',
          langName: 'invoice_short',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "5%", minWidth: "5%",
            icon: "invoice-sm",
            isImg: true,
            modal: "add-invoice",
            fieldsToUpdate: { resource: "patient" },
            rowVariableName: "inputRow"
          },
        },
        {
          fieldName: 'id_number',
          type: 'input',
          attrs: { width: "15%", minWidth: "7%" , mobileWidth: "30%"},
          tabindex: 2,
          isHideable: true,
        },
        {
          fieldName: 'name_en',
          type: 'input',
          attrs: { width: "15%", minWidth: "15%", mobileWidth: "30%" },
          tabindex: 3,
          isHideable: true,
        },

        {
          fieldName: 'year_of_birth',
          type: 'input',
          attrs: { width: "8%", minWidth: "10%", mobileWidth: "30%" },
          tabindex: 4,
          isHideable: true,
        },
        {
          fieldName: 'date_of_birth',
          type: 'date',
          attrs: { avoidDateReset: true,width: "8%", minWidth: "10%", mobileWidth: "30%" },

          tabindex: 5,

          isHideable: true,
        },
        {
          fieldName: 'gender',
          type: 'button-group',
          attrs: { mobileWidth: "80%" },  //width: "10%", minWidth: "10%",
          optionsCollection: OptionsList.gender_opts,
          isHideable: true,

        },
        {
          fieldName: 'marital_status',
          type: 'select',
          attrs: { width: "8%", minWidth: "10%", mobileWidth: "30%" },
          optionsCollection: OptionsList.marital_status_opts,
          isHideable: true,
        },
        {
          fieldName: 'number_of_kids',
          type: 'input',
          validationRules: { 'is_integer': null },
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "30%" },
          tabindex: 5,
          isNumberField: true,
          isHideable: true,
        },
        {
          fieldName: 'contactway',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "10%", minWidth: "10%", mobileWidth: "30%",
            icon: "mobile",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "contactways", filterField: "patient_id" },
            rowVariableName: "objectRow",
            isInputInNewRow: true,

          }
        },

        {
          fieldName: 'patienttype_id',
          langName: 'patienttype',
          type: 'fuzzy',
          validationRules: { is_legit_fuzzy_value_or_empty: true },
          attrs: {
            displayStringFieldName: "patienttype_name",
            fuzzySearchableClasses: ["patienttypes"],
            tableName: "patienttypes",
            showPlusPerm:"administrative",
          },
          tabindex: 6,	//avoidPlus:true is optional
          isNumberField: true,
          isHideable: true,
        },
        {
          fieldName: 'payor_id',
          langName: 'payor',
          type: 'fuzzy',
          validationRules: { is_legit_fuzzy_value_or_empty: true },
          attrs: {
            displayStringFieldName: "payor_name",
            fuzzySearchableClasses: ["payors"],
            tableName: "payors",
            staticOnPerms: ["finances_income"],
            showPlusPerm:"administrative",
          },//avoidPlus:true is optional
          isNumberField: true,
          isHideable: true,
          tabindex: 7,
        },

        /*	{
					fieldName: 'account_in_hashav',
					type: 'input',
					validationRules: { 'is_integer': null },
					isNumberField: true
				},*/


        {
          fieldName: 'notifiy',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts,
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "30%" },
          isHideable: true,
        },
        /*	{
					fieldName: 'notification_time',
					type: 'button-group',
					optionsCollection: OptionsList.notifiyOpts
				},*/
        {
          fieldName: 'notification_default',
          type: 'button-group',
          optionsCollection: OptionsList.notification_defaultOpts,
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "40%" },
          isHideable: true,

        },
        {
          fieldName: 'is_withholding_tax',
          langName: 'withholding_tax',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts,
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "30%" },
          isHideable: true,
        },
        {
          fieldName: 'withholding_tax_percent',
          type: 'input',
          validationRules: { 'is_decimal_or_zero': null },
          attrs: {width: "10%", minWidth: "10%", mobileWidth: "30%"  },
          isNumberField: true
        },
        /*	{
						fieldName: 'is_group',
						langName: 'group',
						type: 'button-group',
						optionsCollection: OptionsList.yes_no_opts
					},*/
        {
          fieldName: 'invoice_by_mail',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts,
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "40%" },
          isHideable: true,
        },
        {
          fieldName: 'automatic_proforma',
          type: 'button-group',
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "40%" },
          optionsCollection: OptionsList.yes_no_opts,
          isHideable: true,
        },
        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.patient_active_insert_opts,
          isHideable: true,
        },
        {
          fieldName: 'status',
          type: 'button-group',
          optionsCollection: OptionsList.patient_or_inquirer_opts,
          isHideable: true,
        },
        {
          fieldName: 'assoc',
          isSortable: false,
          type: 'autocomplete-multi',
          attrs: {width: "20%", minWidth: "20%", mobileWidth: "40%"},
          isHideable: true,
          tabindex: 8,
        },
        {
          fieldName: "tags",
          type: "tags-ac",
          attrs: {
            tagItemType: "patient",
            avoidPlus: false,
            mobileWidth: "80%"
          }
        },
        {
          fieldName: 'remarks',
          type: 'wysiwyg',
          validationRules: {  },
          attrs: {height:"30px", width: "40%", minWidth: "40%", mobileWidth: "50%", mobileMinWidth: "50%" },

        },

      ];

    },
    inquirers: (tableComp: TableGen) => {
      tableComp.avoidNewRow=true;
      tableComp.hasAssocFilter=true;
      tableComp.hasTagsFilter=true;
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "updated_at";
      tableComp.duplicateWarningFields = ["name"];
      tableComp.expanded = { upperFields: ["id","updated_at","irrelevant", "name","show-item", "inquiry_status" ,"contactway","assoc" ,"remarks" ,"task" ] };
      tableComp.sortIsAsc = false;
      tableComp.mobileUpperFields = ["name", "contactway"];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Patient();
      tableComp.filterFieldApiObjectPreset = { "is_group": "no","status":"inquirer" };
      tableComp.filterFields = [
        { fieldName: "active", opts: OptionsList.patient_active_opts, defaultValue: "yes" },
      ];
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'number',
          type: 'static',
          attrs: { width: "5%", minWidth: "5%",mobileWidth: "7%", },
          isNumberField: true,
        },

        {
          fieldName: 'created_at',
          langName: 'date',
          type: 'date',
          validationRules: { width: "15%", minWidth: "15%",mobileWidth: "15%" },
          attrs: {staticOnEdit: true}
        },
        {
          fieldName: 'updated_at',
          langName: 'updated_at',
          type: 'date',
          validationRules: { width: "15%", minWidth: "15%",mobileWidth: "15%" },
          attrs: {staticOnEdit: true}
        },

        {
          fieldName: 'name',
          langName: 'patient_name',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus', width: "20%", minWidth: "20%", mobileWidth: "50%", mobileMinWidth: "50%" },
          tabindex: 1,
        },
        {
          fieldName:"show-item",
          type:"show-item",
          isSortable: false,
          attrs:{
            showItemBtnUrl:"/patients/",width: "5%", minWidth: "5%", mobileWidth: "10%",
          }

        },
        {
          fieldName: 'inquiry_status',
          langName: 'inquiry_status',
          isSortable: true,
          type: 'inquiry-status',
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "30%"},
        },
        {
          fieldName: 'gender',
          type: 'button-group',
          attrs: { width: "10%", minWidth: "10%", mobileWidth: "80%" },
          optionsCollection: OptionsList.gender_opts,
          isHideable: true,
        },
        {
          fieldName: 'marital_status',
          type: 'button-group',
          attrs: { width: "15%", minWidth: "15%", mobileWidth: "95%" },
          optionsCollection: OptionsList.marital_status_opts,
          isHideable: true,
        },
        {
          fieldName: 'parents_marital_status',
          type: 'button-group',
          attrs: { width: "15%", minWidth: "15%", mobileWidth: "95%" },
          optionsCollection: OptionsList.parents_marital_status_opts,
          isHideable: true,
        },
        {
          fieldName: 'contactway',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "5%", minWidth: "5%", mobileWidth: "30%",
            icon: "mobile",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "contactways", filterField: "patient_id" },
            rowVariableName: "objectRow",
            isInputInNewRow: true,

          }
        },
        {
          fieldName: 'payor_id',
          langName: 'payor',
          type: 'fuzzy',
          validationRules: { is_legit_fuzzy_value_or_empty: true },
          attrs: {
            displayStringFieldName: "payor_name",
            fuzzySearchableClasses: ["payors"],
            tableName: "payors",
            staticOnPerms: ["finances_income"],
            showPlusPerm:"administrative",
          },//avoidPlus:true is optional
          isNumberField: true,
          isHideable: true,
        },
        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.patient_active_insert_opts,
          isHideable: true,
          attrs: {width: "15%", minWidth: "15%", mobileWidth: "20%",},
        },
        {
          fieldName: 'task',
          langName: 'task',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "5%", minWidth: "5%", mobileWidth: "10%",
            icon: "tasks",
            modal: "task",
            rowVariableName: "patientRow",

          },
        },

        {
          fieldName: 'assoc',
          langName: 'assoc_inquiry',
          isSortable: false,
          type: 'autocomplete-multi',
          attrs: {width: "15%", minWidth: "15%", mobileWidth: "30%",},
          isHideable: true,
        },
        {
          fieldName: 'remarks',
          type: 'wysiwyg',
          validationRules: {  },
          attrs: {  width: "10%", minWidth: "10%", mobileWidth: "50%", mobileMinWidth: "50%" },

        },
        {
          fieldName: "tags",
          type: "tags-ac",
          attrs: {
            tagItemType: "patient",
            avoidPlus: false,
            mobileWidth: "80%"
          }
        },
        {
          fieldName: 'irrelevant',
          type: 'action-button',
          langName: "irrelevant",
          attrs: {
            ngIfFunc:(curRow:any)=>{return curRow.inquiry_status_id!=-6;},
            clickFunc:"create_task_motion_by_patient_id",
            newValue:-6,
            mobileWidth:"20%",
            icon:"irrelevant",
          },
        },

        // {
        //   fieldName: 'remarks',
        //   type: 'input',
        //   validationRules: {  },
        //   attrs: { autofocus: 'autofocus', width: "80%", minWidth: "80%", mobileWidth: "80%", mobileMinWidth: "80%" },
        //   tabindex: 1,
        // },
        // {
        //   fieldName: 'tasks',
        //   isSortable: false,
        //   type: 'button',
        //   attrs: {
        //     width: "10%", minWidth: "10%", mobileWidth: "30%",
        //     icon: "task",
        //     modal: "gen-table-display",
        //     fieldsToUpdate: { tableName: "tasks", filterField: "patient_id" },
        //     rowVariableName: "objectRow",
        //     isInputInNewRow: true,
        //   }
        // },

      ];
      tableComp.headerModalOpenBtns=[
        {
          icon: "insert",
          hoverLang: "add_patient",
          modal: "insert-patient-form",
          fieldsToUpdate: { },
          // funcToRunOnReturn:"refreshItems",
        }
      ]
    },
    patients_sms: (tableComp: TableGen) => {
      tableComp.avoidNewRow=true;
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.filterFields = [
        { fieldName: "active", opts: OptionsList.active_opts, defaultValue: "yes" }
        // ,{fieldName:"is_group",opts:OptionsList.active_opts,lang:"group",defaultValue:"no"},
      ];
      //tableComp.defaultNewRow = new Patient();
      tableComp.filterFieldApiObjectPreset = { "is_group": "no" };
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'number',
          type: 'static',
          attrs: { width: "30" },
          isNumberField: true,
        },
        {
          fieldName: 'name',
          type: 'static',
          attrs: {  width: "20%", minWidth: "20%", mobileWidth: "80%", mobileMinWidth: "80%" },
          tabindex: 1,
        },

        {
          fieldName: 'notifiy',
          type: 'yes-no',

        },

        {
          fieldName: 'notification_default',
          type: 'button-group',
          optionsCollection: OptionsList.notification_defaultOpts
        },
        {
          fieldName: 'automatic_proforma',
          type: 'yes-no',
          // type: 'button-group',
          // optionsCollection: OptionsList.yes_no_opts,
          attrs: { width: "100", },
          isHideable: true,
        },

      ];

    },
    groups: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.expanded = { upperFields: ["id","name","show-item","grouppatient","patienttype_id","payor_id","assoc",] ,lgAddedUpperFields:["charge_mode"]};
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.duplicateWarningFields = ["name"];
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'number',
          type: 'static',
          attrs: { width: "5%", minWidth: "5%", },
          isNumberField: true,
        },
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },
        {
          fieldName:"show-item",
          type:"show-item",
          isSortable: false,
          attrs:{
            showItemBtnUrl:"/patients/",
          }

        },
        {
          fieldName: 'grouppatient',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "group",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "grouppatients", filterField: "group_id" },
            rowVariableName: "objectRow"
          },	//fieldsToUpdate:{fieldName:val....} not required
          //rowVariableName:string (no required) is the name of the variable inside the modal that will RECEIVE the row that I clicked
        },
        {
          fieldName: 'patienttype_id',
          langName: 'patienttype',
          type: 'fuzzy',
          validationRules: { is_legit_fuzzy_value_or_empty: true },
          attrs: {
            displayStringFieldName: "patienttype_name",
            fuzzySearchableClasses: ["patienttypes"],
            tableName: "patienttypes",
            showPlusPerm:"administrative",
          },
          tabindex: 2,	//avoidPlus:true is optional
          isNumberField: true
        },
        {
          fieldName: 'payor_id',
          langName: 'payor',
          type: 'fuzzy',
          validationRules: { is_legit_fuzzy_value_or_empty: true },
          attrs: {
            displayStringFieldName: "payor_name",
            fuzzySearchableClasses: ["payors"],
            tableName: "payors",
            showPlusPerm:"administrative",
          },	//avoidPlus:true is optional
          isNumberField: true
        },

        // {
        //   fieldName: 'patientprice',
        //   langName: 'price_patient',
        //   type: 'input',
        //   validationRules: { 'is_integer': null },
        //   isNumberField: true
        // },
        {
          fieldName: 'charge_mode',
          type: 'button-group',
          optionsCollection: OptionsList.chargeModeOpts
        },
      {
          fieldName: 'payment_mode',
          type: 'button-group',
          optionsCollection: OptionsList.paymentModeOpts
        }, {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'assoc',
          isSortable: false,
          type: 'autocomplete-multi',
          attrs: {},
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.filterFieldApiObjectPreset = { "is_group": "yes" };
      tableComp.defaultNewRow = new Patient();
    },
    payors: (tableComp: TableGen) => {
      tableComp.perms = ["finances_income"];
      tableComp.searchableFields = ["name"];
      tableComp.expanded = { upperFields: ["id", "name","show-item", "payorprice", "invoice", "patients", "contactway", "is_withholding_tax","withholding_tax_percent"] };
      tableComp.sortField = "name";
      tableComp.mobileUpperFields = ["name","invoice","show-item",  "payorprice","contactway","patients",];
      tableComp.duplicateWarningFields = ["name"];
      tableComp.expandOnLoad = true;
      tableComp.headerModalOpenBtns = [
        {
          icon: "payorprices",
          hoverLang: "payorprices",
          modal: "reports",
          fieldsToUpdate: { report_name: "payorprices_report" },
        },
      ];
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'number',
          type: 'static',
          attrs: { width: "5%", minWidth: "5%", },
          isNumberField: true,
        },
        {
          fieldName: 'name',
          type: 'input',
          attrs: { autofocus: 'autofocus', width: "30%", minWidth: "30%" },
          validationRules: { 'not_empty': null },
          tabindex: 2,
        },
        {
          fieldName:"show-item",
          type:"show-item",
          isSortable: false,
          attrs:{
            showItemBtnUrl:"/payors/",
          }

        },
        {
          fieldName: 'address',
          attrs: {  width: "20%", minWidth: "20%" },
          type: 'input',
          tabindex: 3
        },


        {
          fieldName: 'invoice',
          langName: 'invoice_short',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "invoice-sm",
            isImg: true,
            modal: "add-invoice",
            fieldsToUpdate: { resource: "payor" },
            rowVariableName: "inputRow"
          },
        },
        {
          fieldName: 'payorprice',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "payorprices",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "payorprices", filterField: "payor_id" },
            rowVariableName: "objectRow"

          },	//fieldsToUpdate:{fieldName:val....} not required
          //rowVariableName:string (no required) is the name of the variable inside the modal that will RECEIVE the row that I clicked
        },
        {
          fieldName: 'contactway',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "mobile", width: "5%", minWidth: "5%",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "contactways", filterField: "payor_id" },
            rowVariableName: "objectRow"
          },

        },
        {
          fieldName: 'patients',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "patient",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "patients", filterField: "payor_id", },
            rowVariableName: "objectRow",

          },

        },

        {
          fieldName: 'is_withholding_tax',
          langName: 'withholding_tax',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'withholding_tax_percent',
          type: 'input',
          validationRules: { is_decimal_or_zero: null,smaller_than:48 },
          attrs: {width: "10%", minWidth: "10%", mobileWidth: "30%"  },
          isNumberField: true
        },
        // {
        //   fieldName: 'account_in_hashav',
        //   type: 'input',
        //   validationRules: {  },
        //   isNumberField: true
        // },
        {
          fieldName: 'bn_number',
          type: 'input',
          validationRules: {   },
          isNumberField: true
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Payor();
    },
    payorprices: (tableComp: TableGen) => {
      tableComp.searchableFields = [];
      tableComp.expanded = { upperFields: ["meetingtype_id","meetingtype_price","price_payor","unit","price_for_patient",] ,lgAddedUpperFields:["price_cancellation","unit_cancellation","active"]};
      tableComp.sortField = "meetingtype_name";
      tableComp.mobileUpperFields = ["meetingtype_id"];
      tableComp.collectionFields = [

        /*{
					fieldName: 'price_for_meeting',
					type: 'input',
					validationRules: {'is_decimal': null},
					attrs: {autofocus: 'autofocus'},
					tabindex: 1,
					isNumberField: true

				},*/
        {
          fieldName: 'meetingtype_id',
          langName: 'meetingtype',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true },
          attrs: {
            displayStringFieldName: "meetingtype_name",
            fuzzySearchableClasses: ["meetingtypes"],
            tableName: "meetingtypes",
            showPlusPerm:"administrative",
            width: "200px",
            minWidth: "100px",
          },	//avoidPlus:true is optional
          tabindex: 2,
          isNumberField: true
        },
        {
          fieldName: 'meetingtype_price',
          type: 'otherSiteDataTableValue',
          attrs: {
            otherTableName:"meetingtypes",
            fieldNameinThisTable:"meetingtype_id",
            fieldNameInOtherTable:"price",
            width: "100px",

          }
        },
        {
          fieldName: 'price_payor',
          type: 'input',
          validationRules: { 'is_decimal_or_zero': null },
          tabindex: 3,
          isNumberField: true,
          attrs: {
            width: "100px",
          }
        },
        {
          fieldName: 'unit',
          type: 'button-group',
          optionsCollection: OptionsList.unit_opts,
          attrs: {
            width: "70px",

          }
        },
        {
          fieldName: 'price_for_patient',
          type: 'otherSiteDataTableValueMinusField',
          attrs: {
            otherTableName:"meetingtypes",
            fieldNameinThisTable:"meetingtype_id",
            fieldNameInOtherTable:"price",
            minusField:"price_payor",
            width: "70px",
          }
        },
        {
          fieldName: 'price_cancellation',
          type: 'input',
          validationRules: { 'is_decimal_or_zero': null },
          attrs: {
            width: "100px",
             },
          tabindex: -1,
          isNumberField: true
        },
        {
          fieldName: 'unit_cancellation',
          type: 'button-group',
          optionsCollection: OptionsList.unit_opts,
          attrs: {
            width: "100px",

          }
        },
        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts,
          attrs: {
            width: "70px",
          }
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Payorprice();
    },
    tarifs: (tableComp: TableGen) => {
      tableComp.searchableFields = [];
      tableComp.mobileUpperFields = ["name", "price_for_meeting"];
      tableComp.sortField = "meetingtype_name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          attrs: { autofocus: 'autofocus', width: "30%", minWidth: "30%" },
          validationRules: { 'not_empty': null },
          tabindex: 1,
        },
        {
          fieldName: 'price_for_meeting',
          type: 'input',
          validationRules: { 'is_decimal_or_zero': null },
          attrs: {},
          tabindex: 2,
          isNumberField: true

        },
        {
          fieldName: 'unit',
          type: 'button-group',
          optionsCollection: OptionsList.unit_opts
        },
        {
          fieldName: 'assoc_payment',
          langName:'payment',
          type: 'button-group',
          optionsCollection: OptionsList.assoc_payment_opts
        },
        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Tarif();
    },
    sites: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { width: "30%", minWidth: "20%" },
          tabindex: 1,
        },
      ];

      tableComp.openNewRowOnInit = true;
      tableComp.mobileUpperFields = ["name"];
      tableComp.defaultNewRow = new Site();

    },
    inquiry_statuses: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { width: "30%", minWidth: "20%" },
          tabindex: 1,
        },
      ];

      tableComp.openNewRowOnInit = true;
      tableComp.mobileUpperFields = ["name"];
      tableComp.defaultNewRow = new Inquiry_status();

    },


    suppliers: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.duplicateWarningFields = ["name"];
      tableComp.mobileUpperFields = ["name","number"];
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: {  minWidth: "15%", mobileMinWidth: "60%" },
          tabindex: 1,
        },
        {
          fieldName: 'number',
          langName: 'hp',
          type: 'input',
          attrs: {  minWidth: "8%", mobileMinWidth: "40%" },

        },
        { fieldName: 'address', type: 'input', attrs: {}, tabindex: 2,},
        { fieldName: 'phone', type: 'input', attrs: {},tabindex: 3 },
        { fieldName: 'email', type: 'input', attrs: {} ,tabindex: 4},
        {
          fieldName: 'expensetype_id',
          langName: 'expensetype',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value_or_empty': true },
          attrs: {
            displayStringFieldName: "expensetype_name",
            fuzzySearchableClasses: ["expensetypes"],
            tableName: "expensetypes",
            showPlusPerm:"administrative",
          },	//avoidPlus:true is optional
          tabindex: 5,
          isNumberField: true
        },
        {
          fieldName: 'account_in_hashav',
          type: 'input',
          validationRules: { 'is_integer': null },
          isNumberField: true,
          tabindex: 5,
        },

        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts,
        },

      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Supplier();
    },
    //********************************************************

    taxes: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];
      tableComp.mobileUpperFields = ["name","percent"];
      tableComp.updateRowOverride = "update_tax_ajax";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'static',
          attrs: { outputLang: true }
        },
        {
          fieldName: 'valid_from',
          type: 'date',
          attrs: { staticOnEdit: true },
          tabindex: -1,
        },

        { fieldName: 'valid_to', type: 'date', attrs: {},
          validationRules:{not_empty:null,not_null:null}
      },
        {
          fieldName: 'percent',
          type: 'input',
          validationRules: { 'is_decimal_or_zero': null },
          attrs: { width: "12%" },
          isNumberField: true
        },


      ];
      tableComp.defaultNewRow = new Tax();
    },
    //********************************************************************

    users: (tableComp: TableGen) => {
      tableComp.mobileUpperFields = ["name","email","phone_mobile","whatsapp"];
      tableComp.headerModalOpenBtns = [

        {
          icon: "invoice",
          hoverLang: "admininvoices_report",
          modal: "reports",
          fieldsToUpdate: { report_name: "admininvoices_report" },
        },
        {
          icon: "email",
          hoverLang: "send_users_email",
          modal: "send-users-email",
          fieldsToUpdate: {},
          sendCheckedItems: true,
        },
        // {
        //   icon: "users",
        //   hoverLang: "create_potential_user",
        //   modal: "new-user",
        //   fieldsToUpdate: {},
        // },
      ];
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'id',
          type: 'static',
          validationRules: { 'not_empty': null },
          isNumberField: true,
        },
        {
          fieldName: 'user_name',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: {width: "12%"}
        },
        { fieldName: 'name', type: 'static', attrs: {width: "12%"} },
        { fieldName: 'email', type: 'static', attrs: {} },
        { fieldName: 'phone_mobile', type: 'static', attrs: {} },
        {
          fieldName:"whatsapp",
          type:"whatsapp",
          isSortable: false,
          attrs:{
            width: "70px",
          }

        },
        {
          fieldName: 'program_id',
          type: 'static',

        },
        /*	{ fieldName: 'name:string',   type: 'static', validationRules: { 'not_empty': null }, attrs: {} },
						{ fieldName: 'title',   type: 'static', validationRules: { 'not_empty': null }, attrs: {} },
						{ fieldName: 'email',   type: 'static', validationRules: { 'not_empty': null }, attrs: {} },
						{ fieldName: 'phone',   type: 'static', validationRules: { 'not_empty': null }, attrs: {} },
						{
							fieldName: 'phone_mobile',
														type: 'static',
							validationRules: { 'not_empty': null },
							attrs: {}
						},

						{
							fieldName: 'role',
														type: 'static',
																					isNumberField: true
						},

						{
							fieldName: 'program_id',
														type: 'static',
																					isNumberField: true
						},
						{
							fieldName: 'continuous_charge',
														type: 'static',
																					isNumberField: true
						},*/

        {
          fieldName: 'created_at',
          type: 'date',
          validationRules: { },
          attrs: {}
        },
        {
          fieldName: 'last_login',
          type: 'date',
          validationRules: { },
          attrs: {}
        },


        {
          fieldName: 'logins',
          type: 'static',
          validationRules: { },
          attrs: {}
        },
        {
          fieldName: 'sub_users_count',
          langName: 'users',
          type: 'static',
          validationRules: { },
          attrs: {}
        },

        {
          fieldName: 'sub_users',
          langName: '',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "users",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "admin_sub_users", filterField: "clinic_owner_user_id"},
            rowVariableName: "objectRow"
          },	//fieldsToUpdate:{fieldName:val....} not required
          //rowVariableName:string (no required) is the name of the variable inside the modal that will RECEIVE the row that I clicked
        },

        /*	{
						fieldName: 'user_email_consent',
												type: 'static',
						validationRules: { 'not_empty': null },
						attrs: {}
					}, //no','yes')  'no',*/

        {
          fieldName: 'got_instruction',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: {}
        },
        { fieldName: 'instractor_name', type: 'static', attrs: {}, },

        {
          fieldName: 'active',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: {}
        }, //  'yes',
        {
          fieldName: 'status',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: {}
        }, //free','3months_free','paying','suspended')  '3months_free',
        {
          fieldName: 'invoice',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "invoice-sm",
            isImg: true,
            modal: "add-invoice",
            fieldsToUpdate: { resource: "user", doctype: "admininvoice" },
            rowVariableName: "inputRow"
          },
        },

        /*	{ fieldName: 'lang',   type: 'input', validationRules: { 'not_empty': null }, attrs: {} },
					{
						fieldName: 'unsubscribed',
												type: 'input',
																		isNumberField: true
					},*/


      ];
      tableComp.searchableFields = ["user_name"];
      tableComp.sortField = "date";
      tableComp.filterFields = [
        { fieldName: "active", opts: OptionsList.active_opts, initialValue: "yes" },
        { fieldName: "status", opts: OptionsList.userStatus_opts, lang: "paying", initialValue: "paying" },

      ];
      tableComp.isCheckItems = true;
      tableComp.defaultNewRow = new User();
    },
    admin_sub_users: (tableComp: TableGen) => {
      tableComp.mobileUpperFields = ["name","email"];
      tableComp.headerModalOpenBtns = [

        {
          icon: "email",
          hoverLang: "send_users_email",
          modal: "send-users-email",
          fieldsToUpdate: {},
          sendCheckedItems: true,
        },

      ];
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'id',
          type: 'static',
          validationRules: { 'not_empty': null },
          isNumberField: true,
        },
        {
          fieldName: 'user_name',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: {width: "12%"}
        },
        { fieldName: 'name', type: 'static', attrs: {width: "12%"} },
        { fieldName: 'email', type: 'static', attrs: {} },


        {
          fieldName: 'last_login',
          type: 'date',
          validationRules: { 'not_empty': null },
          attrs: {}
        },

        {
          fieldName: 'active',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: {}
        }, //  'yes',
        {
          fieldName: 'status',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: {}
        }, //free','3months_free','paying','suspended')  '3months_free',



      ];

      tableComp.searchableFields = ["user_name"];
      tableComp.sortField = "date";
      tableComp.filterFields = [
        { fieldName: "active", opts: OptionsList.active_opts, initialValue: "yes" },


      ];
      tableComp.isCheckItems = true;
      tableComp.defaultNewRow = new User();
    },
    users_email: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name","email","phone_mobile"];
      tableComp.sortField = "name";
      tableComp.filterFields = [
        { fieldName: "active", opts: OptionsList.active_opts, initialValue: "yes" },
        { fieldName: "status", opts: OptionsList.userStatus_opts, lang: "paying", initialValue: "paying" },

      ];
      tableComp.headerModalOpenBtns = [
        {
          icon: "email",
          hoverLang: "send_users_email",
          modal: "send-users-email",
          fieldsToUpdate: {},
          dataVariablesFields:["faqRows"],
          sendCheckedItems: true,
        },
      ];
      tableComp.isCheckItems = true;
      tableComp.collectionFields = [
        {
          fieldName: 'id',
          langName: 'id',
          type: 'static',
          validationRules: { 'not_empty': null },
          isNumberField: true,
        },
        {
          fieldName: 'name',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: {}
        },

        { fieldName: 'email', type: 'static', attrs: {} },

        { fieldName: 'phone',   type: 'static', validationRules: { 'not_empty': null }, attrs: {} },
        {
          fieldName: 'phone_mobile',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: {}
        },

        {
          fieldName: 'active',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: {}
        }, //  'yes',

      ];
      tableComp.openNewRowOnInit = false;


    },
    user_programs: (tableComp: TableGen) => {
      tableComp.searchableFields = ["user_id"];
      tableComp.mobileUpperFields = ["user_id"];
      tableComp.collectionFields = [
        {
          fieldName: 'user_id',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' }
        },
        //user_id:number = 0;
        //patient_id:number = 0;

        {
          fieldName: 'created_at',
          langName: 'date',
          type: 'static',
          validationRules: { 'not_empty': null },
          attrs: {}
        },
        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
      ];

      tableComp.defaultNewRow = new User_program();
    },
    clinic_roles: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name","perms"];
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: "input",
          validationRules: { not_empty: null, unique: null },
          attrs: { autofocus: 'autofocus', }
        },

        {
          fieldName: 'perms',
          isSortable: false,
          type: 'button',
          attrs: {
            staticOnPerms: ["ccp"],
            icon: "lock",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "role_perms", filterField: "role_id" },
            rowVariableName: "objectRow"
          },	//fieldsToUpdate:{fieldName:val....} not required
          //rowVariableName:string (no required) is the name of the variable inside the modal that will RECEIVE the row that I clicked
        },
        {
          fieldName: 'duplicate',
          type: 'duplicate',
          isSortable: false,
          attrs: { staticOnPerms: ["administrative"], }
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Clinic_role();
    },
    role_perms: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField="order";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: "static",
          attrs: {}
        },
        {
          fieldName: 'value',
          langName: 'perm',
          type: 'button-group',
          attrs: { updateFuncName: "update_role_perms" },	//add specific updating func
          optionsCollection: OptionsList.yes_no_opts
        },
      ];
    },
    sub_users: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];

      tableComp.searchableFields = ["name", "email", "phone_mobile"];
      tableComp.mobileUpperFields = ["name","email", "phone_mobile","perms_role","report"];
      tableComp.duplicateWarningFields = ["name"];
      tableComp.expanded = { upperFields: ["name", "report", "email", "phone_mobile","invoice",],lgAddedUpperFields: ["email_phone", "perms_role", "perms", "tarif_id", "active"], };

      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: "input",
          validationRules: { not_empty: null },
          attrs: { autofocus: 'autofocus', width: "200px", staticOnEdit: true }

        },
        {
          fieldName: 'report',
          langName: 'patients',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "group",
            modal: "sub-user-patients",
            rowVariableName: "inputRow",
            width: "5%", minWidth: "5%", mobileWidth:"20%"
          },
        },
        {
          fieldName: 'user_name',
          type: "static",
          attrs: { width: "10%", minWidth: "10%", staticOnEdit: true, mobileWidth:"20%" }
        },
        {
          fieldName: 'email',
          type: "input",
          validationRules: { email: null, unique: null },
          attrs: { width: "200px", staticOnEdit: true }
        },
        {
          fieldName: 'phone_mobile',
          type: "input",
          validationRules: { not_empty: null, unique: null,regex:/^([0-9]+)$/ },
          attrs: { width: "15%", minWidth: "15%", staticOnEdit: true, mobileWidth:"20%" }
        },
        {
          fieldName: 'perms_role',
          type: "select",
          validationRules: { not_null: null },
          optionsCollection: [],
          attrs: { width: "15%", minWidth: "15%", mobileWidth:"20%" },//staticOnPerms: ["canChangePerms"]
          genTblToLoad: "clinic_roles",
          //	,
        },
        {
          fieldName: 'perms',
          isSortable: false,
          type: 'button',
          attrs: {
            width: "5%", minWidth: "5%",
            staticOnPerms: ["ccp"],
            icon: "lock",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "user_perms", filterField: "user_id" },
            rowVariableName: "objectRow", mobileWidth:"20%"
          },	//fieldsToUpdate:{fieldName:val....} not required
          //rowVariableName:string (no required) is the name of the variable inside the modal that will RECEIVE the row that I clicked
        },

        {
          fieldName: 'tarif_id',
          type: "select",
          validationRules: { not_null: null },
          optionsCollection: [],
          attrs: { width: "10%", minWidth: "10%" , mobileWidth:"20%"},//staticOnPerms: ["canChangePerms"]
          genTblToLoad: "tarifs",
          //	,
        },
        /*	{
						fieldName: 'sub_user_tarif',
						isSortable: false,
						type: 'button',
						attrs: {
							staticOnPerms: ["administrative"],
							icon: "payor",
							modal: "gen-table-display",
							fieldsToUpdate: { tableName: "sub_user_tarifs", filterField: "user_id" },
							rowVariableName: "objectRow"

						},	//fieldsToUpdate:{fieldName:val....} not required
						//rowVariableName:string (no required) is the name of the variable inside the modal that will RECEIVE the row that I clicked
					},*/
        {
          fieldName: "tags",
          type: "tags-ac",
          attrs: {
            width: "20%", minWidth: "15%",
            tagItemType: "user",
            avoidPlus: false,
            staticOnPerms: ["administrative"], mobileWidth:"90%"
          }
        },
        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts,
          attrs: {
            ngIfFunc:(curRow:any)=>{return curRow.otp_time;},
            width: "5%", minWidth: "5%" , mobileWidth:"10%",
          },
        },
        {
          fieldName: 'resend_invite',
          type: 'action-button',
          langName: "resend_invite",
          attrs: {
            ngIfFunc:(curRow:any)=>{return curRow.active=='no';},
            clickFunc:"send_invite",
            mobileWidth:"20%"
          },
        },
        {
          fieldName: 'date_of_birth',
          type: 'date',
          attrs: { width: "12%", minWidth: "10%",  mobileWidth:"20%"}

        },
        {
          fieldName: 'invoice',
          langName: 'invoice_short',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "invoice-sm",
            isImg: true,
            modal: "add-invoice",
            fieldsToUpdate: { resource: "user" },
            rowVariableName: "inputRow"
          },
        },


        {
          fieldName: 'finResource_list',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "payment",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "finResource_list", filterField: "user_id",ignoreError:true },
            rowVariableName: "objectRow"
          },	//fieldsToUpdate:{fieldName:val....} not required
          //rowVariableName:string (no required) is the name of the variable inside the modal that will RECEIVE the row that I clicked
        },
        {
          fieldName: 'color_hex',
          langName: 'bg_color',
          isSortable: false,
          type: 'color',

        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Sub_user();
    },
    user_perms: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "order";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: "static",
          attrs: {}
        },
        {
          fieldName: 'value',
          langName: 'perm',
          type: 'button-group',
          attrs: { updateFuncName: "update_user_perms" },	//add specific updating func
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'inherited_value',
          langName: 'inherited_value',
          type: 'static',
          attrs: {
            outputLang:true,
            ngIfFunc:(row:any)=>{return !row.value},
          },	//add specific updating func
        },
      ];
    },
    branches: (tableComp: TableGen) => {
      tableComp.collectionFields = [	//dbName:{properties}	//types: input,textarea,select,button-group
        {
          fieldName: "name",
          type: "input",
          validationRules: { 'not_empty': null },
          attrs: { autofocus: "autofocus" }
        },
        { fieldName: "code", type: "input", attrs: {} },
        {
          fieldName: "bank_id",
          type: "select",
          optionsCollection: [],
          isNumberField: true
        },

      ]

      tableComp.defaultNewRow = new Branch();
      tableComp.searchableFields = ["name", "code"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.itemsPerPage = 20;
    },
    diagps: (tableComp: TableGen) => {
      tableComp.collectionFields = [	//dbName:{properties}	//types: input,textarea,select,button-group
        {
          fieldName: "name",
          type: "static",
          validationRules: { 'not_empty': null },
          attrs: { autofocus: "autofocus" }
        },
        { fieldName: "code", type: "static", attrs: {} },
        {
          fieldName: "sort_code",
          type: "static",
          isNumberField: true
        },

      ]

      tableComp.defaultNewRow = new Diagp();
      tableComp.searchableFields = ["name", "code"];
      tableComp.mobileUpperFields = ["name", "code"];
      tableComp.sortField = "name";
      tableComp.itemsPerPage = 20;
    },
    medics: (tableComp: TableGen) => {
      tableComp.collectionFields = [	//dbName:{properties}	//types: input,textarea,select,button-group
        {
          fieldName: "trade_name",
          langName: "name",
          type: "static",
          validationRules: { 'not_empty': null },
          attrs: { autofocus: "autofocus" }
        },
        { fieldName: "reg_no", type: "static", attrs: {} },
        {
          fieldName: "route_of_admin",
          type: "static",
          attrs: {}
        },
        {
          fieldName: "dosage_form",
          type: "static",
          attrs: {}
        },
        {
          fieldName: "amount",
          type: "static",
          isNumberField: true
        },
        { fieldName: "unit", type: "static", attrs: {} },
        {
          fieldName: "atc5_name",
          type: "static",
          attrs: {}
        },
        {
          fieldName: "atc5_code",
          type: "static",
          attrs: {}
        },
        {
          fieldName: "sort_code",
          type: "static",
          isNumberField: true
        },
        /*	{
						fieldName: "amount_array",
						type: "static",
						attrs: {}
					},*/
      ]

      tableComp.defaultNewRow = new Medic();
      tableComp.searchableFields = ["trade_name", "atc5_name"];
      tableComp.mobileUpperFields = ["trade_name", "atc5_name"];
      tableComp.sortField = "trade_name";
      tableComp.itemsPerPage = 20;
    },
    notifications: (tableComp: TableGen) => {
      tableComp.expanded = { upperFields: ["date", "time", "subject", "toname", "email_phone", "type", "download", "email", "sendgrid_status",] };
      tableComp.mobileUpperFields = ["date", "time", "subject",];
      tableComp.collectionFields = [	//dbName:{properties}	//types: input,textarea,select,button-group
        {
          fieldName: 'date',
          type: 'date',
          attrs: { width: "12%", minWidth: "10%" }

        },
        {
          fieldName: 'time',
          type: 'time',
          attrs: { width: "12%", minWidth: "10%" }
        },
        {
          fieldName: 'subject',
          type: 'static',
          attrs: { width: "30%", minWidth: "30%" }
        },
        {
          fieldName: 'message',
          type: 'static',
          attrs: { width: "50%", minWidth: "50%" }
        },
        {
          fieldName: 'toname',
          type: 'static',
          attrs: {  }
        },
        {
          fieldName: 'email_phone',
          type: 'static',
          attrs: {  }
        },
        {
          fieldName: 'remarks',
          type: 'wysiwyg',
          attrs: { width: "70%", minWidth: "70%", autofocus: 'autofocus' }
        },
        {
          fieldName: 'sendgrid_status',
          type: 'staticIcon',
          attrs: { width: "10%", minWidth: "10%", outputLang: true }
        },
        {
          fieldName: 'type',
          type: 'static',
          attrs: { width: "10%", minWidth: "10%", outputLang: true }
        },
        {
          fieldName: 'download',
          type: 'download',
          isSortable: false,
          attrs: { width: "12%", minWidth: "12%" }
        },
        {
          fieldName: 'email',
          isSortable: false,
          type: 'mail-to',
          attrs: { width: "2%", minWidth: "2%" },
        },
        {
          fieldName: 'status',
          type: 'static',
          attrs: { outputLang: true },
          optionsCollection: OptionsList.notification_statusOpts
        },
      ];


      tableComp.defaultNewRow = new Notification();
      tableComp.searchableFields = ["toname"];
      tableComp.sortField = "date";
      tableComp.sortIsAsc = false;
      tableComp.filterFields = [
        /*	{ fieldName: "status", opts: OptionsList.notification_statusOpts },*/
        {
          fieldName: "type",
          opts: OptionsList.notification_typeOpts,
        },
        /*{ fieldName: "from_patient", opts: OptionsList.true_false_opts }
				,*/
      ];
      tableComp.itemsPerPage = 20;
    },

    medilogs: (tableComp: TableGen) => {
      /*u_id:number = 0;
	* */
      tableComp.perms = ["administrative"];
      tableComp.searchableFields = ["patient_id"];
      tableComp.mobileUpperFields = ["patient_id","resource_id","resource_heb","user_id"];
      tableComp.expanded = { upperFields: ["patient_id","resource_id","resource_heb","user_id", "created_at", "updated_at","time"] };
      tableComp.itemsPerPage = 20;

      tableComp.sortField = "resource_id";
      tableComp.sortIsAsc = false;
      tableComp.itemsPerPage = 20;

      tableComp.collectionFields = [
        {
          fieldName: 'patient_id',
          langName:'patient',
          type: 'static',
          attrs: { width: "5%"},
          tabindex: 1,
        },
         {
					 fieldName: 'resource_id',
                       langName:'id',
											 type: 'static',
											 attrs: {width: "5"},
					 tabindex: 1,
				 },
        {
					 fieldName: 'resource_heb',
                       langName:'action',
											 type: 'static',
											 attrs: {width: "10%", minWidth: "10%"},
					 tabindex: 1,
				 },
        {
					 fieldName: 'user_id',
											 type: 'static',
											 attrs: {width: "10%", minWidth: "10%"},
					 tabindex: 1,
				 },

        {
          fieldName: 'updated_at',
          type: 'date',
          attrs: { width: "10%", minWidth: "10%" }
        },
        {
          fieldName: 'time',
          type: 'time',
        },


        { fieldName: 'description_text',langName:'medical_record', type: 'static', attrs: { width: "40%" } },
        { fieldName: 'remarks_text',langName:'personal_memorandum', type: 'static', attrs: { width: "30%" } },

        { fieldName: 'message_heb',langName:'message', type: 'static', attrs: { width: "10%" } },
        { fieldName: 'remarks_heb',langName:'remarks', type: 'static', attrs: { width: "20%" } },

      ];
      tableComp.defaultNewRow = new Medilog();

    },
    tags: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.mobileUpperFields = ["name"];
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus', width: "30%", minWidth: "20%" ,mobileWidth: "80%" },
          tabindex: 1,
        },
        /*	{
						fieldName: 'lang',
						isSortable: false,
						type: 'button-group',
						validationRules: {},
						attrs: { width: "10%", minWidth: "10%", },
						optionsCollection: OptionsList.langOpts
					}, {
						fieldName: 'description',
						type: 'input',
						validationRules: {},
						attrs: {},
						tabindex: 1,
					},*/
      ];

      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Tag();

    },
    pricingtypes: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus', width: "30%", minWidth: "20%" },
          tabindex: 1,
        },
        {
          fieldName: 'monthly',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
      ];

      tableComp.openNewRowOnInit = true;

      tableComp.defaultNewRow = new Pricingtype();

    },
    locations: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus', width: "30%", minWidth: "20%",mobileWidth: "80%"  },
          tabindex: 1,
        },
        {
          fieldName: 'is_conflicting',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts,
          attrs: { mobileWidth: "80%"  },
        },
        {
          fieldName: "tags",
          type: "tags-ac",
          attrs: {
            tagItemType: "location",
            avoidPlus: false,
            mobileWidth: "80%"
          }
        },
        {
          fieldName: 'color',
          langName: 'bg_color',
          isSortable: false,
          type: 'color',
          tabindex: 3,
        },

      ];

      tableComp.openNewRowOnInit = true;

      tableComp.defaultNewRow = new Location();
    },
    states: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus', width: "30%", minWidth: "20%" },
          tabindex: 1,
        },
        {
          fieldName: 'task_text',
          langName:'text',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { width: "30%", minWidth: "20%" },
          tabindex: 2,
        },
        {
          fieldName: 'repeat_every_days',
          type: 'input',
          validationRules: { 'is_integer': null },
          attrs: { width: "10%", minWidth: "10%", },
          tabindex: 3,
          isNumberField: true,
          isHideable: true,
        },{
          fieldName: 'revoke_in_days',
          type: 'input',
          validationRules: { 'is_integer': null },
          attrs: { width: "10%", minWidth: "10%", },
          tabindex: 4,
          isNumberField: true,
          isHideable: true,
        },

      ];

      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new State();

    },
    sub_user_tarifs: (tableComp: TableGen) => {
      tableComp.searchableFields = ["tarif_id"];
      tableComp.mobileUpperFields = ["tarif_id"];
      tableComp.sortField = "name";

      tableComp.collectionFields = [
        {
          fieldName: 'tarif_id',
          langName: 'pricingtype',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true },
          attrs: {
            displayStringFieldName: "pricingtype_name",
            fuzzySearchableClasses: ["pricingtypes"],
            tableName: "pricingtypes",
            showPlusPerm:"administrative",
          },	//avoidPlus:true is optional
          isNumberField: true,
          tabindex: 2,
        },
        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },

      ];

      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Sub_user_tarif();

    },
    object_to_sub_users: (tableComp: TableGen) => {
      tableComp.searchableFields = ["user_id"];
      tableComp.mobileUpperFields = ["user_id"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [

        {
          fieldName: 'user_id',
          langName: 'user',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true, unique: true },
          attrs: {
            displayStringFieldName: "sub_user_name",
            fuzzySearchableClasses: ["sub_users"],
            tableName: "sub_users",
            avoidPlus: true,
          },	//avoidPlus:true is optional
          isNumberField: true,
          tabindex: 2,
        },

        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },

      ];

      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Object_to_sub_user();

    },
    sub_user_groups: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },
        {
          fieldName: 'description',
          type: 'input',
          validationRules: {},
          attrs: {},
          tabindex: 1,
        },
        {
          fieldName: 'sub_user_group_member',
          isSortable: false,
          type: 'button',
          attrs: {
            icon: "therapist",
            modal: "gen-table-display",
            fieldsToUpdate: { tableName: "sub_user_group_members", filterField: "sub_user_group_id" },
            rowVariableName: "objectRow",
          }
        },

      ];

      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Sub_user_group();

    },
    sub_user_group_members: (tableComp: TableGen) => {
      tableComp.searchableFields = ["sub_user_group_name"];
      tableComp.mobileUpperFields = ["user_id"];
      tableComp.sortField = "id";
      tableComp.collectionFields = [
        {
          fieldName: 'user_id',
          langName: 'user_id',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true },
          attrs: {
            displayStringFieldName: "sub_user_name",
            fuzzySearchableClasses: ["sub_users"],
            tableName: "sub_users",
            avoidPlus: true,
          },	//avoidPlus:true is optional
          tabindex: 2,
          isNumberField: true
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Sub_user_group_member();

    },
    medicalogs: (tableComp: TableGen) => {
      tableComp.searchableFields = ["patient_id"];
      tableComp.mobileUpperFields = ["patient_id", "created_at", "updated_at"];
      tableComp.expanded = { upperFields: ["patient_id", "created_at", "updated_at"] };

      tableComp.sortField = "resource_id";
      tableComp.sortIsAsc = false;

      tableComp.collectionFields = [
        {
          fieldName: 'patient_id',
          type: 'static',
          attrs: { width: "10%", minWidth: "10%" },
          tabindex: 1,
        },
        /* {
					 fieldName: 'resource_id',
											 type: 'static',
											 attrs: {width: "10%", minWidth: "10%"},
					 tabindex: 1,
				 }, {
					 fieldName: 'resource',
											 type: 'static',
											 attrs: {width: "10%", minWidth: "10%"},
					 tabindex: 1,
				 },*/
        {
          fieldName: 'created_at',
          type: 'date',
          attrs: { width: "10%", minWidth: "10%" }
        },
        {
          fieldName: 'updated_at',
          type: 'date',
          attrs: { width: "10%", minWidth: "10%" }
        },


        { fieldName: 'message', type: 'static', attrs: { width: "30%", minWidth: "30%" } },


      ];
      tableComp.defaultNewRow = new Medicalog();

    },
    tasks: (tableComp: TableGen) => {
      tableComp.searchableFields = ["title"];
      tableComp.mobileUpperFields = ["title"];

      tableComp.sortField = "title";
      tableComp.collectionFields = [
        {
          fieldName: 'user_id',
          langName: 'user',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true, unique: true },
          attrs: {
            displayStringFieldName: "sub_user_name",
            fuzzySearchableClasses: ["sub_users"],
            tableName: "sub_users",
            avoidPlus: true,
          },	//avoidPlus:true is optional
          isNumberField: true,
          tabindex: 1,
        },
        {
          fieldName: 'title',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 2,
        },
        {
          fieldName: 'content',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 3,
        },
        {
          fieldName: 'date',
          type: 'date',
          validationRules: { 'not_empty': null },
          tabindex: 4
        },
        {
          fieldName: 'time',
          type: 'time',
          validationRules: {},
          tabindex: 5
        },
        {
          fieldName: 'repeat_days',
          type: 'input',
          validationRules: { 'not_empty': null },
          isNumberField: true,
          tabindex: 6
        },
        {
          fieldName: 'email_notify',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'sms_notify',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        }, {
          fieldName: 'closed',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },
        {
          fieldName: 'active',
          type: 'button-group',
          optionsCollection: OptionsList.yes_no_opts
        },


      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Task();
    },
    timeAllocations: (tableComp: TableGen) => {

      tableComp.searchableFields = ["user_id"];
      tableComp.mobileUpperFields = ["user_id"];
      tableComp.sortField = "user_id";
      tableComp.collectionFields = [
        {
          fieldName: 'user_id',
          langName: 'user',
          type: 'fuzzy',
          validationRules: { 'is_legit_fuzzy_value': true, unique: true },
          attrs: {
            displayStringFieldName: "sub_user_name",
            fuzzySearchableClasses: ["sub_users"],
            tableName: "sub_users",
            avoidPlus: true,
          },	//avoidPlus:true is optional
          isNumberField: true,
          tabindex: 1,
        },
        {
          fieldName: 'location_id',
          langName: 'location',
          type: 'fuzzy',
          validationRules: { 'is_legit_or_empty': true, unique: true },
          attrs: {
            displayStringFieldName: "location_name",
            fuzzySearchableClasses: ["locations"],
            tableName: "locations",
            avoidPlus: true,
          },	//avoidPlus:true is optional
          isNumberField: true,
          tabindex: 1,
        },
        {
          fieldName: 'day',
          type: 'input',
          validationRules: { 'not_empty': null },
          isNumberField: true,
          tabindex: 6
        },
        {
          fieldName: 'time',
          type: 'time',
          validationRules: {},
          tabindex: 5
        },
        {
          fieldName: 'time_to',
          type: 'time',
          validationRules: {},
          tabindex: 5
        },

      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new TimeAlloaction();
    },
    solo_time_allocations: (tableComp: TableGen) => {
      tableComp.updateRowOverride="update_solo_time_allocation";
      tableComp.searchableFields = ["day"];
      tableComp.mobileUpperFields = ["day","time"];
      tableComp.sortField = "day";
      tableComp.collectionFields = [

        {
          fieldName: 'day',
          type: 'select',
          validationRules: { time_allocation:true },
          isNumberField: true,
          optionsCollection:OptionsList.days_of_week_opts,
          attrs: { width: "20%", minWidth: "20%", },
        },
        {
          fieldName: 'time',
          langName: 'time_from',
          type: 'time',
          validationRules: {},
          attrs: { width: "15%", minWidth: "15%", },
        },
        {
          fieldName: 'time_to',
          type: 'time',
          validationRules: {},
          attrs: { width: "15%", minWidth: "15%", },
        },

      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new SoloTimeAllocation();
    },
    faqs: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];

      tableComp.isCheckItems = true;

      tableComp.searchableFields = ["question","answer"];
      tableComp.mobileUpperFields = ["question","answer"];
      tableComp.sortIsAsc = false;
      tableComp.collectionFields = [
        {
          fieldName: 'subject_id',
          langName: 'subject',
          type: 'fuzzy',
          validationRules: { is_legit_fuzzy_value_or_empty: true },
          attrs: {
            displayStringFieldName: "name",
            fuzzySearchableClasses: ["faq_subjects"],
            tableName: "faq_subjects",
            showPlusPerm:"administrative",
          },	//avoidPlus:true is optional
          isNumberField: true,
        },

        {
          fieldName: 'question',
          type: 'wysiwyg',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },
        {
          fieldName: 'answer',
          type: 'wysiwyg',
          validationRules: { 'not_empty': null },
          attrs: {  },
          tabindex: 1,
        },

      ];
      tableComp.headerModalOpenBtns = [
        {
          icon: "list",
          hoverLang: "subjects",
          modal: "gen-table-display",
          fieldsToUpdate: { tableName: "faq_subjects" },
        },
        {
          icon: "email",
          hoverLang: "users_email",
          modal: "gen-table-display",
          fieldsToUpdate: { tableName: "users_email",modalWidth:"modal-default-width",faqSend:true },
        },

      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Faq();
    },
    faq_subjects: (tableComp: TableGen) => {
      tableComp.perms = ["administrative"];
      tableComp.mobileUpperFields = ["name"];
      tableComp.searchableFields = ["name"];
      tableComp.sortIsAsc = false;
      tableComp.collectionFields = [

        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null },
          attrs: { autofocus: 'autofocus' },
          tabindex: 1,
        },

      ];
      tableComp.headerModalOpenBtns = [


      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Faq_subject();
    },
    testgroups: (tableComp: TableGen) => {
      tableComp.searchableFields = ["name"];
      tableComp.sortField = "name";
      tableComp.collectionFields = [
        {
          fieldName: 'name',
          type: 'input',
          validationRules: { 'not_empty': null, "unique": null },
          attrs: { autofocus: 'autofocus', width: "30%", minWidth: "30%" },
          tabindex: 1,
        },
        {
          fieldName: 'tests_in_group',
          type: 'textarea',
          attrs: {}
        },
      ];
      tableComp.openNewRowOnInit = true;
      tableComp.defaultNewRow = new Testgroup();

    },
  };



  /*
	//table properties:
	collectionFields: the fields to be used in this table
	langProps: lang values to be used in the display (currently allName, the collection's name)
	collectionName: used to read the "get items" return JSON
	defaultNewRow: the object to create as "new row" (usually new className())
	duplicateWarningFields: fields to WARN (but not prevent) before update/save if already exist in table
	filterFields : array of filters (as select HTML elements) to display, each object has the fields:
		fieldName: the field to "filter by"
		opts: collection of possible options by which to filter
		lang: lang value to display next to field
		checkType: how to compare (values: "null")
		defaultValue
		canEditOnValue: isEditDisabled is determined by: selected option == THIS VALUE
	searchableFields: array of fields to search in
	sortField: which field to sort by initially (default: "name")
	updateRowOverride: use a different api call to update
	itemsPerPage: how many items to display per page (relevant only in pagi tables)
	showItemBtnUrl
	expanded: upperFields - field names to be on top (rest need to be expanded)
	mobileUpperFields: when mobile, force these fields to be the upperFields, and expand (if missing - the first 2 fields are chosen)
	filterField
	filterFieldValue
	openNewRowOnInit:prepNewRow on open
	headerModalOpenBtns: array of btns to appear in the header next to the name and + icon
		icon
		hoverLang
		modal: modal to open
		fieldsToUpdate
		dataVariablesFields: list of field names to be taken from dataVariables

	//field properties:
	fieldName
	langName
	isSortable
	type (input,textarea,select,button-group,fuzzy,image,file,date,time,button,wysiwyg,download,mail-to,fuzzy-api)
	validationRules
	optionsCollection: the options to display in select/button-group
	isNumberField
	savingProps: object to prevent/delay updating this field
		noSave: prevents immediate update after field has been changed
		funcName: function name to run after field has been changed
		fieldName: which field to insert as paramter to funcName (has the relationship influencer (this field)->influencee (fieldName)  )
		//hint: what actually happens is funcName(fieldName)
	attrs: obejct of many customizable options:
		autofocus
		displayStringFieldName: (fuzzy) the field from THIS ROW that has the display value (not the real DB value)
		objectFieldToSave: (fuzzy) name of field in siteData, that has the real DB value to be saved
		fuzzySearchableClasses: (fuzzy) array of which fuzzy classes to search
		tableName: (fuzzy add row modal) which table to use to add the new row to
		tableNameLang: (fuzzy add row modal) the lang to use for collection name
		fuzzySearchObj: (fuzzy) object to explain how to use api call in fuzzy instead of siteData
			apiCall
			outerFilterName: name of the field to pass as FILTER to the apiCall (the value will be ) AND the field to get the filter value from in THIS ROW
			innerFilterName: name of field that contains the "search string"
			otherFieldsToUpdate: array of other fields in the row to update
		optionDisplay: (fuzzy) the display template (will replace values from THIS ROW) to display in fuzzy
		urlTemplate: template (will replace values from THIS ROW) to use in image fields
		icon: (button) the name of the icon to display
		modal: (button) the component to open as modal on button click
		fieldsToUpdate: (button) object of fields to update the opened modal (eg. { tableName: "meetingtypes", tableNameLang: "meetingtypes" })
		rowVariableName: (button) the name of the field in the modal that will contain THIS ROW
		headturesInsert: special for headtures insert
		avoidPlus
		updatedByServer: gets value after newRow


	*/

  switchTableNames(tableComp: TableGen) {

    if (this.tableNamesToInitFuncs[tableComp.tableName]) {
      this.tableNamesToInitFuncs[tableComp.tableName](tableComp);
    }
    tableComp.collectionFields.forEach(fieldObj => {
      fieldObj.langName = fieldObj.langName === undefined ? fieldObj.fieldName : fieldObj.langName;
      fieldObj.isSortable = fieldObj.isSortable === undefined ? true : fieldObj.isSortable;
      fieldObj.validationRules = fieldObj.validationRules || {};
      fieldObj.attrs = fieldObj.attrs || {};
      if (fieldObj?.fields) {
        fieldObj.fields.forEach(innerFieldObj => {
          innerFieldObj.langName = innerFieldObj.langName === undefined ? innerFieldObj.fieldName : innerFieldObj.langName;
          innerFieldObj.attrs = innerFieldObj.attrs || {};

        })
      }
    })
    tableComp.filterFields.forEach(fieldObj => {
      fieldObj.lang = fieldObj.lang === undefined ? fieldObj.fieldName : fieldObj.lang;
    })

    tableComp.tabindexDomain = 100 * ((Object.keys(this.tableNamesToInitFuncs).findIndex(tb => tb == tableComp.tableName) || 0) + 1);	//calculate the basic tabindex domain based on position in the cases array

    tableComp.collectionFields.filter(fieldObj => fieldObj.tabindex !== undefined).forEach(fieldObj => fieldObj.tabindex += tableComp.tabindexDomain);	//add the tabindexDomain to all fields with a tabindex

    tableComp.langProps = { allName: this.store.getNameVars(tableComp.tableName, "allName") };
    tableComp.collectionName = this.store.getNameVars(tableComp.tableName, "collectionName"); //is this just tableName?


    this.attachApiCallPerms(tableComp);	//attached API call perms
  }

  attachApiCallPerms(tableComp: any) {	//attach API call perms
    if (this.apiService.apiCallPerms) {
      tableComp.apiCallPerms = this.apiService.apiCallPerms[tableComp.tableName];
    }
  }

  fromDateStrToDateString(str: string) {	//convert various possible date writing formats to YYYY-MM-DDT00:00:00 (for saving an alternate date field for sorting purposes)
    //2014-4-5 3:14,2014-4-5, 4-5-2014, 3:15 4-5-2014
    if (!str) {
      return 0;
    }
    const datePart = (str.trim().split(" "))[0];	//leave only the date part for "date... time..." formats
    const dateParts = datePart.split("-");	//split to date parts
    if (dateParts.length != 3) {
      return "";
    }
    const yearFirst = (dateParts[0].length == 4);	//try to determine if the year is the first or third part
    const year = dateParts[yearFirst ? 0 : 2];
    const month = Number(dateParts[1]);
    const date = Number(dateParts[yearFirst ? 2 : 0]);
    return `${year}-${this.toDoubleDig(month)}-${this.toDoubleDig(date)}T00:00:00`;
  }

  toDoubleDig(num: number) {	//add a leading zero if needed
    return (num < 10 ? "0" : "") + num
  }

  updDateField(row: any, fieldName: string, date: Date = null) {	//update the alternate date field in a row according to the base field (or value)
    row[this.store.getDateExpandName(fieldName)] = (date ? new Date(date) : new Date(this.fromDateStrToDateString(row[fieldName])));	//if taken from the base field - make sure to normalize the date format
  }

}
