export const OptionsList = {
	expensetype_permanentOpts: [
		{ value: 'permanent', name: '' },
		{ value: 'no', name: '' },
	],
  morning_evening: [
		{ value: 'morning', name: '' },
		{ value: 'evening', name: '' },
	],

	withvatOpts: [
		{ value: 'yes', name: '' },
		{ value: 'no', name: '' },
	],
	chargeModeOpts: [
		{ value: 'by_participant', name: '' },
		{ value: 'by_group', name: '' },
	],

	paymentModeOpts: [
		{ value: 'by_attending', name: '' },
		{ value: 'no_cancellation', name: '' },
	],


	chargeOpts: [
		{ value: 'yes', name: 'charged' },
		{ value: 'no', name: 'no' },
	],
	takesPlaceOpts: [
		{ value: 'yes', name: 'takes_place' },
		{ value: 'no', name: '' },
	],
	requiredOpts: [
		{ value: 'yes', name: 'required' },
		{ value: 'no', name: '' },
	],
	announce_an_langOpts: [
		{ value: 'iw', name: 'heb' },
		{ value: 'en', name: 'eng' },
	],

	langOpts: [
		{ value: 'iw', name: '' },
		{ value: 'en', name: '' },
	],
  subscriptionTypeOpts: [
		{ value: 'private', name: '' },
		{ value: 'multi_user', name: '' },
	],

  locationsOnlyOpts: [
		{ value: 'iw', name: '' },
		{ value: 'en', name: '' },
	],
  hasUsersOpts: [
		{ value: 'iw', name: '' },
		{ value: 'en', name: '' },
	],
	taxAccountOpts: [
		{ value: 'om', name: 'om_long' },
		{ value: 'op', name: 'op_long' },
		{ value: 'hp', name: 'hp_long' },
		{ value: 'ngo', name: 'ngo_long' },
	],


	notification_defaultOpts: [
		/*{ value: 'whatsapp', name: '' },*/
		{ value: 'email', name: '' },
		{ value: 'sms', name: '' },
	],

	notifiyOpts: [
		{ value: 'day_before', name: '' },
		{ value: 'meeting_day', name: '' },
	],

	marital_status_opts: [
		{ value: 'single', name: '' },
		{ value: 'married', name: '' },
		{ value: 'widowed', name: '' },
		{ value: 'divorced', name: '' },
		{ value: 'other', name: '' },
	],

  parents_marital_status_opts: [
    { value: 'parents_married', name: '' },
    { value: 'parents_not_married', name: '' },
    { value: 'father_widowed', name: '' },
    { value: 'mother_widowed', name: '' },
    { value: 'parents_divorced', name: '' },
    { value: 'other', name: '' },
  ],




  dx_status_opts: [
		{ value: 'Susp.', name: 'Susp.' },
		{ value: 'M/P', name: 'M/P' },
		{ value: 'R/O', name: 'R/O' },
		{ value: 'REC', name: 'REC' },
		{ value: 'S/P', name: 'S/P' },
		{ value: 'Prolonged', name: 'Prolonged' },

	],

	export_to_doc_type_opts: [
		//{ value: 'pdf', name: '' },
		{ value: 'csv', name: 'doc_type_csv' },
    //	{ value: 'hashav-mov', name: 'doc_type_hash_mov' },
		//{ value: 'hashav-hesh', name: 'doc_type_hash_hesh' },
	//{ value: 'hashav-xlsx', name: 'doc_type_hash_xlsx' },
	//	{ value: 'bkmv', name: 'irs' },
	],



	file_type_opts: [
		{ value: 'pdf', name: 'opt_pdf' },
		{ value: 'csv', name: 'opt_csv' },
		{ value: 'dat', name: 'opt_dat' },
		{ value: 'xlsx', name: 'opt_xlsx' },
		{ value: 'other', name: 'opt_other' },
	],

	frequency_opts: [
		{ value: 'never', name: '' },
		{ value: '1month', name: 'month1' },
		{ value: '2month', name: 'month2' },

	],

	level_opts: [
		{ value: 'info', name: '' },
		{ value: 'error', name: '' },


	],

	yes_no_opts: [
		{ value: 'yes', name: '' },
		{ value: 'no', name: '' },
	],
  is_assoc_no_opts: [
		{ value: 'yes', name: 'with_assoc' },
		{ value: 'no', name: 'without_assoc' },
	],


	true_false_opts: [
		{ value: 'true', name: 'from_patient' },
		{ value: 'false', name: 'from_user' },
	],

	charged_no_opts: [
		{ value: 'yes', name: 'charged' },
		{ value: 'no', name: '' },
	],

  payor_no_opts: [
		{ value: 'yes', name: 'payor' },
		{ value: 'no', name: '' },
	],

	notifyNoOpts: [
		{ value: 'yes', name: 'notifiy' },
		{ value: 'no', name: '' },
	],

	vacation_all_users_no_opts: [
		{ value: 'yes', name: 'vacation_all_users' },
		{ value: 'no', name: '' },
	],
	charged_manualy_no_opts: [
		{ value: 'yes', name: 'charged_manualy' },
		{ value: 'no', name: '' },
	],
  charged_manualy_payor_no_opts: [
		{ value: 'yes', name: 'charged_manualy_payor' },
		{ value: 'no', name: '' },
	],

	medicalConf_opts: [
		{ value: 'yes', name: 'medical_conf' },
		{ value: 'no', name: '' },
	],
	active_opts: [
		{ value: 'yes', name: 'active' },
		{ value: 'no', name: '' },
	],
	active_all_opts: [
		{ value: 'yes', name: 'active' },
		{ value: 'no', name: '' },
    { value: '-1', name: 'all_options' },
	],

  locked_opts: [
		{ value: 'yes', name: 'is_locked' },
		{ value: 'no', name: '' },
	],
  patient_active_insert_opts: [
		{ value: 'yes', name: 'active' },
		{ value: 'no', name: '' },
    { value: 'pending', name: '' },
	],

  patient_active_opts: [
		{ value: 'yes', name: 'active_patients' },
		{ value: 'no', name: '' },
		{ value: 'pending', name: '' },
		{ value: '-1', name: 'all_options' },
	],

  user_active_opts: [
		{ value: 'yes', name: 'active_users' },
		{ value: 'no', name: '' },

	],
  patient_inquirer_opts: [
		{ value: 'patient', name: 'patient' },
		{ value: 'inquirer', name: 'inquirer' },
		{ value: '-1', name: 'all_options' },
	],
  patient_or_inquirer_opts: [
		{ value: 'patient', name: 'patient' },
		{ value: 'inquirer', name: 'inquirer' },

	],
	urgent_opts: [
		{ value: 'yes', name: 'urgent' },
		{ value: 'no', name: '' },
	],
	closed_opts: [
		{ value: 'yes', name: 'done' },
		{ value: 'no', name: '' },
	],
	sms_notify_opts: [
		{ value: 'yes', name: 'sms_notify' },
		{ value: 'no', name: '' },
	],
	email_notify_opts: [
		{ value: 'yes', name: 'email_notify' },
		{ value: 'no', name: '' },
	],
  confident_opts: [
		{ value: 'yes', name: 'confident' },
		{ value: 'no', name: '' },
	],
  main_opts: [
		{ value: 'yes', name: 'main' },
		{ value: 'no', name: '' },
	],

  assoc_payment_opts: [
		{ value: 'to_pay', name: '' },
		{ value: 'to_receive', name: '' },
	],




	deleted_active_opts: [
		{ value: 'active', name: 'active' },
		{ value: 'deleted', name: 'deleted' },
	],
	invoice_by_mail_opts: [
		{ value: 'yes', name: 'invoice_by_mail' },
		{ value: 'no', name: '' },
	],
	automatic_proforma_opts: [
		{ value: 'yes', name: 'automatic_proforma' },
		{ value: 'no', name: '' },
	],

	is_group_opts: [
		{ value: 'yes', name: 'group' },
		{ value: 'no', name: '' },
	],
	arrived_no_opts: [
		{ value: 'yes', name: 'arrived' },
		{ value: 'no', name: '' },
	],

	gender_opts: [
		{ value: 'female', name: '' },
		{ value: 'male', name: '' },
		{ value: 'other', name: '' },
	],

	headture_header_defaultOpts: [
		{ value: 'i', name: 'invoice' },
		{ value: 'p', name: 'prescription' },
		{ value: 'l', name: 'letter' },
		{ value: 'n', name: 'none' },
	],

	series_repeat_opts: [
		{ value: 'one_time', name: '' },
		{ value: 'series_weekly', name: '' },
		{ value: 'series_monthly', name: '' },
		{ value: 'series_2weekly', name: '' },
		{ value: 'series_3weekly', name: '' },
		{ value: 'series_exception', name: '' },
		{ value: 'series_daily', name: '' },

	],
	unit_opts: [
		{ value: 'percent', name: '' },
		{ value: 'amount', name: 'amountt' },

	],

	notification_typeOpts: [
		{ value: 'chat', name: '' },
		{ value: 'reminder', name: '' },
		{ value: 'document', name: '' },
		{ value: 'invoice', name: '' },
		// { value: 'proforma', name: '' },
		// { value: 'prescription', name: '' },
		//{ value: 'document_copy', name: '' },
		//{ value: 'invoice_copy', name: '' },
	],
	notification2_typeOpts: [
		{ value: 'chat', name: '' },
		{ value: 'self_reminder', name: '' },
		{ value: 'reminder', name: '' },
		{ value: 'auto_message', name: '' },
		{ value: 'document', name: '' },
		{ value: 'invoice', name: '' },
		{ value: 'proforma', name: '' },
		{ value: 'prescription', name: '' },
		{ value: 'document_copy', name: '' },
		{ value: 'invoice_copy', name: '' },
	],

	notification_statusOpts: [
		{ value: 'open', name: '' },
		{ value: 'closed', name: '' },
	],
	document_doc_types: [
	  { value: 'html', name: 'show_letters' },
		{ value: 'upload', name: 'show_documents' },
    { value: '-1', name: 'all_options' },

	],

	paymenttype_opts: [
		{ value: 'cash', name: '' },
		{ value: 'cheque', name: '' },
		{ value: 'bank_transfer', name: '' },
		{ value: 'credit_card', name: '' },
		{ value: 'paymentapp', name: '' },
	],

	finResourceDocType_opts: [
		{ value: 'invoice', name: '' },
		{ value: 'proforma', name: '' },
		{ value: 'justinvoice', name: '' },
		{ value: 'receipt', name: '' },
		{ value: 'selfinvoice', name: '' },
	],

	change_series_opts: [
		{ value: 'only_one', name: '' },
		{ value: 'now_on', name: '' },
		{ value: 'until_date', name: '' },

	],

	medicpatients_filters: [
		{ value: "null", name: "recent" },
		{ value: "nonNull", name: "historical" }
	],

	vat_opts: [
		{ value: "no", name: "withvat" },
        { value: "yes", name: "withoutvat" }
	],
  taxesReportMode_opts: [
		{ value: "taxes", name: "" },
		{ value: "taxes_income_tax", name: "" },
		{ value: "taxes_vat", name: "" }
	],

  is_withholding_tax_opts: [
		{ value: "yes", name: "withholding_tax" },
		{ value: "no", name: "" }
	],

	isNew_opts: [
		{ value: true, name: "new" },
		{ value: false, name: "existing" }
	],

	mixedList_opts: [
		{ value: "without", name: "without_personal_memorandum" },
		{ value: "with_personal_memorandum", name: "" },
		{ value: "only_personal_memorandum", name: "" }
	],

  mixedList_pm_opts: [
		{ value: "yes", name: "with_personal_memorandum" },
		{ value: "no", name: "" }
	],
  mixedList_desc_opts: [
		{ value: "yes", name: "with_description" },
		{ value: "no", name: "" }
	],

	userStatus_opts: [
		{ value: "paying", name: "paying" },
		{ value: "free", name: "free" },
		{ value: "suspended", name: "suspended" },
		{ value: "3months_free", name: "3months_free" },
		{ value: "intern", name: "intern" }
	],
	report_name_opts: [
		{ value: "invoices", name: "" },
		{ value: "payment_method", name: "" },
		{ value: "expenses", name: "" },
		{ value: "profitloss", name: "" },
		{ value: "taxes", name: "" },
		{ value: "taxes_income_tax", name: "" },
		{ value: "taxes_vat", name: "" },
	],

	send_freq_opts: [
		{ value: "1month", name: "" },
		{ value: "2month", name: "" },
	],
	file_format_opts: [
		{ value: "xlsx", name: "" },
    { value: "csv", name: "" },
		{ value: "pdf", name: "opt_pdf" },
	],
	date_in_month_opts: [
		{ value: "3", name: "" },
		{ value: "5", name: "" },
		{ value: "7", name: "" },
	],

	contactway_identifier_opts: [
		{ value: "email", name: "" },
		{ value: "phone", name: "" },
		{ value: "other", name: "" },
	],

	perms_page_section_opts: [
		{ value: "users", name: "" },
		{ value: "roles", name: "" },

	],
	calendar_view_opts: [
		{ value: "all_fus", name: "" },
		{ value: "non_patients", name: "" },

	],

	vacation_all_users: [
		{ value: 'yes', name: 'vacation_all_users' },
		{ value: 'no', name: 'not_vacation_all_users' },
	],
	graphs_freqs: [
		{ value: 'yearly', name: '' },
		{ value: 'quarterly', name: '' },
		{ value: 'monthly', name: '' },
	],
	graphs_time_ranges: [
    { value: 'this_year', name: '' },
		{ value: 'last_year', name: '' },
	],

  default_font_for_documents_opts: [
    { value: 'miriamlibre', name: '' },
		{ value: 'davidlibre', name: '' },
		{ value: 'frankruhllibre', name: '' },
	],
  activity_periods: [
    { value: 'yearly', name: '' },
    { value: 'monthly', name: '' },
    { value: 'weekly', name: '' },
    { value: 'daily', name: '' },
  ],
  testquestion_field_types:[
    {value:'remark',name:'no_answer'},
    {value:'text',name:''},
    {value:'number',name:''},
    // {value:'opts-select',name:''},
    {value:'opts-checkbox',name:''},
    {value:'opts-btn-group',name:''},
  ],
  area_position_types:[
    {value:'position_start',name:''},
    {value:'position_center',name:''},
    {value:'position_end',name:''},
  ],
  show_numbers_not_names_opts:[
    {value:"yes",name:"show_numbers_not_names"},
    {value:"no",name:"no"},
  ],
  invoices_report_by_opts:[
    {value:"invoices_report",name:"income_report"},
    {value:"invoices_report_by_payment_method",name:"income_report_by_payment_method"},
  ],

  debts_report_opts:[
    {value:"debts_report",name:"debts_report"},
    {value:"real_debt_report",name:"real_debt"},
  ],

  showByCreatedAt_opts:[
    {value:"yes",name:"show_by_created_at"},
    {value:"no",name:"show_by_expense_date"},
  ],
  showOnlyFollowups_opts:[
    {value:"no",name:"show_finresources_and_followups"},
    {value:"yes",name:"show_only_followups"},
    {value:"unpaid",name:"show_only_unpaid_followups"},
  ],
  showEmails_opts:[
    {value:"yes",name:"show_emails"},
    {value:"no",name:"no"},
  ],
  until_now_opts:[
    {value:"yes",name:"until_now"},
    {value:"no",name:"until_last_month"},
  ],

  showPaymentDetails_opts:[
    {value:"yes",name:"with_payment-details"},
    {value:"no",name:"without_payment-details"},
  ],
  withoutInvoice_opts:[
    {value:"no",name:"against_justinvoice"},
    {value:"yes",name:"without_invoice"},

  ],
  days_of_week_opts: [
		{ value: 0, name: "sunday" },
		{ value: 1, name: "monday" },
		{ value: 2, name: "tuesday" },
		{ value: 3, name: "wednesday" },
		{ value: 4, name: "thursday" },
		{ value: 5, name: "friday" },
		{ value: 6, name: "saturday" },
	],


	is_finresource_connected_only: [
    { value: 'no', name: 'all_options' },
		{ value: 'yes', name: 'is_finresource_connected_only' },
	],

  mr_status_opts: [
		{ value: "new", name: "" },
		{ value: "declined", name: "" },
		{ value: "accepted", name: "" },
		{ value: "directed", name: "" },
		{ value: "checking", name: "" },
    { value: '0', name: 'all_options' },
	],
  mr_motion_status_opts: [
		{ value: "open", name: "" },
		{ value: "declined", name: "" },
		{ value: "accepted", name: "" },
	],
  base_periods:[
    {value:"date_range",name:""},
    {value:"this_year",name:""},
    {value:"last_year",name:""},
    {value:"this_month",name:""},
    {value:"month_before",name:""},
    {value:"two_months_before",name:""},
  ],
  base_periods_no_range:[
    {value:"this_year",name:""},
    {value:"last_year",name:""},
    {value:"this_month",name:""},
    {value:"month_before",name:""},
    {value:"two_months_before",name:""},
  ],
  import_date_opts:[
		{ value: "month_first", name: "12/31/2000" },
		{ value: "date_first", name: "31/12/2000" },
	],



  // show_therapist_opts:[
  //   { value: 'yes', name: 'show_therapist' },
	// 	{ value: 'no', name: 'nonee' },
  // ],
  // show_email_in_pdf_opts:[
  //   { value: 'yes', name: 'show_email_on_document' },
	// 	{ value: 'no', name: 'nonee' },
  // ],

}
