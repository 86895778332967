<ng-template #header>
  @if(patient){
    <app-btn-ext text="send_invite" (clickEv)="send_invite()" [isSpin]="isSendingInvite" />
  }
</ng-template>

@if(test){
  <div >
    <ng-container  *ngTemplateOutlet="rowFields;context:{test:test,patient:patient}"></ng-container>

    @for(subtest of test.tests;track subtest){
      <ng-container  *ngTemplateOutlet="rowFields;context:{test:subtest}"></ng-container>
    }
  </div>
}

@if(test?.user_sign_required=='yes'){
  <div class="row">
    <div class="col-12">
      <label>{{"user_signature" | langVal}}</label>
      <div>
        <app-canvas-write  (imageOutput)="user_sign_img_blob=$event;validateEntireForm()" [error]="userSignErr" ></app-canvas-write>
      </div>
    </div>
  </div>
}
@if(test?.patient_sign_required=='yes'){
  <div class="row">
    <div class="col-12">
      <label>{{"patient_signature" | langVal}}</label>
      <div>
        <app-canvas-write  (imageOutput)="patient_sign_img_blob=$event;validateEntireForm()" [error]="patientSignErr" ></app-canvas-write>
      </div>
    </div>
  </div>
}

<div class="modal-btns" >
  @if(isModalMode){
    <app-cancel-btn (click)="close()" />
  }
  @if(patient){
    <app-btn-ext text="confirm" (clickEv)="save()" [disabled]="!isFormSubmittable" [isSpin]="isSaving" />
    <app-btn-ext text="send_invite" (clickEv)="send_invite()" [isSpin]="isSendingInvite" />
  }
  @if(inUserPatientMode){
    <app-btn-ext text="submit" [disabled]="!isFormSubmittable" (clickEv)="saveUserPatient()"/>
  }

</div>




<ng-template #rowFields let-test="test"  let-patient="patient">
  <h4 class="followup-title">{{test.name}} 
  @if(test.short_name){
    ({{test.short_name}}) 
  }
  - {{patient?.name}}</h4>
<!--  <h4 >{{'please_fill' | langVal}}</h4>-->
  <!-- <h4 lang v="please_fill"   ></h4> -->
  <div>

    <div [innerHTML]="test.description?(test.description | safeHtml):''" ></div>
    <div [innerHTML]="test.remarks?(test.remarks | safeHtml) :''" ></div>
  </div>


  @for(tq of test.questions;track tq){
    <div class="row">
      <div class="col-12">
        <b>{{tq.question}}</b>
        @if(tq?.required=="yes"){ * }
      </div>
    </div>
    @if(tq.field_type!=="remark"){
      <div class="row">
        <div class="col-12">
          @switch(tq.field_type){
            @case("text"){
              <app-gen-input fieldName="answer" type="textarea"  [actualObj]="tq" [errors]="tq.errors"
              (keyupEvent)="validateEntireForm()" (changeEvent)="validateEntireForm()" />
            }
            @case("number"){
              <app-gen-input fieldName="answer" type="number" [min]="tq.range_min" [max]="tq.range_max"  [actualObj]="tq" [errors]="tq.errors" (keyupEvent)="validateEntireForm()" (changeEvent)="validateEntireForm()" />
            }
            @case("opts-select"){
              <app-gen-select hasNone  [actualObj]="tq"  fieldName="answer"  (changeEvent)="validateEntireForm()" [errors]="tq.errors" [options]="tq.optionsArr" />
            }
            @case("opts-checkbox"){
              <div>
                @for(opt of tq.optionsArr;track opt){
                  <p>
                    <input type="radio" [name]="'tq_'+tq.id" (click)="tq.answer=opt.id;validateEntireForm()" /> {{opt.name}}
                  </p>
                }
                @if(tq.errors?.answer){
                  <p class="error" >{{tq.errors?.answer}}</p>
                }
              </div>
            }
            @case("opts-btn-group"){
              <app-gen-button-group [actualObj]="tq" fieldName="answer" optionFieldValue="id" [errors]="tq.errors" [options]="tq.optionsArr" (changeEvent)="validateEntireForm()" />
            }
          }
        </div>
      </div>
    }
  }

</ng-template>
